import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import ImageDiv, { StyledContainer } from "./components/ImageDiv";
import TitleContainer from "./components/TitleContainer";
import { Box } from "@material-ui/core";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class LandingPage7Web extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const findTechnicalTalents = this.state.findTechnicalTalents[0]?.attributes || {};
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <LandingHeader navigation={this.props.navigation} />
        <StyledContainer>
          <StyleWrapperContainer>
          <TitleContainer
            title={findTechnicalTalents.title}
            description={findTechnicalTalents.description}
            className="findDesignTalentTitle"
          />
          {findTechnicalTalents.technical_talent_features &&
            findTechnicalTalents.technical_talent_features.data &&
            findTechnicalTalents.technical_talent_features.data.length > 0 && (
              <div className="landingPage5">
                {findTechnicalTalents.technical_talent_features?.data.map(
                  (item, index) => (
                    <ImageDiv
                      title={item.attributes.title}
                      description={item.attributes.description}
                      image={item.attributes.image}
                      direction={index % 2 === 1 ? "right" : "left"}
                      handleNavigation={this.handleNavigation}
                      idName={item.attributes.title}
                    />
                  )
                )}
              </div>
            )}
          </StyleWrapperContainer>
        </StyledContainer>
        <LandingFooter navigation={this.props.navigation} />
        
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StyleWrapperContainer = styled(Box)({
  "& .containerWrapper":{
    backgroundColor:"rgb(245, 243, 243)",
  },
  "& .containerBox":{
    maxWidth:"1440px",
    margin:"0px auto 0px auto"
  }
})
const StyledWrappedGrayContainer = styled(Box)({
  width:"100%",
  backgroundColor:"#f5f3f3"
  })
  const StyledWrappedWHiteContainer = styled(Box)({
    width:"100%",
    })
// Customizable Area End
