import React from "react";
import { ImageList, ImageListItem, Box } from "@material-ui/core";
import styled, { keyframes } from "styled-components";
import { IFirstPageData } from "../../../packages/blocks/landingpage/src/LandingPageController";
import {
  withStyles
} from "@material-ui/core/styles";

let index = 0;

const scrollAnimation = keyframes`
  0% { 
    transform: translateY(0); 
  }
  100% { 
    transform: translateY(-50%); 
  }
`;

const AnimatedWrapper = styled.div`
  animation: ${scrollAnimation} 38s linear infinite;  // Removed forwards
`;

const StyledContainer = styled(Box)({
  width: " 100%",
  height: "100%",
  overflow: "hidden",
  position: "relative",
  "& .imageItemDiv": {
    display: "block",
    width: "100% !important",
    height:"242px",
    objectFit: "cover",
    aspectRatio: "16 / 9"
  },
  "@media(max-width:600px)": {
    width: "calc(100vw-100px)",
   },
});

const StyledImageListItem = withStyles({
  root: {
    width: (props:any) => props.width, // accept width as prop
    '& > div': {
      borderRadius: 4,
      overflow: 'hidden'
    }
  }
})(ImageListItem);

const getImageGridPattern = (index: number) => {
  const rowPattern = index % 7;

  switch (rowPattern) {
    case 0:
      return "100%";
    case 1:
      return "50%";
    case 2:
      return "50%";
    case 3:
      return "42%";
    case 4:
      return "58%";
    case 5:
      return "58%";
    case 6:
      return "42%";
    default:
      return "100%";
  }
};

export const scrollableImageGrid = (landingPageData: IFirstPageData) => {
  const duplicatedImages = [
    ...landingPageData?.attributes.images || [],
    ...landingPageData?.attributes.images || []
  ];

  index = 0;
  return(
  <StyledContainer>
    <AnimatedWrapper>
      <ImageList gap={20}>
        {duplicatedImages.map(
          (item: { url: string; id: number }, idx: number) => {
            if (idx === landingPageData.attributes.images.length) {
              index = 0;
            }
            const width = getImageGridPattern(index);
            index++;
            return (
              <StyledImageListItem key={item.id}
                style={{ width: width }}
              >
                {item.url.split("?type=").at(1) === 'image' ? (
                  <img
                    src={`${item.url}`}
                    alt={item.id.toString()}
                    className="imageItemDiv"
                  />
                ) : (
                  <video
                    src={item.url}
                    autoPlay
                    loop
                    muted
                  />
                )}
              </StyledImageListItem>
            );
          }
        )}
      </ImageList>
    </AnimatedWrapper>
  </StyledContainer>
);
}

export default scrollableImageGrid;
