// Customizable Area Start
import React from "react";
import HeaderNavbar  from '../../../components/src/Navbar'
import CreativeDashboard_ from "../../../components/src/CreativeDashboard"
import CreativeDashboardDetail from "../../../components/src/CreativeDashboardDetail"
import { pdfType, imageType, editImage, userImg } from "./assets"

import DashboardController, {
  Props,
} from "./DashboardController.web";
import { Box, Container, createTheme, styled } from "@material-ui/core";
const theme = createTheme({
  typography: {
    fontFamily: "Silka",
  },
});

export default class CreativeDashboardPage extends DashboardController {
  constructor(props: Props) {
    super(props);
  }
 
  render() {
    return (
      //Merge Engine DefaultContainer
      <>
        <HeaderNavbar
          navigation={this.props.navigation}
          data-test-id="headerNavbar"
        />
        {!this.state.showDetailSection ? (
          <BoxContainer>
            <Container maxWidth="xl" className="parents">
              <CreativeDashboard_
                navigateToDetailSection={this.navigateToDetailSection}
              />
            </Container>
          </BoxContainer>
        ) : (
          <CreativeDashboardDetail
            navigateToDetailSection={this.navigateToDetailSection}
            roleAttachmentFiles={undefined}
            roleInvoiceFiles={undefined}
            handleTerminateModal={undefined}
            handleRoleAttachmentFile={undefined}
            handleRoleInvoiceFile={undefined}
            editImage={editImage}
            editImageAlt={undefined}
            userImg={userImg}
            invoicesTitle={undefined}
            pdfType={pdfType}
            imageType={imageType}
          />
        )}
      </>
    );
  }
}
const BoxContainer = styled(Box)({
  "& .parents": {
    disply: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    padding:"80px",
    [theme.breakpoints.down('sm')] :{
      width: "100%",
      padding: "24px"
    }
  },
});

// Customizable Area End
