import {
  Box,
  Typography,
  styled,
  Drawer,
  List,
  ListItem,
  Avatar,
  ListItemText,
  WithWidth,
  isWidthDown,
  IconButton,
  withWidth,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Tooltip,
} from "@material-ui/core";
import React, { Component } from "react";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import MenuIcon from "@material-ui/icons/Menu";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";
const { baseURL } = require("framework/src/config");
const imgLogo = require("./image_logo.svg");
const profileImg = require("./viewcircle.svg");

const StyledBox = styled(Box)({
  "& .expendArrow": {
    transform: "rotate(180deg)",
    transitionDelay: "25s !important",
    transitionDuration: "5s !important",
  },
  "&.expendArrowReturn": {
    transform: "rotate(0deg)",
    transitionDelay: " 25s !important",
    transitionDuration: " 5s !important",
  },
  "& .disabledLink": {
    borderRadius: "4px",
    cursor: "default",
    color: "grey",
  },
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& *": {
    fontSize: "16px",
  },
  "& .MuiPaper-root": {
    backgroundColor: "#fff",
    borderRadius: 4,
    width: "600px",
    boxShadow: "none",
    height: "243px",
  },
}));
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#0D0C22",
  color: "#fff",
  padding: "18px 37px 18px 40px",
  fontWeight: "bold",
  "& *": {
    fontFamily: "Silika-Medium",
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "44px 39.5px 24px 39.5px",
  "& p": {
    fontWeight: 400,
    fontFamily: "Silika-Light",
    textAlign: "center",
  },
  "@media(max-width:959px)": {
    paddingTop: "0px !important",
    paddingRight: "20px !important",
    paddingBottom: "0px !important",
    paddingLeft: "20px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: "16px 40px 24px 40px",
  "@media(max-width:959px)": {
    paddingTop: "0px !important",
    paddingRight: "20px !important",
    paddingBottom: "20px !important",
    paddingLeft: "20px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  display: "flex",
  "& button": {
    fontFamily: "Silika-Medium",
    textTransform: "none",
    borderRadius: "0",
    boxShadow: "none",
    height: "48px",
  },
  "& .cancelBtn": {
    border: "1px solid #010101",
    color: "#010101",
  },
  "& .logoutBtn": {
    background: "#0D0C22",
    marginLeft: "16px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: "100px",
  borderRadius: "2px !important",
  "& .logOutBtn": {
    padding: "14.5px 16px 14.5px 16px !important",
  },
}));

const useEventTooltipStyle = makeStyles((theme) => ({
  arrow: {
    color: "f5f5f5",
  },

  tooltip: {
    color: "#000000",
    padding: "14px 14px 14px 10px",
    fontFamily: "Silika-Medium",
    fontSize: "12px",
    borderRadius: "2px",
    backgroundColor: "#f5f5f5",
    borderLeft: "4px solid #000",
    margin: "4px 0px 0px 4px",
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function EventTooltip(props: any) {
  const classes = useEventTooltipStyle();

  return <Tooltip classes={classes} placement="right" {...props} />;
}

function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

interface Props extends WithWidth {
  navigation?: {};
}

interface S {
  profileModal: boolean;
  discoverDropdown: boolean;
  isDropdownOpen: boolean;
  isOpenArrow: boolean;
  isDrawerOpen: boolean;
  isLogoutDialogeOpen: boolean;
  showMessage: boolean;
  showMessageJob: boolean;
  selectedScreen: string;
  profileAvatar: string;
  firstName: string | undefined;
  lastName: string | undefined;
}

class Navbar extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      profileModal: false,
      discoverDropdown: false,
      isDropdownOpen: false,
      isOpenArrow: false,
      isDrawerOpen: false,
      isLogoutDialogeOpen: false,
      showMessage: false,
      showMessageJob: false,
      selectedScreen: localStorage.getItem("selectedScreen") || "",
      profileAvatar: sessionStorage.getItem("profileAvatar") || "gfhf",
      firstName: sessionStorage.getItem("firstName")?.toString()[0],
      lastName: sessionStorage.getItem("lastName")?.toString()[0]
    };
  }

  async componentDidMount() {
    this.fetchProfile();
    window.addEventListener("profileUpdated", this.handleProfileUpdate)
  }

  componentWillUnmount(){
    window.removeEventListener("profileUpdated", this.handleProfileUpdate)
  }

  handleProfileUpdate = () => {
    this.fetchProfileData();
  }

  fetchProfileData = async () => {
    const profileId = sessionStorage.getItem("profileId");
    const token = this.getToken();
    const apiUrl = `${baseURL}/bx_block_profile/profiles/${profileId}`;
    if (token.length !== 0) {
      try {
        let response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
        });
        let result = await response.json();
        this.setState({
          profileAvatar: result.data.attributes.photo,
          firstName: result.data.attributes.first_name,
          lastName: result.data.attributes.last_name
        })
      } catch (error) {
        console.error("()=>here1", error);
      }
    }
  };

  getToken = () => {
    const token = localStorage.getItem("token") || "";
    return token;
  };

  fetchProfile = async () => {
    const token = this.getToken();
    if (token.length !== 0) {
      const apiUrl = `${baseURL}/account_block/accounts/show_profile`;
      try {
        let response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
        });
        let result = await response.json();
        sessionStorage.setItem("profileId", result.data.attributes.profile);
        this.fetchProfileData();
      } catch (error) {
        console.error("()=>here1", error);
      }
    }
  };

  handleOpen = (p0?: string) => {
    this.setState({ showMessage: true });
  };
  handleClose = () => {
    this.setState({ showMessage: false });
  };

  handleOpenJob = (p0?: string) => {
    this.setState({ showMessageJob: true });
  };
  handleCloseJob = () => {
    this.setState({ showMessageJob: false });
  };

  handleHeaderProfileOpen = () => {
    this.setState({
      profileModal: !this.state.profileModal,
    });
  };

  handleHeaderProfileClose = () => {
    this.setState({
      profileModal: false,
    });
  };

  handleisDropdownOpen = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
      isOpenArrow: !this.state.isOpenArrow,
    });
  };

  handleNavigation = (screenName: string) => {
    if (screenName.length > 0) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      runEngine.sendMessage(message.id, message);
      localStorage.setItem("selectedScreen", screenName);
    }
  };

  handleisDrawerOpen = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
    });
  };

  handleIsLogoutDialogeOpen = () => {
    this.setState({
      isLogoutDialogeOpen: !this.state.isLogoutDialogeOpen,
    });
  };

  handleLogout = () => {
    window.localStorage.clear();
    sessionStorage.clear();
    this.handleNavigation("LandingPage");
  };

  handleClickAwayDiscover = () => {
    this.setState({
      isDropdownOpen: false,
      isOpenArrow: !this.state.isOpenArrow,
    });
  };

  handleClickAwayProfile = () => {
    this.setState({
      profileModal: false,
      isOpenArrow: !this.state.isOpenArrow,
    });
  };

  renderLogoutDialogue = () => {
    return (
      <StyledDialog
        open={this.state.isLogoutDialogeOpen}
        onClose={this.handleIsLogoutDialogeOpen}
      >
        <StyledDialogTitle>Log out</StyledDialogTitle>
        <StyledDialogContent>
          <Typography>Are you sure you want to log out?</Typography>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            onClick={this.handleIsLogoutDialogeOpen}
            color="primary"
            className="cancelBtn"
          >
            Cancel
          </StyledButton>
          <StyledButton
            className="logOutBtn"
            onClick={this.handleLogout}
            color="primary"
            variant="contained"
          >
            Yes, log out
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    );
  };

  dropdownLinks = (navText: string, screen: string, className?: string) => {
    return (
      <StyledHyperlink>
        <span
          onClick={() => this.handleNavigation(screen)}
          className={className}
        >
          {navText}
        </span>
        <Box style={webStyle.discoverDropDownWidth}>
          <Typography style={webStyle.discoverOptionBorder} />
        </Box>
      </StyledHyperlink>
    );
  };
  dropdownLinkMedia = (navText: string, screen: string) => {
    return (
      <>
        <div>
          <div
            onClick={() => this.handleNavigation(screen)}
            style={webStyle.discoverHyperLinkMedia}
          >
            {navText}
          </div>
        </div>
        <Box style={webStyle.discoverDropDownWidth}>
          <Typography style={webStyle.discoverOptionBorder} />
        </Box>
      </>
    );
  };

  render() {
    const { width } = this.props;
    const isMobile = isWidthDown("sm", width);
    let selectedLink = localStorage.getItem("selectedScreen");
    const { profileAvatar, firstName, lastName } = this.state;
    return (
      <>
        {this.state.showMessage && (
          <Box style={webStyle.popupOverlay as React.CSSProperties}>
            <Box style={webStyle.popup as React.CSSProperties}>
              <p>This feature is not available yet.</p>
              <button
                onClick={this.handleClose}
                style={webStyle.buttonpop as React.CSSProperties}
              >
                Close{" "}
              </button>
            </Box>
          </Box>
        )}
        {this.state.showMessageJob && (
          <Box style={webStyle.popupOverlay as React.CSSProperties}>
            <Box style={webStyle.popup as React.CSSProperties}>
              <p>This feature is not available on your plan. </p>
              <button
                onClick={this.handleCloseJob}
                style={webStyle.buttonpop as React.CSSProperties}
              >
                Close{" "}
              </button>
            </Box>
          </Box>
        )}
        <StyledBox style={webStyle.headOuterContainer}>
          <Box style={webStyle.leftAlignOption}>
            <Box style={webStyle.logoContainer}>
              {isMobile ? (
                <IconButton
                  onClick={this.handleisDrawerOpen}
                  style={webStyle.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <></>
              )}
              <img src={imgLogo} alt="logo" style={webStyle.logoImage} onClick={() => this.handleNavigation("ProfileInfo")} />
              {!isMobile ? (
                <>
                  <EventTooltip title="Coming soon" placement="bottom">
                    <StyledDiscoverHyperLink
                      onClick={() => {}}
                      className="disabledLink"
                    >
                      Dashboard
                    </StyledDiscoverHyperLink>
                  </EventTooltip>
                  <StyledDiscoverHyperLink
                    onClick={() => this.handleNavigation("FindTalentCatalogue")}
                    style={{
                      cursor: "pointer",
                      color: "black",
                    }}
                  >
                    Find Talent
                  </StyledDiscoverHyperLink>
                  <EventTooltip title="Coming soon" placement="bottom">
                    <StyledDiscoverHyperLink
                      onClick={() => {}}
                      className="disabledLink"
                    >
                      Opportunities
                    </StyledDiscoverHyperLink>
                  </EventTooltip>
                  <Box
                    onClick={this.handleisDropdownOpen}
                    style={{ ...webStyle.discoverAnimation, gap: "0px" }}
                  >
                    <Typography
                      style={{
                        ...webStyle.discoverHyperLink,
                        marginRight: "0px",
                        padding: "0px 10px 0px 0px",
                        color: "black",
                      }}
                    >
                      Discover
                    </Typography>
                    <ExpandMoreIcon
                      style={webStyle.expendIconDescover}
                      className={
                        this.state.isOpenArrow
                          ? "expendArrow"
                          : "expendArrowReturn"
                      }
                    />
                    {this.state.isDropdownOpen && (
                      <ClickAwayListener
                        onClickAway={this.handleClickAwayDiscover}
                      >
                        <StyledDiscoverDropDown>
                          {this.dropdownLinks("Gallery", "LandingPageWeb6")}
                          {this.dropdownLinks("Events", "AllEventsWeb")}
                          <EventTooltip title="Coming soon" placement="right">
                            {this.dropdownLinks("Media", "", "disabledLink")}
                          </EventTooltip>
                        </StyledDiscoverDropDown>
                      </ClickAwayListener>
                    )}
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Box>
            <Drawer
              open={this.state.isDrawerOpen}
              onClose={this.handleisDrawerOpen}
            >
              <List>
                <ListItem>
                  <ListItemText>
                    <EventTooltip title="Coming soon" placement="bottom">
                      <StyledDiscoverHyperLink>
                        Dashboard
                      </StyledDiscoverHyperLink>
                    </EventTooltip>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <StyledDiscoverHyperLink
                      onClick={() =>
                        this.handleNavigation("FindTalentCatalogue")
                      }
                    >
                      Find Talent
                    </StyledDiscoverHyperLink>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <EventTooltip title="Coming soon" placement="bottom">
                      <StyledDiscoverHyperLink>
                        Opportunities
                      </StyledDiscoverHyperLink>
                    </EventTooltip>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Box
                      onClick={this.handleisDropdownOpen}
                      style={webStyle.drawerBox}
                    >
                      <Typography
                        style={{
                          ...webStyle.discoverHyperLink,
                          fontWeight: 700,
                          marginRight: "0px",
                          gap: "0",
                        }}
                      >
                        Discover
                      </Typography>
                      <ExpandMoreIcon
                        style={webStyle.expendIconDescover}
                        className={
                          this.state.isOpenArrow
                            ? "expendArrow"
                            : "expendArrowReturn"
                        }
                      />
                      {this.state.isDropdownOpen && (
                        <StyledDiscoverDropDown>
                          {this.dropdownLinks("Gallery", "LandingPageWeb6")}
                          {this.dropdownLinks("Companies", "")}
                          {this.dropdownLinks("Events", "AllEventsWeb")}
                          <EventTooltip title="Coming soon" placement="right">
                            {this.dropdownLinks("Media", "", "disabledLink")}
                          </EventTooltip>
                        </StyledDiscoverDropDown>
                      )}
                    </Box>
                  </ListItemText>
                </ListItem>
              </List>
            </Drawer>
          </Box>

          <Box style={webStyle.rightAlignOption}>
            <BootstrapTooltip title="Coming soon">
              <NotificationsOutlinedIcon style={webStyle.notificationOption} />
            </BootstrapTooltip>

            <BootstrapTooltip title="Coming soon">
              <ChatBubbleOutlineIcon style={webStyle.chatOption} />
            </BootstrapTooltip>

            <Avatar
              onClick={() => this.handleHeaderProfileOpen()}
              src={profileAvatar}
              alt="profile"
              style={webStyle.headerImage}
              data-test-id="headerImg"
            >
              {firstName?.toString()[0]}
              {lastName?.toString()[0]}
            </Avatar>
            {this.state.profileModal && (
              <ClickAwayListener onClickAway={this.handleClickAwayProfile}>
                <Box
                  onClick={this.handleHeaderProfileClose}
                  style={webStyle.profileHyperLinkDiv}
                >
                  {this.dropdownLinks("My Profile", "ProfileInfo")}
                  {this.dropdownLinks("Settings", "SettingsProfile")}
                  {this.dropdownLinks("Favourites", "Favourites")}
                  <div>
                    <Typography
                      style={webStyle.discoverHyperLink}
                      onClick={this.handleIsLogoutDialogeOpen}
                    >
                      Log out
                    </Typography>
                  </div>
                  <Box style={webStyle.borderDiv}>
                    <Typography style={webStyle.bottomBorder} />
                  </Box>
                </Box>
              </ClickAwayListener>
            )}
          </Box>
          {this.renderLogoutDialogue()}
        </StyledBox>
      </>
    );
  }
}

const StyledDiscoverDropDown = styled(Box)(() => ({
  position: "absolute",
  top: "45px",
  left: "0px",
  height: "194x",
  width: "192px",
  background: "#FFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  border: "1px solid #DFDFDF",
  borderRadius: "4px",
  zIndex: 2,
  "& .discoverHyperLink": {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    textAlign: "left" as "left",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#0D0C22",
    padding: "10px",
    gap: "8px",
    cursor: "pointer",
    width: "calc(100% - 20px)",
  },
  "& .disabledLink": {
    color: "grey",
  },
}));

const webStyle = {
  hyperLink: {
    textDecoration: "none",
    textAlign: "left" as "left",
    fontFamily: "Silika-Medium",
    fontSize: "15px",
    fontStyle: "normal" as "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#000000",
    letterSpacing: "-0.225px",
    whiteSpace: "nowrap" as "nowrap",
    padding: "10px",
    width: "calc(100% - 20px)",
  },
  discoverHyperLink: {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    textAlign: "left" as "left",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#0D0C22",
    padding: "10px",
    gap: "8px",
    cursor: "pointer",
    width: "calc(100% - 20px)",
  },
  discoverHyperLinkMedia: {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    textAlign: "left" as "left",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#0D0C22",
    opacity: "20%",
    padding: "10px",
    gap: "8px",
    cursor: "pointer",
    marginRight: "12px",
  },
  profileHyperLinkDiv: {
    position: "absolute" as "absolute",
    top: "50px",
    right: "5px",
    zIndex: 99999,
    height: "201px",
    width: "192px",
    background: "#FFF",
    borderRadius: "8px",
    padding: "4px",
    border: "1px solid #9B9B9D",
    gap: "2px",
    display: "grid" as "grid",
    flexDirection: "column" as "column",
    justifyContent: "start" as "start",
    alignItems: "center" as "center",
    justifyItems: "start" as "start",
  },
  expendIconDescover: {
    height: "24px",
    width: "24px",
  },
  headerImage: {
    height: "32px",
    width: "32px",
    flexShrink: 0,
    borderRadius: "50%",
    objectFit: "cover" as "cover",
    cursor: "pointer",
  },
  chatOption: {
    height: "24px",
    width: "24px",
    cursor: "default",
  },
  notificationOption: {
    fontSize: "30px",
    stroke: "#ffffff",
    strokeWidth: 0.6,
    cursor: "default",
  },
  rightAlignOption: {
    display: "flex" as "flex",
    height: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    gap: "24px",
    position: "relative" as "relative",
  },
  discoverOptionBorder: {
    borderBottom: "0.6px solid #9B9B9D",
    width: "160px",
    opacity: "16%",
    margin: "auto",
  },
  discoverDropDownWidth: {
    width: "190px",
  },
  discoverDropDown: {
    position: "absolute" as "absolute",
    top: "45px",
    left: "20px",
    height: "194x",
    width: "192px",
    background: "#FFF",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    alignItems: "start" as "start",
    // gap: "7px",
    // padding: "4px 4px 4px 5px",
    border: "1px solid #DFDFDF",
    borderRadius: "4px",
    zIndex: 2,
  },
  discoverAnimation: {
    display: "flex",
    width: "Hug (110px)",
    height: "Hug(39px)",
    padding: "10px",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    position: "relative" as "relative",
    maxHeight: "20px !important",
  },
  drawerBox: {
    display: "flex",
    alignItems: "center",
    position: "relative" as "relative",
    padding: "0 10px",
  },
  logoImage: {
    width: "19.412px",
    height: "25.674px",
    flexGrow: 0,
    alignSelf: "center",
    marginRight: 25,
    cursor: "pointer",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  menuButton: {
    padding: "4px",
    marginRight: "17px",
  },
  leftAlignOption: {
    display: "flex",
    justifyContent: "space-evenly",
    textAlign: "center" as "center",
  },
  headOuterContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "32px",
    margin: "21px 22.5px 0px 25px",
  },
  bottomBorder: {
    borderBottom: "0.6px solid #9B9B9D",
    width: "160px",
    opacity: "16%",
    margin: "auto",
  },
  borderDiv: {
    width: "190px",
  },
  popupOverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1000",
  },
  popup: {
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    boxshadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  buttonpop: {
    margintop: "10px",
    padding: "5px 10px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#243642",
    color: "white",
    cursor: "pointer",
    margin: "auto",
    display: "flex",
  },
};

const StyledHyperlink = styled("span")(() => ({
  cursor: "pointer",
  height: "100%",
  width: "100%",
  display: "flex",
  flexFlow: "column",
  "& span": {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    textAlign: "left" as "left",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#0D0C22",
    padding: "10px",
    width: "calc(100% - 20px)",
  },
}));

const StyledDiscoverHyperLink = styled("div")(() => ({
  whiteSpace: "nowrap" as "nowrap",
  fontFamily: "Silika-Medium",
  textAlign: "left" as "left",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#0D0C22",
  padding: "10px",
  width: "calc(100% - 20px)",
  marginRight: "12px",
  cursor: "pointer",
}));

export default withWidth()(Navbar);
