import React from "react";
// Customizable Area Start
import { Box, Typography, styled, Button ,Grid , Dialog , DialogTitle ,DialogContent } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import HeaderNavbar from "../../../components/src/Navbar";
import * as IMG_CONST from "./assets";
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInvoice = () => {
    return (
      <InvoiceBox>
        <Box className="innerBox">
          <Typography className="headingTxt">Invoice</Typography>
          <Box style={{ display: "flex" }}>
            <Typography className="subHeading">Number</Typography>
            <Typography className="headingTxt" style={{fontSize:"24px"}}> #4788</Typography>
          </Box>
        </Box>
        <Box className="innerBox">
          <Box style={{textAlign:"left"}}>
            <Typography className="billedTo">Billed To:</Typography>
            <Typography className="billedToValue">Super Cars</Typography>
          </Box>
          <Box>
           {this.state.isModalShow ? ""  : <img src={IMG_CONST.paidImg} alt="Paid" />} 
          </Box>
        </Box>
        <Box className="innerBox">
          <Box style={{display:"flex"}}>
            <Box style={{marginRight:"44px",textAlign:"left"}}>
              <Typography className="labelText">Address</Typography>
              <Typography className="valueText">
                990 Queens Road London, E53 4HM
              </Typography>
            </Box>
            <Box style={{textAlign:"left"}}>
              <Typography className="labelText">Company registration number</Typography>
              <Typography className="valueText">8756289075</Typography>
            </Box>
          </Box>
          <Box className="textstyle">
            <Typography className="labelText">Issued on</Typography>
            <Typography className="valueText">March 5, 2024</Typography>
          </Box>
        </Box>
        <Box className="innerBox">
          <Box style={{textAlign:"left"}}>
            <Typography className="labelText">VAT number</Typography>
            <Typography className="valueText">DE111</Typography>
          </Box>
          <Box className="textstyle">
            <Typography className="labelText">Payment Due</Typography>
            <Typography className="valueText">March 5, 2024</Typography>
          </Box>
        </Box>
      </InvoiceBox>
    );
  };
   ServiceDetails = (index: number, { projectName, timeSheet, dates, totalHours, role, price1, price2 }:any) => (
    <Box className="serviceDetailsContainer">
      <Box className="serviceSummary">
        <Box className="serviceDetails">
          <Typography className="serviceDetailTitle">Project Name:</Typography>
          <Typography className="serviceDetailValue">{projectName}</Typography>
        </Box>
        <Box className="serviceDetails">
          <Typography className="serviceDetailTitle">Time Sheet:</Typography>
          <Typography className="serviceDetailValue">{timeSheet}</Typography>
        </Box>
        <Box className="serviceDetails">
          <Typography className="serviceDetailTitle">Dates:</Typography>
          <Typography className="serviceDetailValue">{dates}</Typography>
        </Box>
        <Box className="serviceDetails">
          <Typography className="serviceDetailTitle">Total hours:</Typography>
          <Typography className="serviceDetailValue">{totalHours}</Typography>
        </Box>
        <Box className="serviceDetails">
          <Typography className="serviceDetailTitle">Role:</Typography>
          <Typography className="serviceDetailValue">{role}</Typography>
        </Box>
      </Box>

       <Box className="serviceSummaryBox">
         <Grid container spacing={2}>
           <Grid item lg={6} md={6} sm={12} xs={12}>
             <Typography className="priceTxt">{price1}</Typography>
           </Grid>
           <Grid item lg={6} md={6} sm={12} xs={12}>
             <Typography className="priceTxt">{price2}</Typography>
           </Grid>
         </Grid>
       </Box>
    </Box>
  );
  renderServiceBox = () => {
    const serviceData = [
      {
        projectName: "0003  Van O5",
        timeSheet: "Week 1",
        dates: "31 Mar/ 6 Apr",
        totalHours: "8:30h",
        role: "Car Engineer",
        price1: "£1.000.000.00",
        price2: "£2.000.000.00",
      },
      {
        projectName: "0003  Van O5",
        timeSheet: "Electric design",
        dates: "31 Mar/ 6 Apr",
        totalHours: "12:00h",
        role: "Car Engineer",
        price1: "£420.00",
        price2: "£42.00",
      },
    ];
    return (
      <ServiceBox>
        <Box className="serviceHeader">
          <Typography className="serviceTitle">Services</Typography>
          <Box className="servicePriceContainer">
            <Typography className="servicePrice">Price</Typography>
            <Typography className="servicePrice"><span style={{color:"#9B9B9D"}}>@20%</span>VAT</Typography>
          </Box>
        </Box>
        {serviceData.map((data, index) => (
          (this.ServiceDetails (index , data))
        ))}
        <Box className="SubtotalStyle">
          <Box className="firstBox"></Box>
          <Box className="secondbox">
            <Box className="totalBox">
              <Typography className="totalPriceTxt">Subtotal:</Typography>
              <Typography className="totalPriceTxt">£2.000.000.00</Typography>
            </Box>

            <Box className="totalBox">
              <Typography className="totalPriceTxt">Vat:</Typography>
              <Typography className="totalPriceTxt">£200.042.00</Typography>
            </Box>

            <Box className="totalBox backgroundBlack">
              <Typography className="totalPriceTxt fontColorWhite">Total:</Typography>
              <Typography className="totalPriceTxt fontColorWhite">£1.620.042.00</Typography>
            </Box>
          </Box>
        </Box>
        <Box>
        </Box>
      </ServiceBox >
    );
  };

  renderPaymentBox = () => {
    return (
      <Box style={{ borderTop: "1px solid #DFDFDF", display: "flex", flexDirection: "column", padding: "20px", }}>
        <Box className="detailsBox">
            <Box>
              <Typography className="labelDetailsText">Company name</Typography>
              <Typography className="valueDetailsText">Maloka Aloha</Typography>
            </Box>
            <Box>
              <Typography className="labelDetailsText" style={{textAlign:"left"}}>Address</Typography>
              <Typography className="valueDetailsText">990 Queens Road London,  E53 4HM</Typography>
            </Box>
            <Box style={{textAlign:"left"}}>
              <Typography className="labelDetailsText">Issued on</Typography>
              <Typography className="valueDetailsText">youremail@mail.com</Typography>
            </Box>
          </Box>
        <AgreeTxt>Did you received the payment?</AgreeTxt>
        <ButtonContainer>
          <YesButton data-test-id="openModalID" onClick={()=>this.openModal()}>Yes</YesButton>
          <NoButton>No</NoButton>
        </ButtonContainer>
        <Box>
          <>
            {this.state.isModalShow && 
             <DialogWrapper
             open={this.state.isModalShow}
             className='custom-modal'
             BackdropProps={{
                 className: "backdrop-class"
             }}
             PaperProps={{
              style: {
                maxWidth: "1195px",
                width: "100%",
              },
            }}
         > 
                <DialogTitle id="alert-dialog-slide-title" className="dialogueHeaderContainer">
                  <Grid container>
                    <Grid item xs={12} className="dialogueHeader">
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <Box>
                          <Typography>PDF Invoice  - Download</Typography>
                        </Box>
                        <Box>
                          <CloseBtn data-test-id="closeDeleteModalID" onClick={() => this.closeDeleteModal()} />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContentWrapper>
                    {this.renderInvoice()}
                    {this.renderServiceBox()}
                    <Box style={{ borderTop: "1px solid #DFDFDF", display: "flex", flexDirection: "column", padding: "20px", }}>
                      <Box className="detailsBox">
                        <Box style={{textAlign:"left"}}>
                          <Typography className="labelDetailsText">Company name</Typography>
                          <Typography className="valueDetailsText">Maloka Aloha</Typography>
                        </Box>
                        <Box>
                          <Typography className="labelDetailsText" style={{textAlign:"left"}}>Address</Typography>
                          <Typography className="valueDetailsText">990 Queens Road London,  E53 4HM</Typography>
                        </Box>
                        <Box className="issueBox">
                          <Typography className="labelDetailsText">Issued on</Typography>
                          <Typography className="valueDetailsText">youremail@mail.com</Typography>
                        </Box>
                      </Box>
                      <AgreeTxt>Did you received the payment?</AgreeTxt>
                      <ButtonContainer style={{justifyContent:"end"}}>
                        <CancelButton data-test-id="closeDeleteModalID" onClick={() => this.closeDeleteModal()}>Cancel</CancelButton>
                        <DownloadButton>Download PDF</DownloadButton>
                      </ButtonContainer>
                    </Box>
                </DialogContentWrapper>
              </DialogWrapper>
            }
          </>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        <HeaderNavbar
          navigation={this.props.navigation}
          data-test-id="headerNavbar"
        />

        <WrapperBox>
          {this.renderInvoice()}
          {this.renderServiceBox()}
          {this.renderPaymentBox()}
        </WrapperBox>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const WrapperBox = styled(Box)({
  margin: "44px 80px 0px 80px",

  "@media (max-width: 1200px)": {
    margin: "0px 60px",
  },
  "@media (max-width: 768px)": {
    margin: "0px 3"
  },

  "@media (max-width: 480px)": {
    margin: "0px 20px",
  },

  "& .detailsBox": {
    display: "flex",
    flexWrap: "wrap",
    gap: "80px",
    padding:"32px 16px 12px 16px",
    "@media (max-width: 480px)": {
      padding: "0px",
      gap: "10px",
      fontSize: "12px"
    },
    "@media (max-width: 768px)": {
      padding: "0px",
      gap: "10px",
      fontSize: "12px"
    },
    "& .labelDetailsText": {
      color: "#666",
      fontWeight: 400,
      fontSize: "15px",
      fontFamily: "Silika-Medium",
      "@media (max-width: 480px)": {
        fontSize:"12px"
      },
      "@media (max-width: 768px)": {
        fontSize: "12px"
      },
    },
    "& .valueDetailsText": {
      color: "#010101",
      fontWeight: 400,
      fontSize: "15px",
      fontFamily: "Silika-Medium",
      "@media (max-width: 480px)": {
        fontSize:"12px"
      },
      "@media (max-width: 768px)": {
        fontSize: "12px"
      },
    },
  },
});

const InvoiceBox = styled(Box)(() => ({
  height: "auto",
  padding: "32px",
  display: "flex",
  flexDirection: "column",
  gap: "30px",
  "@media (max-width: 768px)": {
    padding: "20px",
    "& .headingTxt": {
      fontSize: "22px"
    },
    "& .subHeading": {
      fontSize: "20px",
    },
    "& .labelText, & .valueText": {
      fontSize: "14px",
      textAlign:"left"
    },
    "& .billedTo": {
      fontSize: "18px",
    },
    "& .billedToValue": {
      fontSize: "16px"
    },
    "& img": {
      width: "80px",
      height: "auto",
    },
  },


  "& .innerBox": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "20px",
    "& .textstyle":{
      textAlign:"right",
      "@media (max-width: 480px)": {
        textAlign:"left",
      },
      "@media (max-width: 768px)": {
        textAlign:"left",
      },
    }
  },

  "& .headingTxt": {
    color: "#333",
    fontSize: "28px",
    fontWeight: 700,
    lineHeight: "33.84px",
    letterSpacing: "-0.15000000596046448px",
    fontFamily: "Silika-Medium",
  },

  "& .subHeading": {
    color: "#333",
    fontWeight: 400,
    fontSize: "24px",
    fontFamily: "Silika-Medium",
    lineHeight: "28.8px"
  },

  "& .labelText": {
    color: "#9B9B9D",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    lineHeight:"19.2px",
  },

  "& .valueText": {
    color: "#010101",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    lineHeight:"19.3px",
  },

  "& .billedTo": {
    color: "#9B9B9D",
    fontWeight: 400,
    fontSize: "20px",
    fontFamily: "Silika-Medium",
    lineHeight: "24px"
  },

  "& .billedToValue": {
    color: "#010101",
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Silika-Medium",
    lineHeight: '24.17px'
  },

  "& img": {
    height: "63px",
    width: "auto",
    maxWidth: "122px",
  },

  "@media (max-width: 480px)": {
    padding: "0px",
    "& .headingTxt": {
      fontSize: "20px"
    },
    "& .subHeading": {
      fontSize: "18px"
    },
    "& .labelText, & .valueText": {
      fontSize: "12px",
      textAlign:"left"
    },
    "& .billedTo": {
      fontSize: "16px"
    },
    "& .billedToValue": {
      fontSize: "14px"
    },
    "& img": {
      width: "70px"
    },
  },
}));


const ServiceBox = styled(Box)(() => ({
  padding: "20px",
  "@media (max-width: 1024px)": {
    padding: "15px",
  },
  "@media (max-width: 768px)": {
    padding: "10px",
    "& .labelDetailsText, & .valueDetailsText": {
      fontSize: "12px",
    },
  },
  "& .SubtotalStyle": {
    display: "flex",
    justifyContent: "space-between",

    "@media (max-width: 480px)": {
      "& .firstBox": {
        display: "none"
      },
      "& .secondbox":{
        width:"100%",
        margin:"40px"
      }
  },
  },
  "& .serviceHeader": {
    borderBottom: "1px solid #DFDFDF",
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
  },
  "& .serviceTitle": {
    color: "#010101",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight:"22px",
    fontFamily: "Silika-Medium",
    padding: "8px 12px",
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
    "@media (max-width: 480px)": {
      padding: "15px 0px",
    }
  },
  "& .servicePriceContainer": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "space-between",
    padding:"8px 12px",
    "@media (max-width: 1024px)": {
    },
    "@media (max-width: 768px)": {
      gap: "8px",
    },
    "@media (max-width: 480px)": {
      gap: "6px",
      padding: "15px 0px",
    }
  },

  "& .servicePrice": {
    color: "#010101",
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    display: "inline-block",
    wordWrap: "break-word",
    whiteSpace: "normal",
    width: "100px",
    textAlign: "end",
    "@media (max-width: 1024px)": {
      width: "75px",
      fontSize: "12px",
    },
    "@media (max-width: 768px)": {
      width: "60px",
      fontSize: "12px",
      gap: "8px",
    },
    "@media (max-width: 480px)": {
      width: "50px",
      fontSize: "10px",
    }
  },

  "& .serviceVat": {
    color: "#9B9B9D",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 768px)": {
      fontSize: "12px",
    }
  },
  "& .serviceDetailsContainer": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding:"8px 12px",
    "@media (max-width: 768px)": {
      gap: "10px",
    },
    "@media (max-width: 480px)": {
      padding:"8px 0px",
      gap: "10px",
    }
  },
  "& .serviceDetails": {
    display: "flex",
    gap: "5px",
    "@media (max-width: 768px)": {
      gap: "5px",
    }
  },
  "& .serviceDetailTitle": {
    color: "#010101",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight:"20px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 768px)": {
      fontSize: "12px",
      textAlign: "left",
    },
    "@media (max-width: 480px)": {
      fontSize: "12px",
      textAlign: "left",
    }
  },
  "& .serviceDetailValue": {
    color: "#9B9B9D",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight:"20px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 768px)": {
      fontSize: "12px",
      textAlign: "left",
    },
    "@media (max-width: 480px)": {
      fontSize: "12px",
      textAlign: "left",
    }
  },
  "& .serviceSummaryBox": {
    display: "flex",
    gap: "10px",
    alignItems: "center",

    "@media (max-width: 768px)": {
      gap: "8px",
    },
    "@media (max-width: 480px)": {
      gap: "6px",
      
    }
  },
  "& .priceTxt": {
    color: "#010101",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    lineHeight:"22px",
    width:"auto",
    textAlign: "end",
    "@media (max-width: 1024px)": {
      width: "75px",
      fontSize: "12px",
    },
    "@media (max-width: 768px)": {
      fontSize: "12px",
      gap: "8px",
      width: "auto",
    },
    "@media (max-width: 480px)": {
      width: "auto",
      fontSize: "10px",
    }
  },
  "& .totalBox": {
    height: "30px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px",
    marginBottom: "10px",
    "@media (max-width: 1024px)": {
    },
    "@media (max-width: 768px)": {
      gap: "8px",
    },
    "@media (max-width: 480px)": {
      gap: "6px",
      textAlign:"center",
      height: "auto"
    }
  },

  "& .totalPriceTxt": {
    color: "#010101",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    display: "inline-block",
    wordWrap: "break-word",
    whiteSpace: "normal",
    width: "100px",
    textAlign: "end",
    "@media (max-width: 1024px)": {
      width: "75px",
      fontSize: "12px",
    },
    "@media (max-width: 768px)": {
      width: "60px",
      fontSize: "12px",
      gap: "8px",
    },
    "@media (max-width: 480px)": {
      width: "100%",
      fontSize: "10px",
      textAlign:"center"
    }
  },
  "& .backgroundBlack": {
    backgroundColor: "#000",
    color: "#FFF",
  },
  "& .fontColorWhite": {
    color: "#FFF",
  },
  "& .detailsBox": {
    display: "flex",
    flexWrap: "wrap",
    gap: "80px",
  },
  "& .labelDetailsText": {
    color: "#666",
    fontWeight: 400,
    fontSize: "15px",
    fontFamily: "Silika-Medium",
  },

  "& .valueDetailsText": {
    color: "#010101",
    fontWeight: 400,
    fontSize: "15px",
    fontFamily: "Silika-Medium",
  },
  "@media (max-width: 480px)": {
    padding:"0px",
    "& .labelDetailsText, & .valueDetailsText": {
      fontSize: "12px",
    },
  }
}));

const ButtonContainer = styled(Box)({
  display: "flex",
  marginTop: "16px",
  gap: "10px",
  padding:"0px 16px",

  flexDirection: "row",
  '@media (max-width: 480px)': {
    flexDirection: "column",
    alignItems: "stretch",
    padding: "0px"
  },
  '@media (max-width: 768px)': {
    padding: "0px"
  }
})
const DialogWrapper = styled(Dialog)({
  "& .backdrop-class": {
      backgroundColor: "rgba(255, 255, 255, 0.5)"
  },
  "& .dialogueHeaderContainer":{
    background: "#0D0C22",
    color: "white",
    "& .dialogueHeader": {
      fontFamily: "Silika-light",
      fontSize: "16px",
      fontWeight: 300,
      lineHeight: "19.2px",
    }
  },
})
const DialogContentWrapper = styled(DialogContent)({
  textAlign: "center",
  padding: "24px 14px !important",
  "& .detailsBox": {
    display: "flex",
    flexWrap: "wrap",
    gap: "80px",
    padding:"32px 16px 12px 16px",
    "@media (max-width: 480px)": {
      padding:"0px",
      gap: "10px",
      fontSize:"12px"
    },
    "@media (max-width: 768px)": {
      gap: "10px",
      fontSize: "12px"
    },
    "& .labelDetailsText": {
      color: "#666",
      fontWeight: 400,
      fontSize: "15px",
      fontFamily: "Silika-Medium",
    },
    "& .valueDetailsText": {
      color: "#010101",
      fontWeight: 400,
      fontSize: "15px",
      fontFamily: "Silika-Medium",
      "@media (max-width: 480px)": {
        fontSize:"12px"
      },
      "@media (max-width: 768px)": {
        fontSize: "12px"
      },
    },
  },
  "& .issueBox":{
      textAlign:"left",
    },
})
const CloseBtn = styled(CloseIcon)({
  cursor: "pointer",
  width: "24px",
  height: "24px",
})

const CancelButton = styled(Button)({
  textTransform: "unset",
  color: "#0D0C22",
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Silika-Medium",
  width: "87px",
  backgroundColor: "#FFFFFF",
  padding: "5px",
  border: "1px solid #010101",
  cursor: "pointer",
  borderRadius: "1px",
  '@media (max-width: 480px)': {
    width: "100%",
    marginBottom: "10px"
  }
})
const NoButton = styled(Button)({
  textTransform: "unset",
  color: "#0D0C22",
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Silika-Medium",
  width: "50px",
  backgroundColor: "#FFFFFF",
  padding: "5px",
  border: "1px solid #010101",
  cursor: "pointer",
  borderRadius: "1px",
  '@media (max-width: 480px)': {
    width: "100%",
    marginBottom: "10px"
  }
});
const DownloadButton = styled(Button)({
  textTransform: "unset",
  width: "146px",
  backgroundColor: "#0D0C22",
  color: "#FFFFFF",
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Silika-Medium",
  padding: "5px",
  cursor: "pointer",
  borderRadius: "1px",
  '&:hover': {
    backgroundColor: "#0D0C22",
    color: "#FFFFFF"
  },
  '@media (max-width: 480px)': {
    width: "100%"
  }
})
const YesButton = styled(Button)({
  textTransform: "unset",
  width: "50px",
  backgroundColor: "#0D0C22",
  color: "#FFFFFF",
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Silika-Medium",
  padding: "5px",
  cursor: "pointer",
  borderRadius: "1px",
  '&:hover': {
    backgroundColor: "#0D0C22",
    color: "#FFFFFF"
  },
  '@media (max-width: 480px)': {
    width: "100%"
  }
});
const AgreeTxt = styled(Typography)({
  color: "#010101",
  fontWeight: 400,
  fontSize: "15px",
  fontFamily: "Silika-Medium",
  padding:"32px 16px 12px 16px",
  textAlign:"left",
  '@media (max-width: 480px)': {
    textAlign:"center",
    padding:"20px 0px",
  fontSize: "13px",
  },
  '@media (max-width: 768px)': {
    textAlign:"left",
    padding:"20px 0px",
  fontSize: "13px",

  }
})


// Customizable Area End
