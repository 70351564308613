
import React from 'react';
import {
  Box,
  Button,
  Container,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Table,
  createTheme,
  Theme
} from '@material-ui/core';
import { paid } from "./../assets";

const theme = createTheme({
  typography: {
    fontFamily: 'Silika-Light',
  },
});

const StyledBox = styled(Box)({
  backgroundColor: '#F9F9F9',
  // padding: '24px',
  margin:"0px 70px 70px 70px",
  fontFamily: 'Silika-Light',

});

const StyledTable = styled(Table)({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #DFDFDF',
    padding: '16px 0',
  },
  '& .MuiTableCell-head': {
    fontWeight: 500,
  },
  '& .MuiTable-root': {
    // width:'97% !important'
  }
});

interface ServiceItem {
  projectName: string;
  timeSheet: string;
  dates: string;
  totalHours: string;
  role: string;
  price: string;
  vat: string;
}

const Invoice: React.FC = () => {
  const services: ServiceItem[] = [
    {
      projectName: "0003 Van O5",
      timeSheet: "Week 1",
      dates: "31 Mar/ 6 Apr",
      totalHours: "8:30h",
      role: "Car Engineer",
      price: "£1,000,000.00",
      vat: "£200,000.00"
    },
    {
      projectName: "0003 Van O5",
      timeSheet: "Electric design",
      dates: "31 Mar/ 6 Apr",
      totalHours: "12:00h",
      role: "Car Engineer",
      price: "£420.00",
      vat: "£42.00"
    }
  ];

  return (
      <StyledBox>
        {/* Header */}
        <Box style={{ backgroundColor: "#f1f1f1", padding: "20px" }}>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h5" style={{ fontWeight: 700, fontSize: '28px' }} >Invoice</Typography>
            <Box display="flex" alignItems={"center"}>
              <Typography style={{ fontWeight: 400, fontSize: '24px', fontFamily: "Silika-Light", marginRight: "10px" }} color="textSecondary" variant="body2">Number</Typography>
              <Typography style={{ fontWeight: 900, fontSize: '24px', fontFamily: "Silika-Light" }} variant="h6">#4788</Typography>
            </Box>
          </Box>

          {/* Billing Information */}
          <Box mb={4}>

            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
            >
              <Box sx={{ textAlign: 'left' }}>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: '20px', fontFamily: "Silika-Light" }}
                  variant="body2"
                >
                  Billed To:
                </Typography>
                <Typography
                  style={{ fontWeight: 700, fontSize: '20px', fontFamily: "Silika-Light" }}
                  variant="h6"
                  gutterBottom
                >
                  Sam Smith
                </Typography>
              </Box>

              <Box sx={{ textAlign: 'right' }}>
                <img src={paid} />
              </Box>
            </Box>

            <Box display="grid" gridTemplateColumns="repeat(4, auto)" 
            >
              <Box>
                <Typography style={{ fontWeight: 400, fontSize: '16px', fontFamily: "Silika-Light" }} color="textSecondary" variant="body2">Address</Typography>
                <Typography style={{ fontWeight: 900, fontSize: '16px', fontFamily: "Silika-Light" }}  >990 Queens Road London, E53 4HM</Typography>

                <Box mt={2}>
                  <Typography style={{ fontWeight: 400, fontSize: '16px', fontFamily: "Silika-Light" }} color="textSecondary" variant="body2">Contact</Typography>
                  <Typography style={{ fontWeight: 900, fontSize: '16px', fontFamily: "Silika-Light" }} >youremail@mail.com</Typography>
                </Box>
              </Box>

              <Box>
                <Typography style={{ fontWeight: 400, fontSize: '16px', fontFamily: "Silika-Light" }} color="textSecondary" variant="body2">VAT number</Typography>
                <Typography style={{ fontWeight: 900, fontSize: '16px', fontFamily: "Silika-Light" }} >DE111</Typography>

                <Box mt={2}>
                  <Typography style={{ fontWeight: 400, fontSize: '16px', fontFamily: "Silika-Light" }} color="textSecondary" variant="body2">Company registration number</Typography>
                  <Typography style={{ fontWeight: 900, fontSize: '16px', fontFamily: "Silika-Light" }} >8756289075</Typography>
                </Box>
              </Box>
              <Box>

              </Box>
              <Box textAlign={'right'}>
                <Typography style={{ fontWeight: 400, fontSize: '16px', fontFamily: "Silika-Light" }} color="textSecondary" variant="body2">Issued on</Typography>
                <Typography style={{ fontWeight: 900, fontSize: '16px', fontFamily: "Silika-Light" }}>March 5, 2024</Typography>

                <Box mt={2}>
                  <Typography style={{ fontWeight: 400, fontSize: '16px', fontFamily: "Silika-Light" }} color="textSecondary" variant="body2">Payment Due</Typography>
                  <Typography style={{ fontWeight: 900, fontSize: '16px', fontFamily: "Silika-Light" }}>March 12, 2024</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Services Table */}
        <TableContainer style={{ width: '97%', padding: '20px' }}>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 700, fontSize: '14px', fontFamily: "Silika-Light" }}>Services</TableCell>
                <TableCell style={{ fontWeight: 700, fontSize: '14px', fontFamily: "Silika-Light" }} align="right">Price</TableCell>
                <TableCell style={{ fontWeight: 700, fontSize: '14px', fontFamily: "Silika-Light", color:"#D5D5D5" }} align="right">@20% <span style={{color:"black", fontWeight: 700, fontSize: '14px' }} >VAT</span> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services.map((service, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box>
                      <Typography style={{
                        fontFamily: "Silika-Light"
                      }}>
                        Project Name: <Typography style={{
                          fontFamily: "Silika-Light"
                        }} component="span" color="textSecondary">{service.projectName}</Typography>
                      </Typography>
                      <Typography style={{
                        fontFamily: "Silika-Light"
                      }}>
                        Time Sheet: <Typography style={{
                          fontFamily: "Silika-Light"
                        }} component="span" color="textSecondary">{service.timeSheet}</Typography>
                      </Typography>
                      <Typography style={{
                        fontFamily: "Silika-Light"
                      }}>
                        Dates: <Typography style={{
                          fontFamily: "Silika-Light"
                        }} component="span" color="textSecondary">{service.dates}</Typography>
                      </Typography>
                      <Typography style={{
                        fontFamily: "Silika-Light"
                      }}>
                        Total hours: <Typography style={{
                          fontFamily: "Silika-Light"
                        }} component="span" color="textSecondary">{service.totalHours}</Typography>
                      </Typography>
                      <Typography style={{
                        fontFamily: "Silika-Light"
                      }}>
                        Role: <Typography style={{
                          fontFamily: "Silika-Light"
                        }} component="span" color="textSecondary">{service.role}</Typography>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right">{service.price}</TableCell>
                  <TableCell align="right">{service.vat}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>

        {/* Totals */}
        <Box padding={1} display="flex" flexDirection="column" alignItems="flex-end" mt={3}>
          <Box width={300}>
            <Box display="flex" justifyContent="flex-end" mb={1}>
              <Typography style={{
                fontFamily: "Silika-Medium", marginRight: '10px', fontSize: "14px"
              }}>Subtotal:</Typography>
              <Typography style={{
                fontFamily: "Silika-Medium", fontSize: '14px',marginRight: '10px',
              }}>£1,420,000.00</Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={1}>
              <Typography style={{
                fontFamily: "Silika-Medium", marginRight: '10px', fontSize: "14px"
              }}>VAT:</Typography>
              <Typography style={{
                fontFamily: "Silika-Medium", fontSize: "14px",marginRight: '10px',
              }}>£200,042.00</Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" pt={1} pb={1} style={{ backgroundColor: '#0D0C22', color: "#ffffff", fontSize: "14px" }}>
              <Typography variant="h6" style={{
                fontFamily: "Silika-Light", marginRight: '10px', fontSize: '14px'
              }}>Total:</Typography>
              <Typography style={{ fontFamily: "Silika-Light", fontSize: '14px',marginRight: '10px' }} variant="h6">£1,620,042.00</Typography>
            </Box>
          </Box>
        </Box>

        {/* Footer */}
        <Box mt={6} padding={3} style={{ borderTop: '1px solid #DFDFDF' }} display="grid" gridTemplateColumns="repeat(4, 1fr)" >
          <Box>
            <Typography style={{ fontFamily: "Silika-Light", fontSize: '14px', fontWeight: 200 }} color="textSecondary" variant="body2">Company name</Typography>
            <Typography style={{ fontFamily: "Silika-Medium", fontSize: '14px' }}>Maloka Aloha</Typography>
          </Box>
          <Box >
            <Typography style={{ fontFamily: "Silika-Light", fontSize: '14px' }} color="textSecondary" variant="body2">Address</Typography>
            <Typography style={{ fontFamily: "Silika-Medium", fontSize: '14px' }}>990 Queens Road London, E53 4HM</Typography>
          </Box>
          <Box>
            <Typography style={{ fontFamily: "Silika-Light", fontSize: '14px' }} color="textSecondary" variant="body2">Contact</Typography>
            <Typography style={{ fontFamily: "Silika-Medium", fontSize: '14px' }}>youremail@mail.com</Typography>
          </Box>
        </Box>
      </StyledBox>
  );
};

export default Invoice;