export const editImage = require("../assets/editImage.svg");
export const pdfType = require("../assets/pdfType.svg");
export const imageType = require("../assets/imageType.svg");
export const userImg = require("../assets/userImg.svg");
export const creativeImage = require("../assets/imageCreative.svg");
export const plus = require("../assets/plus.png");
export const paid = require("../assets/paid.png");
export const download = require("../assets/download.png");
export const location = require("../assets/location.png");
export const actioneeImg = require("../assets/image__avatar.svg");
