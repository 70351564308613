Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.dummyText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet urna eget lacus sollicitudin, non accumsan lorem posuere. In hac habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas tincidunt ligula. Integer sodales ac purus sit amet imperdiet."
exports.cancelTxt = "Cancel";
exports.terminateText = "Terminate Session";
exports.areYouSureText = "Are you sure you want to Terminate?";
exports.yesTerminateText = "Yes, Terminate";
exports.deliverablesHeading = "Deliverables";
exports.deliverable = "Deliverable";
exports.status = "Status";
exports.action = "Action";
exports.actionee = "Actionee";
exports.deadline = "Deadline";
exports.dummyTextAccordion = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet urna eget lacus sollicitudin, non accumsan lorem posuere. In hac habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas tincidunt ligula. Integer sodales ac purus sit amet imperdiet.";
exports.editImageAlt = "Edit";
exports.roleName = "0001 Truckers";
exports.userName = "John Doe";
exports.role = "Supercars";
exports.timeAgo = "5 days ago";
exports.levelLabel = "Level:";
exports.levelValue = "Senior";
exports.locationLabel = "Location:";
exports.locationValue = "Coventry, UK";
exports.typeLabel = "Type:";
exports.typeValue = "Full Time | Remote";
exports.professionsLabel = "Professions:";
exports.professionsValue = "Interior Design | UI/UX Design | Visualisation";
exports.datesLabel = "Dates:";
exports.datesValue = "13 Mar 24 - 25 Apr 24";
exports.rateLabel = "Rate:";
exports.rateValue = "NA";
exports.budgetLabel = "Budget:";
exports.budgetValue = "£2,000";
exports.attachmentsTitle = "Attachments";
exports.invoicesTitle = "Invoices";
exports.addIconAlt = "Add Icon";
exports.editImageAlt = "Edit";
exports.rolesTitle = "Roles";
exports.createRoleText = "Create your first role!";
exports.createButtonText = "Create";
exports.overviewTitle = "Overview";
exports.jobTitle = "Automotive Designer";
exports.datesLabel = "Dates:";
exports.datesValue = "Define";
exports.budgetLabel = "Budget:";
exports.budgetValue = "Define";
exports.attachmentsTitle = "Attachments";
exports.editImageAlt = "Edit";
exports.addIconAlt = "Add Icon";
// Customizable Area End