import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import { StyledContainer } from "./components/ImageDiv";
import TitleContainer from "./components/TitleContainer";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, styled, Typography } from "@material-ui/core";
import HtmlTypography from "../../../../packages/components/src/HtmlTypography";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const StyledAccordion = styled(Accordion)  ({
  marginBottom: '30px',
  border: '1px solid #DFDFDF',
  boxShadow: 'none',
  '&.Mui-expanded': {
    marginBottom: '30px',
  },  
});
const StyledAccordionSummary = styled(AccordionSummary) ({
  fontFamily : "Silika-Medium",
  fontWeight : 500,
  fontSize : "16px",
  lineHeight : "25px"                                 
});

const StyledAccordionDetails = styled(AccordionDetails)({
  fontFamily : "Silika-Light",
  fontWeight : 300,
  fontSize : "14px",
  lineHeight : "19.69px",
  '&.Mui-expanded': {
    marginBottom: '30px',
  }
});

const StyledWrappedBigScreenContainer = styled(Box)({
  "& .containerBox":{
    maxWidth:"1440px",
    margin:"0px auto 0px auto"
  },
  "& .supportImg1":{
    aspectRatio: "203 / 184",
    height: "100%",
    width: "100%",
    maxWidth: "609px",
    maxHeight: 552,
    marginLeft:"auto",
    "@media(max-width:960px)": {
      margin:"auto"
     },
  },
  "& .supportImgTitle1":{
    fontSize:"48px",
    fontWeight:300,
    fontFamily:"Silika-Medium",
    "@media(max-width:580px)": {
      fontSize:"24px",
     },
  },
  "& .supportImgDesc1":{
    fontSize:"16px",
    fontWeight:300,
    fontFamily:"Silika-Light",
    marginTop:"47px"
  },
  "& .getStartedBtn": {
    width: "125px",
    height: "48px",
    borderRadius: "2px",
    color: "white",
    textTransform: "none",
    fontFamily: "Silika-Medium",
  },
  "& .startBtnProject": {
    backgroundColor: "#0D0C22",
  },
  "& .imgPart":{
    width: "50%", display: "flex" ,
    "@media(max-width:960px)": {
      width: "100%",
      display:"block"
     },
  },
  "& .descTitleContainer":{
    display:"flex",flexDirection:"column",justifyContent:"space-between",  height: "calc(100% - 40px)" 
  },
  "& .part1Content":{
    maxWidth: "579px",padding:"20px 0px 20px 0px",
    
  },
  "& .part1Container":{
     width:"50%",
    "@media(max-width:960px)": {
     width:"100% !important"
      },
  },
  "& .provideContent":{
    maxWidth: "1294px", margin: "0px auto 0px auto", display: "flex" ,
    "@media(max-width:960px)": {
     flexWrap:"wrap"
     },
  },
  "& .provideContainer":{
    padding: "112.61px 73px 140px 71px",
    "@media(max-width:1440px)": {
      padding: "50.61px 32px 70px 35px",
     },
  },
  "& .supportImg2":{
    maxHeight:"552px",
    maxWidth:"612px",
    aspectRatio:"51/46",
    height:"100%",
    width:"100%"
  },
  "& .supportImgDesc2":{
    maxWidth:"582px",
    marginTop:"49px"
  },
  "& .secondImgSupport":{
    "@media(max-width:960px)": {
      width:"100% !important",
      display:"flex",
     },
  },
  "& .secondPartDesc":{
    width:"50%"
  },
  "& .secondDivContent":{
    "@media(max-width:960px)": {
       paddingLeft:"0px !important"
     }, 
  },
  "& .titleBox":{
    marginBottom:"62px",
    "@media(max-width:960px)": {
      marginBottom:"0px !important"
    }, 
 },

})

// Customizable Area End

export default class SupportPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const faqData = this.state?.faqData;
    const supportFeatureData = this.state?.supportFeatureData[0]?.attributes||{};
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <LandingHeader navigation={this.props.navigation} />
        <StyledContainer>
        <StyledWrappedBigScreenContainer>
        <Box className="containerWrapper">
        <Box className="containerBox titleBox">
          <TitleContainer
            title={supportFeatureData.title}
            description={supportFeatureData.description}
          />
        </Box>
        </Box>
            <Box className="provideContainer">
              <Box className="provideContent">
                <Box className="part1Container">
                  <Box className="part1Content">
                    <Box className="descTitleContainer">
                    <HtmlTypography className="supportImgTitle1" htmlContent={supportFeatureData.help_features?.data[0].attributes.title || ""} />
                    <HtmlTypography className="supportImgDesc1" htmlContent={supportFeatureData.help_features?.data[0].attributes.description || ""} />       
                      <Button className="getStartedBtn startBtnProject" data-test-id="btnNav" onClick={() => this.handleNavigation("AccountLogin")}>Find Talent</Button>
                    </Box>
                    </Box>
                </Box>
                <Box className="imgPart">
                  <img src={supportFeatureData.help_features?.data[0].attributes.image} className="supportImg1" alt="image" />
                </Box>
              </Box>
            </Box>
            <Box className="provideContainer" style={{backgroundColor:"#F8F8F8"}}>
              <Box className="provideContent">  
                <Box style={{width:"50%"}} className="secondImgSupport">
                  <img src={supportFeatureData.help_features?.data[1].attributes.image} className="supportImg2" alt="image" />
                </Box>
                <Box className="part1Container secondPartDesc" style={{alignContent:"center"}}>
                  <Box className="part1Content" style={{maxWidth:"652px"}}>
                    <Box style={{margin:"auto",paddingLeft:"40px"}} className="secondDivContent">
                    <HtmlTypography className="supportImgTitle1" htmlContent={supportFeatureData.help_features?.data[1].attributes.title || ""} />
                    <HtmlTypography className="supportImgDesc2" htmlContent={supportFeatureData.help_features?.data[1].attributes.description || ""} />       
                      <Button className="getStartedBtn startBtnProject" data-test-id="btnNav2" onClick={() => this.handleNavigation("AccountLogin")}>Find Talent</Button>
                    </Box>
                    </Box>
                </Box>
              </Box>
            </Box>
          <div className="questionDiv">
            <div className="questionBox">
              <div className="questionBoxTitle">
                <Typography className="questionTitle">
                  {faqData[0]?.attributes.question}
                </Typography>
                <Typography className="questionDescription">
                {faqData[0]?.attributes.answer}
                </Typography>
              </div>
              <div>
                {faqData.slice(1).map((faq, index)=>(
                  <StyledAccordion key={index} >
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      {faq.attributes.question}
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                    {faq.attributes.answer}
                    </StyledAccordionDetails>
                </StyledAccordion>
                ))}
              </div>
            </div>
          </div>
          </StyledWrappedBigScreenContainer>
        </StyledContainer>
        <LandingFooter navigation={this.props.navigation} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
