import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { arrowRight } from "./assets";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import parse from "html-react-parser";
import { animationVideo } from "../../../../packages/blocks/email-account-login/src/assets";
// Customizable Area End

import LandingPageControllerWeb, {
  Props,
} from "./LandingPageController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography:{
    fontFamily:"Silika-Medium"
  }
});

const StyledContainer = styled(Box)({
  "& .imgUpMaxWidth":{
    maxWidth:"90% !important",
    "@media(max-width:1440px)": {
      maxWidth:"612px !important",
     },
  },
  "& .grayContainer":{
   backgroundColor:"#F8F8F8"
  },
  "& .showReelImg":{
   aspectRatio:"686/379"
  },
  "& .reelDiv":{
    "@media(max-width:433px)": {
      paddingLeft:"0px !important",
      paddingRight:"0px !important",
      margin:"auto !important",
      justifyContent: "center",
      display: "flex"
    },
  },
  '& .showReel': {
    backgroundColor: "#D9D9D9",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px"
  },
  '& .showReelText':{
    fontSize:"16px",
    fontWeight:"300", 
    fontFamily:"Silika-Light" ,
    borderRadius: "4px"
  },
  '& .forCreativeContainer':{
    maxWidth:"1302px",
    display:"flex",
    marginTop:"140px",
    marginBottom:"100px",
    background:"white",
    justifyContent:"space-between",
    marginLeft:"auto",
    marginRight:"auto",
    "@media(max-width:1000px)": {
      marginTop:"100px !important",
      paddingLeft:"50px",
      paddingRight:"50px",
      marginBottom:"80px",
    },
    "@media(max-width:960px)": {
      marginTop:"72px !important",
      paddingLeft:"34px",
      paddingRight:"24px",
      marginBottom:"60px",
    },
    "@media(max-width:433px)": {
      paddingLeft:"24px !important",
      paddingRight:"20px !important",
      marginBottom:"35px",
    },
  },
  '& .forCreativeContainerItem':{
    paddingTop:"20px",
    paddingBottom:"20px",
    paddingRight:"20px",
    "@media(max-width:533px)": {
      paddingRight:"0px",
      paddingTop:"0px",
    },
  },
  "& .forCreativeContainerItemDiv2":{
    height:"552px",
    "@media(max-width:917px)": {
      height: "500px"
    },
    "@media(max-width:533px)": {
      height: "350px !important"
    },
    "@media(max-width:375px)": {
      height: "294.4px !important",
      width: "100%"
    },
  },
  '& .titleForCreatives':{
    fontWeight:500,
    fontSize:"30px",
    color:"#9B9B9D",
    fontFamily:"Silika-Medium !important",
    justifyContent:"center",
    "@media(max-width:1390px)": {
      fontSize:"24px",
    },
    "@media(max-width:917px)": {
      fontSize:"16px",
    },
    "@media(max-width:533px)": {
      fontSize:"16px",
    },
  },
  '& .arrowRight':{
    marginRight:"14px",
    "@media(max-width:1300px)": {
      height:"24px",
      marginRight:"14px",
    },
    "@media(max-width:917px)": {
      height:"14px",
      marginRight:"14px",
    },
    "@media(max-width:533px)": {
      height:"14px",
      marginRight:"8px",
    },
  },
  '& .subTitleForCreatives':{
    fontWeight:"500",
    fontSize:"64px",
    color:"#0D0C22",
    textTransform:"none",
    lineHeight:"70.4px",
    marginTop:"20px",
    paddingRight:"10px",
    display:"flex",
    flexWrap:"wrap",
    fontFamily:"Silika-Medium !important",
    "@media(max-width:1300px)": {
      fontSize:"48px",
      lineHeight:"50.4px",
    },
    "@media(max-width:917px)": {
      fontSize:"48px",
      lineHeight:"50.4px",
    },
    "@media(max-width:533px)": {
      fontSize:"36px",
      lineHeight:"39.4px",
      maxWidth:"652px",
      paddingRight:"0px",
    },
    "@media(max-width:960px)": {
      width:"100% !important"
     },
  },
  '& .descriptionForCreatives':{
    fontSize:"16px",
    fontWeight:"300",
    lineHeight:"25px",
    color:"#0D0C22",
    paddingRight:"25px",
    marginTop:"50px",
    fontFamily:"Silika-Light !important",
    "@media(max-width:533px)": {
      maxWidth:"582px",
      paddingRight:"0px",
      marginTop:"25px",
    },
    "@media(max-width:960px)": {
      maxWidth:"100% !important"
     },
  },
  '& .getStartedBtn':{
    background:"#0D0C22",
    textTransform:"none",
    color:"white",
    fontWeight:"500",
    fontSize:"16px",
    fontFamily:"Silika-Medium",
    width:"125px",
    height:"48px",
    marginTop:"50px",
    borderRadius:"2px",
    "@media(max-width:1200px)": {
      marginTop:"30px",
    },
    "@media(max-width:533px)": {
      marginTop:"25px",
    },
  },
  '& .getPaidContainer':{
    backgroundColor:"transparent",
    marginBottom:"0px !important",
    marginTop:"0px !important",
    paddingTop:"88px",
    paddingBottom:"33px",
    "@media(max-width:917px)": {
      paddingTop:"70px",
    },
    "@media(max-width:533px)": {
      paddingTop:"36px",
      paddingBottom:"0px",
    },
    "@media(max-width:960px)": {
      paddingTop:"0px",
    },
  },
  '& .getPaidImageDiv':{
    paddingRight:"20px"
  },
  '& .paidContainerItemDiv1':{
    marginBottom:"50px",
    paddingRight:"75px",
    width:"100% !important",
    "@media(max-width:900px)": {
      paddingRight:"0px",
      marginBottom:"0px",
    },
  },
  '& .grayPaidContent':{
    height:"552px",
  },
  '& .paidContainerItemDiv2':{
    marginTop:"50px",
    marginBottom:"130px",
    "@media(max-width:960px)": {
      marginTop:"0px !important",
      marginBottom:"36px !important",
   },
  },
  '& .titleForGetPaid':{
    fontSize:"48px",
    fontWeight:"500",
    color:"#0D0C22 !important",
    lineHeight:"52.8px",
    fontFamily:"Silika-Medium",
    "@media(max-width:1300px)": {
      fontSize:"40px",
      lineHeight:"50.4px",
    },
    "@media(max-width:917px)": {
      fontSize:"36px",
      lineHeight:"40.4px",
    },
    "@media(max-width:470px)": {
      lineHeight:"39.4px",
      fontSize:"35px",
   },
  },
  '& .paidDescripton':{
    fontWeight:300,
    fontSize:"16px",
    lineHeight:"25px",
    color:"#0D0C22 !important",
    marginTop:"50px",
    fontFamily:"Silika-Light",
    maxWidth:"100%",
    "@media(max-width:1440px)": {
      maxWidth:"582px",
      paddingRight:"42px",
    },
    "@media(max-width:1100px)": {
      marginTop:"24px",
      lineHeight:"23px",
    },
    "@media(max-width:553px)": {
      marginTop:"24px",
      lineHeight:"25px",
    },
    "@media(max-width:960px)": {
      maxWidth:"100% !important"
    },
    
  },
  '& .projectDiv':{
    display:"flex",
    marginBottom:"80px",
    background:"white",
    marginLeft:"auto",
    marginRight:"auto",
    maxWidth:"1302px",
    "@media(max-width:1000px)": {  
      paddingLeft:"50px",
      paddingRight:"50px",
    },
    "@media(max-width:960px)": {
      paddingLeft:"34px",
      paddingRight:"24px",
    },
    "@media(max-width:433px)": {
      paddingLeft:"24px !important",
      paddingRight:"20px !important",
      marginBottom:"35px",
    },
  },
 '& .projectTitle':{
    fontWeight:500,
    fontSize:"48px",
    color:"#0D0C22",
    lineHeight:"52.8px",
    alignContent:"center",
    fontFamily:"Silika-Medium",
    "@media(max-width:1300px)": {
      fontSize:"40px",
      lineHeight:"50.4px",
    },
    "@media(max-width:917px)": {
      fontSize:"36px",
      lineHeight:"40.4px",
      marginTop:"36px !important"
    },
    "@media(max-width:470px)": {
      lineHeight:"39.4px",
      fontSize:"35px",
   },
   "@media(max-width:960px)": {
    maxWidth:"100% !important"
  },
  },
  '& .projectTitleDiv':{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column"
  },
  '& .projectDescription': {
    fontWeight: 300,
    fontSize: "16px",
    marginTop:"80px",
    lineHeight: "25px",
    color:"rgb(13,12,34,1) !important",
    fontFamily:"Silika-Light !important",
    "@media(max-width:600px)": {
      marginTop:"80px !important",
    },
    "@media(max-width:450px)": {
      marginTop:"24px !important",
    },
  },
  "& .projectDescDiv":{
    "@media(max-width:960px)": {
      maxWidth:"100% !important",
    },
  },
  '& .joinComContainer':{
    backgroundRepeat:"no-repeat",
    position: 'relative',
    backgroundSize:"cover",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    paddingLeft:"69px",
    paddingRight:"69px",
    marginTop:"140px",
    background:"white",
    "@media(max-width:1000px)": {
      marginTop:"100px !important",
      paddingLeft:"50px",
      paddingRight:"50px",
      marginBottom:"0px !important",
    },
    "@media(max-width:960px)": {
      marginTop:"72px !important",
      paddingLeft:"34px",
      paddingRight:"24px",
      marginBottom:"0px  !important",
    },
    "@media(max-width:433px)": {
      paddingLeft:"24px !important",
      paddingRight:"20px !important",
      marginBottom:"0px",
      marginTop:"0px !important",
    },
  },
  '& .joinComTitle': {
    fontWeight: 500,
    fontSize: "72px",
    lineHeight: "79.2px",
    fontFamily:"Silika-Medium",
    display:"flex !important",
    justifyContent:"center !important",
    textAlign:"center",
    color:"rgba(13,12,34,1)",
    alignItems:"center !important",
    "@media(max-width:1300px)": {
      fontSize:"60px",
      lineHeight:"50.4px",
    },
    "@media(max-width:917px)": {
      fontSize:"48px",
      lineHeight:"50.4px",
    },
    "@media(max-width:533px)": {
      fontSize:"36px",
      lineHeight:"39.4px",
      maxWidth:"652px",
      paddingRight:"0px",
    },
  },
  '& .joinComDes': { 
    fontWeight: 300,
    fontSize: "16px",
    lineHeight:"25px",
    color:"#0D0C22",
    marginTop:"50px",
    fontFamily:"Silika-Light",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center",
    maxWidth:"584px",
    textAlign: "center",
    "@media(max-width:960px)": {
      marginTop: "24px !important",
      maxWidth: "384px", 
    },
    "@media(max-width:584px)": {
      marginTop: "24px !important",
      maxWidth: "284px", 
    },
  },
  "& .videoBackCover": {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover', 
  },
  '& .joinComContent':{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    marginTop:"100px",
    marginBottom:"100px",
    zIndex:10,
    "@media(max-width:960px)": {
      marginTop:"72px !important",
      marginBottom:"50px",
    },
    "@media(max-width:890px)": {
     marginTop:"60px !important",
     marginBottom:"30px",
    },
    "@media(max-width:600px)": {
      marginTop:"40px !important",
    },
  },
  "& .joinCommunity":{
    display:"none",

    fontFamily:"Silika-Light",
    "@media(max-width:960px)": {
       display:"flex !important",
       marginTop:"24px !important",
       justifyContent:"center",
       alignItems:"center"
    },
  },
  "& .outerDivJoin":{
    fontFamily:"Silika-Light",
    justifyContent:"center",
    alignItems:"center",
    display:"flex",
    borderRadius:"4pt",
    "@media(max-width:960px)": {
      display:"none !important",   
   },
  },
  "& .carInnerDiv": {
    "@media(max-width:890px)": {
      height: "600px !important"
    },
    "@media(max-width:600px)": {
      height: "300px !important"
    },
    "@media(max-width:450px)": {
      height: "189.9px !important"
    },
  },
  "& .beInspired":{
    display:"flex",
    marginBottom:"100px",
    background:"white",
    maxWidth:"1302px",
    marginLeft:"auto",
    marginRight:"auto",
    "@media(max-width:1000px)": {
      marginTop:"100px !important",
      paddingLeft:"50px",
      paddingRight:"50px",
      marginBottom:"60px",
    },
    "@media(max-width:960px)": {
      marginTop:"72px !important",
      paddingLeft:"34px",
      paddingRight:"24px",
      marginBottom:"40px",
    },
    "@media(max-width:433px)": {
      paddingLeft:"24px !important",
      paddingRight:"20px !important",
      marginBottom:"11px !important",
    },
    marginTop:"140px",
    "@media(max-width:890px)": {
     marginTop:"60px !important"
    },
    "@media(max-width:600px)": {
      marginTop:"40px !important"
    },
  },
  "& .beInspiredTitle":{
    marginTop:"0px !important",
    fontSize:"48px",
    lineHeight : "52.4px !important",
    width: "100%",
    "@media(max-width:1440px)": {
      width:"390px !important"
     },
  },
  "& .beinsImg":{
    maxWidth:"100% !important",
    "@media(max-width:1440px)": {
     maxWidth:"612px !important"
    },
    "@media(max-width:960px)": {
     maxWidth:"100% !important"
    },

  },
  "& .workingOnEx":{
    maxWidth:"100%",
    color:"#0D0C22",
    "@media(max-width:1440px)": {
      maxWidth:"582px !important"
     },
  },
  "& .descriptionBeIns":{
    color:"rgba(13,12,34,1)",
    maxWidth:"100%",
    "@media(max-width:1440px)": {
      maxWidth:"545px !important"
     },
  }
})

// Customizable Area End

export default class LandingPageWeb extends LandingPageControllerWeb {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
    // Customizable Area Start
    isImageAvailable() {
      const data = this.state.landingPageData[3]?.attributes?.images[0];
      return !!data;
    }
    // Customizable Area End
    render() {
       // Customizable Area Start
       // Customizable Area End
      return (
        // Customizable Area Start
        // Required for all blocks
        <ThemeProvider theme={theme}>
          <LandingHeader navigation={this.props.navigation} />
            <StyledContainer>
              <Grid container>
                <div style={{ paddingLeft: "34px", paddingRight: "34px", width: "100%" }} className="reelDiv">
                  <Grid item xl={12} className="showReel">
                  {this.state.landingPageData[1]?.attributes.images[0] && <video
                              className="showReelText showReelImg"
                              height="100%"
                              width="100%"
                              src={this.state.landingPageData[1]?.attributes?.images[0]?.url || ""}
                              controls
                              autoPlay
                              loop
                              muted
                            />}
                  </Grid>
                </div>
              </Grid>
              <Grid container className="forCreativeContainer">
                <Grid item xl={6} lg={6} md={6} sm={12} className="forCreativeContainerItem">
                  <Typography className="titleForCreatives"><img src={arrowRight} className="arrowRight" alt="arrow" />FOR CREATIVES</Typography>
                  <Typography className="subTitleForCreatives">{this.state.landingPageData[0]?.attributes.title}</Typography>
                  <Grid item xl={6} lg={6} md={6} sm={12} className="forCreativeContainerItemDiv2 joinCommunity">
                  {this.state.landingPageData[0]?.attributes.images[0] && <img className="showReelText" height="100%" width="100%" src={this.state.landingPageData[0]?.attributes?.images[0]?.url} alt="show reel" />}
                  </Grid>
                  <Typography className="descriptionForCreatives" style={{ maxWidth: "582px" }}>
                    {this.state.landingPageData[0]?.attributes.description && parse(this.state.landingPageData[0]?.attributes.description) || ""}
                  </Typography>
                  <Button className="getStartedBtn" onClick={() => this.handleNavigation("LandingPage2Web")} data-test-id="creativeBtn">Get Started</Button>
                </Grid>
    <Grid item xl={6} lg={6} md={6} sm={12} className="forCreativeContainerItemDiv2 outerDivJoin imgUpMaxWidth" style={{ paddingLeft:"35px"}}>
                { this.state.landingPageData[0]?.attributes.images[0] &&  <img className="showReelText" width="100%" height="100%" src={this.state.landingPageData[0]?.attributes?.images[0]?.url} alt="show reel" />}
                </Grid>
              </Grid>
              <div className="grayContainer">
              <Grid container className="forCreativeContainer getPaidContainer">
                <Grid item xl={6} lg={6} md={6} sm={12} className="paidContainerItemDiv1 outerDivJoin">
                  <Box className="grayPaidContent forCreativeContainerItemDiv2">
                  { this.state.landingPageData[2]?.attributes.images[0] &&<img className="showReelText" height="100%" width="100%" src={this.state.landingPageData[2]?.attributes?.images[0]?.url} alt="show reel" />}
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} className="paidContainerItemDiv2">
                  <Typography className="titleForGetPaid" style={{ maxWidth: "642px" }}>{this.state.landingPageData[2]?.attributes.title}</Typography>
                  <Grid item xl={6} lg={6} md={6} sm={12} className="forCreativeContainerItemDiv2 joinCommunity" style={{ maxWidth: "612px !important" }}>
                  { this.state.landingPageData[2]?.attributes.images[0] && <img className="showReelText beinsImg" height="100%" src={this.state.landingPageData[2]?.attributes?.images[0]?.url} alt="show reel" />}
                  </Grid>
                  <Typography className="paidDescripton">
                    {this.state.landingPageData[2]?.attributes.description && parse(this.state.landingPageData[2]?.attributes.description) || ""}</Typography>
                  <Button className="getStartedBtn" onClick={() => this.handleNavigation("AccountRegistration")} data-test-id="paidBtn">Get Started</Button>
                </Grid>
              </Grid>
              </div>
              <Grid container className="projectDiv">
                <Grid container>
                  <Grid item xl={6} lg={6} md={6} sm={12} className="projectTitleDiv">
                    <Typography className="projectTitle" style={{ maxWidth: "495px", paddingTop: "70px" }}>{this.state.landingPageData[3]?.attributes?.title}</Typography>
                    { this.state.landingPageData[3]?.attributes.images[0] &&<img src={this.state.landingPageData[3]?.attributes?.images[0]?.url} height="756px" width="100%" alt="carInner" className="joinCommunity carInnerDiv" />}
                  </Grid>
                  <Grid item md={6} sm={12} xl={6} lg={6} className="projectDescDiv workingOnEx">
                    <Typography className="projectDescription">
                      {this.state.landingPageData[3]?.attributes?.description && parse(this.state.landingPageData[3]?.attributes?.description) || ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: "69px", marginBottom: "50px" }} className="outerDivJoin">
               {this.isImageAvailable() && (
          <img
            style={{ borderRadius: "4px" , aspectRatio: "16 / 9" }}
            src={this.state.landingPageData[3]?.attributes?.images[0]?.url}
            alt="carInner"
            width="100%"
          />
        )}
                </Grid>
              </Grid>
              <div className="grayContainer">
              <Grid container className="forCreativeContainer getPaidContainer">
                <Grid item xl={6} lg={6} md={6} sm={12} className="paidContainerItemDiv1 outerDivJoin">
                  <Box className="grayPaidContent forCreativeContainerItemDiv2">
                  { this.state.landingPageData[4]?.attributes.images[0] && <img className="showReelText" height="100%" width="100%" src={this.state.landingPageData[4]?.attributes?.images[0]?.url} alt="show reel" />
                   }</Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} className="paidContainerItemDiv2">
                  <Typography className="titleForGetPaid" style={{ maxWidth: "642px" }}>{this.state.landingPageData[4]?.attributes.title}</Typography>
                  <Grid item xl={6} lg={6} md={6} sm={12} className="forCreativeContainerItemDiv2 joinCommunity" style={{ maxWidth: "612px !important" }}>
                  { this.state.landingPageData[4]?.attributes.images[0] &&  <img className="showReelText beinsImg" height="100%" src={this.state.landingPageData[4]?.attributes?.images[0]?.url} alt="show reel" />}
                  </Grid>
                  <Typography className="paidDescripton">
                    {this.state.landingPageData[4]?.attributes.description && parse(this.state.landingPageData[4]?.attributes.description) || ""}</Typography>
                  <Button className="getStartedBtn" onClick={() => this.handleNavigation("AccountRegistration")} data-test-id="paidBtn">Get Started</Button>
                </Grid>
              </Grid>
              </div>
              <Grid container className="beInspired">
                <Grid item xl={6} lg={6} md={6} sm={12} className="forCreativeContainerItem">
                  <Typography className="subTitleForCreatives beInspiredTitle">{this.state.landingPageData[5]?.attributes?.title}</Typography>
                  <Grid item xl={6} lg={6} md={6} sm={12} className="forCreativeContainerItemDiv2 joinCommunity">
                    {this.state.landingPageData[5]?.attributes.images[0] &&  <img className="showReelText" height="100%" width="100%" src={this.state.landingPageData[5]?.attributes?.images[0]?.url} alt="show reel" />}
                  </Grid>
                  <Typography className="descriptionForCreatives descriptionBeIns">
                    {this.state.landingPageData[5]?.attributes?.description && parse(this.state.landingPageData[5]?.attributes?.description) || ""}
                  </Typography>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} className="forCreativeContainerItemDiv2 outerDivJoin" style={{ paddingLeft:"35px"}}>
                {this.state.landingPageData[5]?.attributes.images[0] && <img className="showReelText beinsImg" height="100%" width="100%" src={this.state.landingPageData[5]?.attributes?.images[0]?.url} alt="show reel" />}
                </Grid>
              </Grid>
            <Grid container className="joinComContainer" style={{background : "#0D0C22"}} >
              <video
                autoPlay
                loop
                muted
                className="videoBackCover"
              >
                <source src={animationVideo} type="video/mp4" />
              </video>
              <Grid item xl={8} lg={8} md={10} sm={12} className="joinComContent">
                <Typography className="joinComTitle">{this.state.landingPageData[6]?.attributes?.title}</Typography>
                <Box className="joinComDes">
                  {this.state.landingPageData[6]?.attributes?.description && parse(this.state.landingPageData[6]?.attributes?.description) || ""}
                </Box>
                <Button className="getStartedBtn" onClick={() => this.handleNavigation("AccountRegistration")} data-test-id="JoinBtn">Get Started</Button>
              </Grid>
            </Grid>
            </StyledContainer>
          <LandingFooter navigation={this.props.navigation} />
        </ThemeProvider>
        // Customizable Area End
      );
    }
  }
  // Customizable Area Start
  // Customizable Area End
