import React from "react";
import { styled, Typography, Button, Table, TableCell, TableRow, TableBody, TableHead, TableContainer, Theme, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { SubscriptionPlan, SubscriptionPlanFeature1, FeatureCategory, SubscriptionPlanFeature } from "./types";
import CloseIcon from '@material-ui/icons/Close';
interface SubscriptionProps {
    windowWidth: number;
    subscriptionPlans: SubscriptionPlan[];
    subscriptionFeatureCategories: FeatureCategory[];
    subscriptionFeatures: SubscriptionPlanFeature[];
    viewAllFeaturesOpen: boolean;
    onClickFeaturesOpen: () => void;
    onClickBackToTop: () => void;
    isHeaderNotNeeded?: boolean
}

interface S {
    isOpen: boolean
}

export default class SubscriptionCards extends React.Component<SubscriptionProps, S>{

    constructor(props: SubscriptionProps){
        super(props);
        this.state = {
            isOpen: false
        }
    }

    handleOnClickSubscription = () => {
        this.setState({isOpen: !this.state.isOpen})
    }

    handleClickGetItNow = (plan: string) => {
        if(plan !== "Free"){
            this.handleOnClickSubscription();
        }
    }
    renderCard = (subscription: SubscriptionPlan) => {
        const title = subscription.attributes.plan_name;
        const description = subscription.attributes.description;
        const currency = subscription.attributes.currency;
        const duration = subscription.attributes.duration;
        const amount = subscription.attributes.amount;
        const features = subscription.attributes.features.slice(0, 3).map((f: SubscriptionPlanFeature1) => f.title);
        const currentPlan = title === "Free";
        const actionBtntring = title === "Free" ? "Your current plan" : `Get ${title}`;
        return (
            <StyledCard data-test-id="subscriptionCard">
                <div className="top">
                    <Typography className="title">{title}</Typography>
                    <Typography className="description">{description}</Typography>
                </div>
                <div className="bottom">
                    <div style={{height:"10px"}}>
                    {currentPlan && <span className="currentPlan">Current plan</span>}
                    </div>
                    <span className="price">
                        {currency} {amount}
                    </span>
                    <span className="perMonth">
                        {duration}
                    </span>
                    <div className="groupFeatures">
                        {features.length > 0 ? features.map(feature => <div className="groupFeature">
                            <CheckIcon/>
                            <span className="groupFeatureName">{feature}</span>
                        </div>): <></>}
                    </div>
                    <Button className="actionBtn" onClick={() => currentPlan ? undefined: this.handleOnClickSubscription()}>
                        {actionBtntring}
                    </Button>
                    <Typography className="cancelBtn" component="button">Cancel anytime. No strings attached.</Typography>
                </div>
            </StyledCard>
        )
    };

    renderFeatures = (categoryId: string, features: SubscriptionPlanFeature[]) => {
        const { subscriptionPlans } = this.props;
        return features.map((feature: SubscriptionPlanFeature) => (<TableRow key={feature.id}>
            <TableCell>{feature.attributes.title}</TableCell>
            {subscriptionPlans && subscriptionPlans.length > 0 ? subscriptionPlans.map((sPlan: SubscriptionPlan) => {
                const sFeatureIds = sPlan.attributes.features.filter((f: SubscriptionPlanFeature1) => f.subscription_plan_feature_category_id.toString() === categoryId).map((f: SubscriptionPlanFeature1) => f.id.toString());
                return <TableCell>
                    <div className="tickMark">
                    {sFeatureIds.includes(feature.id) ? <CheckIcon/> : <></>}
                    </div>
                </TableCell>
            }) : <></>}
        </TableRow>));
    };

    render(){
    const { isHeaderNotNeeded, onClickBackToTop, onClickFeaturesOpen, viewAllFeaturesOpen, windowWidth, subscriptionFeatureCategories, subscriptionFeatures, subscriptionPlans } = this.props;
    const isMobile = windowWidth <= 599;

    return (
        <StyledSubscriptionContainer  data-test-id="subscriptionCards">
            {isHeaderNotNeeded ? <></> : <Typography className="choosePlan">Choose the plan that works best for you.</Typography>}
            <StyledCardList className="subscriptionCardsList">
                {this.props.subscriptionPlans.length > 0 ? 
                this.props.subscriptionPlans.map((subscription: SubscriptionPlan) => {
                    return this.renderCard(subscription)
                })
                : <></>
                }
            </StyledCardList>
            <StyledViewAllFeatures onClick={() => onClickFeaturesOpen()} data-test-id="viewAllFeatures" viewAllFeaturesOpen={viewAllFeaturesOpen}>
                View all features {viewAllFeaturesOpen ?<ExpandLessIcon/> : <ExpandMoreIcon/>}
            </StyledViewAllFeatures>
            {viewAllFeaturesOpen ? <>
                    <StyledTableContainer data-test-id="subscriptionCardsContainer">
                        <Table>
                            <StyledTableHead>
                                <TableRow>
                                    <TableCell className="tableCellEmpty"></TableCell>
                                    {subscriptionPlans.map((subscription: SubscriptionPlan) => <TableCell className="tablecell">
                                        <div className="headTitle">
                                            <span>{subscription.attributes.plan_name}</span>
                                            {!isMobile ? <Typography onClick={() => this.handleClickGetItNow(subscription.attributes.plan_name)}>Get it Now</Typography>
                                                : <Typography data-test-id="mobileCurrentPlan" className="currentPlan" onClick={() => this.handleClickGetItNow(subscription.attributes.plan_name)}>Current plan</Typography>}
                                        </div>
                                    </TableCell>)}
                                </TableRow>
                            </StyledTableHead>
                            <StyledTableBody>
                                {subscriptionFeatureCategories.map((sCategory: FeatureCategory) => {
                                    let categoryId = sCategory.id;
                                    let categoryFeatures = subscriptionFeatures.length > 0 ? subscriptionFeatures.filter((sFeature: SubscriptionPlanFeature) => sFeature.attributes.feature_category.id.toString() == categoryId) : [];
                                    if (categoryFeatures.length > 0) {
                                        return (
                                            <>
                                                <TableRow key={-1} className="titleBar">
                                                    <TableCell colSpan={5}>{sCategory.attributes.name}</TableCell>
                                                </TableRow>
                                                {this.renderFeatures(categoryId, categoryFeatures)}
                                            </>
                                        )
                                    }
                                    return <></>
                                })}

                            </StyledTableBody>
                        </Table>
                    </StyledTableContainer>
            <StyledViewAllFeatures data-test-id="backTopTopButton" style={{border:"none"}} onClick={() => onClickBackToTop()} viewAllFeaturesOpen={viewAllFeaturesOpen}>
                Back to the top <ExpandLessIcon/>
            </StyledViewAllFeatures>
            </>: <></>}
            <StyledDialog
                open={this.state.isOpen}
                onClose={this.handleOnClickSubscription}
            >
                <DialogTitle className="title"><CloseIcon onClick={this.handleOnClickSubscription}/></DialogTitle>
                <DialogContent className="dialogContent">
                    <Typography>Paid plans are not yet available. It will be coming soon.</Typography>
                </DialogContent>
            </StyledDialog>
        </StyledSubscriptionContainer>
    )
    }
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    boxShadow: 'none',
    border: `1px solid #DFDFDF`,
    borderRadius:"4pt",
    borderBottom: "0px",
    "& table": {
        maxWidth: "1312px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    [theme.breakpoints.down('sm')]:{
        margin: "0 auto",
        width: "calc(100vw - 50px)"
    }
}));
  
const StyledTableHead = styled(TableHead)(({ theme }: {theme: Theme}) => ({
    backgroundColor: '#f8f8f8',
    borderBottom: "0px",
    '& .MuiTableCell-root': {
        fontWeight: 'bold',
        fontSize: '14px',
        padding: '12px',
        borderBottom: '2px solid #ddd',
        color: '#333',
    },
    "& .tableCellEmpty":{
        width: "296px",
        [theme.breakpoints.down('sm')] :{
            width: "auto"
        }
    },
    "& .tablecell":{
        padding: "16px 28px 12px 28px",
        width: "98px",
        borderRight: "0.5px solid #DFDFDF",
        borderLeft: "0.5px solid #DFDFDF",
        height: "100%",
        "&:last-child":{
            borderRight: "0px",
        },
        [theme.breakpoints.down('sm')] :{
            padding: "10px 5px",
            height: "127px",
            width: "12%"
        }
    },
    "& .headTitle":{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        alignItems: "center",
        [theme.breakpoints.down('sm')] :{
            flexDirection: "column",
            textOrientation: "mixed",
            width: "max-content !important",
            alignItems: "baseline",
            "@supports (-webkit-appearance: none)": {
                alignItems: "flex-start"
            }
        },
        "& p":{
            borderRadius: '4.21px',
            fontWeight: 500,
            background: "#0D0C22",
            color: "#fff",
            fontSize: "16.2px",
            lineHeight: "25.27px",
            fontFamily: "Silika-Medium",
            width:"132px",
            height:"37px",
            marginTop: "8px",
            alignItems: "center",
            display:"flex",
            justifyContent:"center",
            cursor: "pointer",
            [theme.breakpoints.down('sm')] :{
                margin: "0",
                padding: "0 0.5px",
                height: "30px",
                textAlign: "center",
                borderRadius: "59px"
            }
        },
        "& span": {
            letterSpacing: "-0.12634074687957764px",
            fontWeight: 500,
            marginTop: "0px",
            marginBottom: "auto",
            textAlign: "center",
            fontSize: "25.27px",
            lineHeight: "33.69px",
            [theme.breakpoints.down('sm')]: {
                margin: "0",
                lineHeight: "14px",
                marginRight: "4px",
                fontSize: "14px",
            }
        }
    }
}));
  
const StyledTableBody = styled(TableBody)(({ theme }: {theme: Theme}) => ({
    '& .MuiTableCell-root': {
      padding: '14.5px 12px',
      borderBottom: `0.5px solid #DFDFDF`,
      borderRight: "0.5px solid #DFDFDF",
      fontSize: '14px',
      color: '#010101',
      fontFamily: "Silika-Light",
      lineHeight: "18.72px",
      [theme.breakpoints.down('sm')] :{
        padding: "8px 12px"
      }
    },
    '& .MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9',
    },
    "& .tickMark":{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "& svg":{
            color: "#00FFA7"
        }
    },
    "& .titleBar":{
        "& td":{
            background: "#DFDFDF",
            fontFamily: "Silika-Medium"
        }
    }
})); 

const StyledViewAllFeatures = styled(Button)(({theme,viewAllFeaturesOpen}: {theme: Theme ,viewAllFeaturesOpen: boolean}) => ({
    textTransform: "none",
    height: "44px",
    width: "200px",
    margin: "48px auto 0px auto",
    borderWidth: !viewAllFeaturesOpen ? "1px 1px 1px 1px" : "1px 1px 0 1px",
    borderStyle: "solid",
    borderColor: " #DFDFDF",
    borderRadius: "2px 2px 0px 0px",
    "& span":{
        fontWeight:500,
        fontSize:"16px",
    },
    "& svg":{
        marginLeft: "8px"
    }
}))

const StyledSubscriptionContainer = styled("div")(({theme}: {theme: Theme}) => ({
    paddingTop: "40.07px",
    display: "flex",
    width: "100%",
    flexFlow: "column",
    justifyContent: "center",
    "& *":{
        fontFamily: "Silika-Medium"
    },
    "& p":{
        fontSize: "16px",
        lineHeight: "32px"
    },
    "& .choosePlan":{
        marginBottom: "13px",
        [theme.breakpoints.down('sm')] :{ 
            textAlign: "center"
        }
    },
}));

const StyledCardList = styled("div")(({theme}: {theme: Theme}) => ({
    display: "inline-flex",
    maxWidth:"1312px",
    marginLeft:"auto",
    marginRight:"auto",
    [theme.breakpoints.down('sm')] :{ 
        flexWrap: "wrap",
        justifyContent: "center",
        width: "calc(100vw - 50px)",
        margin:"0 auto"
    },
    [theme.breakpoints.down('md')] :{ 
            flexWrap: "wrap",
            justifyContent: "center",
            width: "calc(100vw - 50px)",
            margin:"0 auto"
        }
}));

const StyledCard = styled("div")(({theme}: {theme: Theme}) => ({
    border: "0.78px solid #DFDFDF",
    padding: "33.8px 23px",
    height: "auto",
    width: "315px",
    background: "#fff",
    marginRight: "12px",
    marginBottom: "12px",
    flexFlow: "column",
    borderRadius:"4pt",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')] :{ 
        marginRight: "0px",
        width: "100%",
        marginBottom: "24px",
        boxShadow: "0px 2px 8px 0px #00000014",
        transition: "boxShadow 0.6s ease",
        "& .actionBtn":{
            background: "#0D0C22 !important",
            color: "#fff",
            border: "1px solid #0D0C22",
            transition: "background-color 0.6s ease, color 0.7s ease",
        }
    },
    "&:hover":{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 8px 32px 0px, rgba(0, 0, 0, 0.06) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px, rgba(0, 0, 0, 0.09) 0px 25px 50px 0px",
        transition: "boxShadow 0.6s ease",
        "& .actionBtn":{
            background: "#0D0C22 !important",
            color: "#fff",
            border: "1px solid #0D0C22",
            transition: "background-color 0.6s ease, color 0.7s ease",
        }
    },
    "& .title":{
        fontSize: "24px",
        lineHeight: "33.69px",
        fontWeight:500,
        marginBottom: "8px"
    },
    "& .description":{
        fontFamily: "Silika-Light",
        fontSize: "16px",
        lineHeight: "25.72px",
        fontWeight:300,
        color:"#010101",
        height:"59px"
    },
    "& .price":{
        fontSize: "48px",
        fontWeight: 500,
        letterSpacing: "-0.7580444812774658px",
        marginTop: "15.13px",
        lineHeight: "58.96px",
        display: "block"
    },
    "& .currentPlan":{
        background: "#0D0C22",
        color: "#fff",
        borderRadius: "2pt",
        fontSize: "9.48px",
        lineHeight: "14.23px",
        fontFamily: "Silika-Medium",
        padding: "2px 7px"
    },
    "& .perMonth":{
        fontSize: "16px",
        lineHeight: "25.27px",
        display: "block",
        fontFamily: "Silika-Light",
        fontWeight:300,
        color:"#9B9B9B"
    },
    "& .groupFeatures":{
        margin: "38px 0"
    },
    "& .groupFeature":{
        display: "inline-flex",
        width: "100%",
        alignItems: "center",
        marginBottom: "8px",
        "& svg":{
            color: "#00FFA7",
            height: "16px"
        }
    },
    "& .groupFeatureName":{
        fontSize: "16px",
        lineHeight: "25.27px",
        marginLeft: "8px",
        fontFamily: "Silika-Light",
        fontWeight:300,
        color:"#010101"

    },
    "& .actionBtn":{
        borderRadius: "2pt",
        border: "0.39px solid #DFDFDF",
        textTransform: "none",
        height: "58px",
        width: "100%",
        fontSize: "16px",
        marginBottom: "18px",
        fontFamily:"Silika-Medium"
    },
    "& .cancelBtn":{
        background: "none",
        border: "none",
        fontSize: "12px",
        lineHeight: "18.72px",
        padding: "0px",
        fontFamily: "Silika-Light",
        fontWeight:300,
         color:"#010101"
    }
}));


const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& *": {
      fontSize: "16px",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      borderRadius: 4,
      width: "600px",
      boxShadow: "none",
      height: "150px",
    },
    "& .title":{
        backgroundColor: '#0D0C22',
        color: '#fff',
        padding: '18px 37px 18px 40px',
        fontWeight: 'bold',
    "& h2":{
        width: "100%",
        display: "inline-flex",
        alignItems: "center"
    },
    "& button":{
        marginRight: "0px",
        marginLeft: "auto",
        padding: "0px",
        borderRadius: "2px"
    },
    "& *": {
        fontFamily: "Silika-Medium",
    },
    "& path": {
        fill: "#fff"
    },
    "& svg":{
        marginRight: "0px",
        marginLeft: "auto"
     }
    },
    "& .dialogContent":{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& p":{
            textAlign: "center",
            fontFamily: "Silika-Medium"
        }
     }
  }));