import React from "react";
// Customizable Area Start
import PostCreationWebController, { Post, Props } from "./PostCreationWebController";
import {
  Grid,
  Typography,
  Container,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Avatar,
  DialogTitle,
  IconButton,
  Chip,
  TextField,
  TextareaAutosize,
  Tooltip,
  Theme, styled, ClickAwayListener
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { likeImage, saveImage, optionIcon, addIcon,viewBtn, removeIcon,dislikeImage, CopyImage, ChatIcon, BackBtn } from "../../customisableuserprofiles/src/assets";
import PostCreationPopUp from "./components/PostCreationPopUp"
import Preview from "./components/Preview"
import { returnTruthyString, getSurname, isImageOrVideo } from "../../../components/src/HelperUtils"
import "./PostCreation.css";
import AddCollabarator from "./components/AddCollabarator";
import DeleteCollab from "./components/DeleteCollab";
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import { DialogActionWrapper, DialogContentTextWrapper, DialogContentTextWrapperChat, StyledBox } from "../../../blocks/events/src/EventView.web";
import ClearIcon from '@material-ui/icons/Clear';
import { Autocomplete } from "@material-ui/lab";
import Carousel from "react-elastic-carousel";
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { orangeBanner } from "../../landingpage/src/assets";
import { customArrow } from "../../../components/src/CustomArrow.web";
import { restricted, visibilityOn, visibilityOff, blackClose } from "./assets";
import ShareIcon from '@material-ui/icons/Share';
import { ShareMenuDialog, ShareLinkDialog } from "../../../components/src/ShareDialogs.web";
const StyledPostDetails = styled(Dialog)(({theme}: {theme: Theme}) => ({
  width: "100%",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]:{
    "& .boxContainerMob":{
      height: "auto"
    }
  },
  "& .postDetailsPaper":{
    maxWidth: "1232px",
    width: "1232px",
    boxShadow: "none",
    overflow: "hidden",
    borderRadius: "4px",
    height: "100%",
    "@media (max-width: 599px)":{
      height: "100%",
      maxHeight: "100%",
      margin: "0px"
    }
  },
  "& .postDetailsBackdrop":{
    backgroundColor: "rgba(255, 255, 255, 0.6)"
  },
  "@media (max-width: 599px)":{
    top:"0px !important"
  },
  "& .postDetailsContent":{
    padding: "0px",
    height: "100%",
    border: "1px solid #d9d9d9"
  },
  "& .backButton":{
    marginLeft: "40px",
    textTransform: "none",
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    marginBottom: "24px",
    "@media (max-width: 768px)":{
      marginLeft: "20px",
      marginBottom: "12px",
    },
    "& svg":{
      marginRight: "11px"
    }
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    color: "#000000",
      borderLeft: "4px solid #010101",
      padding: "8px 14px 8px 10px",
      fontFamily: "Silika-Medium",
      fontSize: "12px",
      borderRadius: "2px",
      backgroundColor: "#fff"
  }
}));

const ProfileAvatar = styled(Avatar)({
  backgroundColor: "#DFDFDF",
  color: "#9B9B9D",
  fontFamily: "Silika-Medium !important",
  "& .MuiAvatar-img": {
    objectFit: "cover",
  }
});

const StyledCarousel = styled("div")(({theme}: {theme: Theme}) => ({
  maxWidth: "1140px",
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
  marginTop: "87px",
  "& .carouselMain":{
    margin: "-13.5px",
    position: "relative",
    width: "calc(100% + 27px)",
    "@media (max-width: 768px)": {
      width: "auto"
    }
  },
  "@media (max-width: 768px)": {
    "& .carouselMain":{
      margin: "0px"
    },
    display: "contents !important"
  },
  "& .collaborationGridItem":{
    maxHeight: "280px",
    aspectRatio: "362/280",
    width: "100%",
  },
  "& .collaborationGridItem:hover .collabImgHover, .collaborationGridItem:hover .likeShareSaveButtonsDiv, .collaborationGridItem:hover .removeCollab": {
    opacity: 1
  },
  "& .collaborationGridItem:hover .collaborationGridItemInside": {
    backgroundColor: "rgba(217, 217, 217, 0.36)",
    filter: "grayscale(0.3)",
    transition: "opacity 0.3s ease",
  },
  "& .rec-item-wrapper":{
    height: "100%"
  },
  "& .collaborationGridItem:hover .collabImg": {
    opacity: 0.5
  },
  "& .collaborationGridItem, .collaborationGridItemInside, .collabImg":{
    height: "100%"
  },
  "& .rec-slider-container":{
    margin: "0px !important"
  },
  "& .collaborationGridItemOutside":{
    borderRadius: "4px",
    padding: "0 13px",
    "& img":{
      borderRadius: "4px"
    },
    "@media (max-width: 768px)": {
      padding: "13px",
    }
  },
  "& .collabImg, .restrictedDivMain, .rec-item-wrapper": {
    width: "100%"
  },
  "& .collabImg":{
    maxHeight: "280px",
    objectFit: "cover",
  },
  "& .collaborationGridItemInside":{
    maxHeight: "280px",
    position: "relative"
  },
  "& .collabImgHover": {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    fontFamily: "Silika-Medium",
    justifyContent: "center",
    color: "#010101",
    opacity: 0,
    display: "flex",
    fontSize: "20px",
    alignItems: "center",
    "@media (max-width: 824px)": {
      fontSize: "14px",
    },
  },
  "& .restrictedImg": {
    width: "24px",
    height: "24px",
  },
  "& .restrictedDiv": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .restrictedDivMain": {
    position: "absolute",
    top: "0%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.9)"
  },
  "& .confidentialText": {
    fontFamily: "Silika-Medium",
    fontSize: "20px",
    fontWeight: 500,
    marginLeft: "4px",
    color: "#FFFFFF",
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .likeShareSaveButtonsDiv": {
    display: "inline-flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "12px",
    opacity: 0,
    "& .actionButton": {
      height: "24px",
      width: "24px",
      pointer:"pointer",
      "@media (max-width: 802px)": {
        height: "18px",
        width: "18px",
      },
      "&:first-child": {
        marginRight: "12px"
      }
    },
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .confidentialPara": {
    color: "#FFFFFF",
    maxWidth: "220px",
    fontFamily: "Silika-Light",
    margin: 0,
    fontSize: "14px",
    fontWeight: 500,
    "@media (max-width: 768px)": {
      display: "none"
    },
  },

  "& .actionButtonImg": {
    height: "22px",
    marginRight: "12px",
    cursor:"pointer",
    width: "22px",
    "@media (max-width: 802px)": {
      height: "18px",
      width: "18px",
    },
  },
  "& .customArrow": {
    transform: "translateY(-50%)",
    position: "absolute",
    zIndex: 1,
    top: "45%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    }
  },
  "& .rec-swipable": {
    width: "100% !important"
  },
  "& .prevArrow": {
    left: "30px"
  },
  "& .nextArrow": {
    right: "30px"
  },
  "@media (max-width: 2000px)": {
    "& .carousel-item": {
      width: "33.33%",
    },
  },
  "@media (max-width: 480px)": {
    "& .carousel-item": {
      width: "100%",
    },
  },
}));


const StyledShareChatDial = styled(Dialog)({
  "& .headingText": {
    fontFamily: "Silika-Medium !important"
  },
  "& .dialogBackdrop":{
    backgroundColor: "#ffffffc4",
  },
  "& .dialogPaper":{
    boxShadow: "none",
  }

})

export const dropdownLinks = (navText: string, handleLink: () => void) => {
  return (
    <div >
      <div>
        <div onClick={handleLink}
          data-test-id="dropdownLinks"
          style={{ ...webStyle.discoverHyperLink }}>
          {navText}
        </div>
      </div>
      <Box style={webStyle.discoverDropDownWidth}>
        <Typography
          style={webStyle.discoverOptionBorder}
        />
      </Box>
    </div>
  )
}

const StyledProfile = styled("div")(({theme}: {theme: Theme}) => ({
  [theme.breakpoints.down("sm")]:{
    width: "calc(100vw - 154px)",
    margin: "0 auto !important"
  },
  [theme.breakpoints.down("xs")]:{
    width: "calc(100% - 49px)"
  },
  "& .userProfileImgDiv":{
    width: "fit-content",
    height: "fit-content",

    "@media (max-width: 768px)":{
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: "-155px",
      zIndex: 9999
    },
    "@media (max-width: 699px)":{
      marginTop: "-140px",
    },
    "@media (max-width: 519px)":{
      marginTop: "-120px",
    },
    "@media (max-width: 399px)":{
      marginTop: "-100px",
    },
    "@media (max-width: 349px)":{
      marginTop: "-90px",
    },
  },
  "& .userProfileImg":{
    borderRadius: "100px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "189px",
    height: "189px",
    "@media (max-width: 768px)":{
      width: "150px",
      height: "150px",
      border: "5px solid #fff",
    },
    "@media (max-width: 699px)":{
      width: "120px",
      height: "120px",
    },
    "@media (max-width: 519px)":{
      width: "80px",
      height: "80px",
      border: "3px solid #fff",
    },
    "@media (max-width: 399px)":{
      width: "60px",
      height: "60px",
    },
    "@media (max-width: 349px)":{
      width: "40px",
      height: "40px",
    },
    fontSize: "50px"
  },
  "& .profileinfoDiv":{
    display: "flex",
    marginTop: "30px",
    justifyContent: "space-between",
   
    "@media (max-width: 768px)": {
      flexWrap: "wrap",
    },
  },
  "& .userName":{
    fontSize: "32px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "38.61px"
  },
  "& .description":{
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "19.69px",
    fontStyle: "normal",
    marginTop: "11px",
  },
  "& .table-description-wrapper":{
    marginTop: "39px"
  },
  "@media (max-width: 768px)": {
    border: "1px solid #DFDFDF",
    borderRadius: "4px !important",
    display: "flex",
    flexDirection: "column-reverse",
    marginBottom: "24px !important"
  },
  "& .user-profile-img": {
    width: "20%",
    "@media (max-width: 768px)": {
      marginTop: "-130px"
    },
    "@media (max-width: 450px)": {
      marginTop: "-110px"
    }
  },
  "& .user-detail-div": {
    width: "78%",
    "@media (max-width: 768px)": {
      width: "100%"
    }
  },
  "& .outerMostMobileSizer": {
    height: "auto !important",
    marginTop: "10px !important",
    paddingBottom: "10px !important",
    borderRadius: "4px !important",
    justifyContent: "center !important",
    width: "86% !important",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    marginBottom: "10px !important",
  },
  "& .profileImgMob": {
    "@media (max-width: 1026px)": {
      height: "170px !important"
    },
    "@media (max-width: 924px)": {
      height: "150px !important"
    },
    "@media (max-width: 768px)": {
      border: "5px solid white",
      height: "100px !important"
    },
    "@media (max-width: 450px)": {
      height: "61px !important",
      width: "61px !important"
    },
  },
  "& .levelDivMobile": {
    "@media (max-width: 768px)": {
      width: "100% !important",
      display: "flex",
      justifyContent: "center"
    }
  },
  "& .userNameMob": {
    "@media (max-width: 768px)": {
      display: "none !important"
    }
  },
  "& .locationOuterDiv": {
    "& .description":{
      fontSize: "14px",
      fontFamily: "Silika-Medium",
      fontWeight: 500,
      lineHeight: "140.625%",
      fontStyle: "normal",
      marginTop: "18px"
    },
    "@media (max-width: 768px)": {
      marginRight: "0px !important",
      marginTop: "0px !important",
      "& .description":{
        whiteSpace: "pre-wrap",
        width: "calc(100% - 12px)",
        textAlign: "center"
      }
    }
  }
}))
// Customizable Area End

export default class ListPost extends PostCreationWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getButtonClass() {
    return this.state.shareData?.length === 0 ? 'opacity-05' : '';
  }

  renderLikeSaveShare = (index: number, isFavorited: boolean, isLiked: boolean, id: string, postID: number, profileId: number) => {
    return <>
      <div className="likeShareSaveButtonsDiv" data-test-id="likeShareSaveButtonsDiv" key={index}>
        {isLiked ? (
          <FavoriteIcon className="actionButton" data-test-id="favoriteIconCreator" onClick={(event)=>{
            event.stopPropagation();
            this.likeCreatorPost("unlike",postID)
          }} />
        ) : (
          <FavoriteBorderIcon className="actionButton" data-test-id="favoriteBorderIcon" onClick={(event)=>{
            event.stopPropagation();
            this.likeCreatorPost("like",postID)}}  />
        )}

        <ShareIcon className="actionButtonImg" height={20} width={20} data-test-id="openPopupGallery" onClick={(event) => { 
          event.stopPropagation();
          this.openPopUpShareModal(postID, "Profiles", profileId) }}  />
        {isFavorited ? (
          <BookmarkIcon className="actionButton" data-test-id="bookmarkIconCreator" onClick={(event) => {
            event.stopPropagation();
            this.handleDeleteFavProfile(postID, "post")}}  />
        ) : (
          <BookmarkBorderIcon className="actionButton" data-test-id="bookmarkBorderIcon" onClick={(event)=>{
            event.stopPropagation();
            this.handleAddFavouriteProfile(postID, "BxBlockPosts::Post")}} />
        )}
      </div>
    </>
  }

  isLikedPost=(postId:number):boolean=>{
    return this.state.AllPostLikes.some(
      (like: any) => like.attributes.post_id === postId
    );
  }

  isFavouritePost = (postId:number):boolean=>{
    return this.state.allSavedPost.some(
      (like: any) =>
        like.attributes.favouriteable_id === postId
    );
  }

  carouselCreatorPost = () => {
    const lengthIsGreater = this.state.getAllCreatorPost.length > 3;
    return (<StyledCarousel>
      <div className="carouselMain">
        <Carousel
          renderArrow={(props) => customArrow({ ...props, lenghIsGreater: lengthIsGreater })}
          isRTL={false}
          itemsToShow={3}
          data-test-id="carousel-main-div"
          className="carouselGrid"
        >
          {this.state.getAllCreatorPost.length > 0 && this.state.getAllCreatorPost.map((post: Post,index) => {
            let isConfidential = post.attributes.confidential;
            const profileUserId = sessionStorage.getItem("profileUserId") || "";
            if((sessionStorage.getItem("profileId") === post.attributes.profile.data.id) || post.attributes.collaborator_ids.includes(profileUserId)){
                isConfidential = false;
              }
              const urlParams = new URLSearchParams(post.attributes.images[0].url.split('?')[1]);
              const type = urlParams.get('type');
            return <Grid
              className="collaborationGridItem"
              data-test-id="listPostITemDiv"
              style={{ position: "relative", display: "inline-block",cursor:"pointer" }}
              onClick={()=>this.navigatePostDetailTOPostDetail(isConfidential, post.id)}
            >
              <div className="collaborationGridItemOutside">
              {!isConfidential && (
                <div className="collaborationGridItemInside" key={post.id}>
                  {type && type.includes("image") ? (
                    <img
                    data-test-id="postImg"
                    src={post.attributes.images[0].url}
                    className="collabImg"
                    
                    />
                  ) : (
                    <video
                    className="collabImg"
                    controls
                    src={post.attributes.images[0].url}
                    playsInline 
                    muted
                    autoPlay
                  >
                  </video>
                  )}
                    <div className="collabImgHover">{post.attributes.project_title}</div>
                  {this.renderLikeSaveShare(
                    index,
                    this.isFavouritePost(post.attributes.id),
                    this.isLikedPost(post.attributes.id),
                    "1",
                    post.attributes.id,
                    post.attributes.profile.data.attributes.id
                  )}
                </div>
              )}
              {isConfidential && (
                <div className="collaborationGridItemInside" key={post.id}>
                  <img
                    data-test-id="ImgPostConfidential"
                    style={{ opacity: "0.2" }}
                    src={typeof post?.attributes?.images?.[0]?.url !== 'undefined' ? post.attributes.images[0].url : orangeBanner}
                    className="collabImg"
                  />
                  <div className="restrictedDivMain">
                    <div className="restrictedDiv">
                      <img
                        className="restrictedImg"
                        data-test-id="restrictedImgDiv"
                        src={restricted}
                      />
                      <p className="confidentialText">Confidential</p>
                    </div>
                    <p className="confidentialPara">
                      You have to be a collaborator to view this
                      content.
                    </p>
                  </div>
                </div>
              )}
              </div>
            </Grid>
          })}
        </Carousel>
      </div>
    </StyledCarousel>)
  }

  creatorProfileLike = (isCollaborationPost: boolean, post: any, isLiked: boolean, isSavedProfile: boolean) => (
    <>{isCollaborationPost && (
      <div
        style={webStyle.shareButton}
        className="shareLikBtnMobile"
      >
        <Tooltip
          title={
            isLiked
              ? "You recommend working with this person."
              : "Do you recommend working with this person?"
          }
          data-testid="tooltip"
        >
          {isLiked ? (
            <img
              src={dislikeImage}
              onClick={() =>
                this.profileLike(post.profile.data.attributes.id)
              }
              style={{ cursor: "pointer" }}
              height={24}
              width={24}
              data-testid="thumb-up-icon"
            />
          ) : (
            <img
              src={likeImage}
              onClick={() =>
                this.profileLike(post.profile.data.attributes.id)
              }
              style={{ cursor: "pointer" }}
              height={24}
              width={24}
              data-testid="thumb-up-outlined-icon"
            />
          )}
        </Tooltip>
        <div>
          <ShareIcon
            data-test-id="shareEventBtn"
            onClick={() => {
              this.openPopUpShareModal(
                post.profile.data.attributes.id, "Profiles"
              );
            }}
            height={24}
            width={24}
            style={webStyle.imgPaddingLeft}
          />
          {!isSavedProfile ? (
            <img
            height={24}
            width={24}
              src={saveImage}
              onClick={() =>
                this.handleAddFavouriteProfile(
                  post.profile.data.attributes.id, "BxBlockProfile::Profile"
                )
              }
              data-test-id="saveProfileImg"
            ></img>
          ) : (
            <TurnedInIcon
              onClick={() =>
                this.handleDeleteFavProfile(
                  post.profile.data.attributes.id, "profile"
                )
              }
              data-test-id="unSaveProfileImg"
            />
          )}
        </div>
      </div>
    )}
    </>
  )

  renderHeadline = () =>{
    const post = this.state.postData.attributes ?? {};
    return(
      post.profile.data.attributes.headline &&
      `${post.profile.data.attributes.headline} ` +
      (post.profile.data.attributes.hourly_rate_from && post.profile.data.attributes.hourly_rate_to
        ? `| £${post.profile.data.attributes.hourly_rate_from} - £${post.profile.data.attributes.hourly_rate_to}`
        : ''
      )
    )
  }


  renderProjectTags = () => {
    const post = this.state.postData.attributes ?? {}
    if(post.tag_list.length > 0) {
      return <div className="projectTagsMobile">
        <Typography className="tagHeader">Project Tags</Typography>
          <Grid className="tagsContainer">
            {post.tag_list.map((tag, index) => (
              <Grid spacing={1}
                data-test-id="tag-grid-item"
                className="tagCard"
              >
                <Typography className="tagContent">{tag}</Typography>
              </Grid>
            ))}
          </Grid>
          <span className="borderBottom mobileHide" />
      </div>
    }
  }

  postDetailMedias = (isCollaborationPost: boolean, post: any,  showOptions: boolean, isOwner: boolean) => (<>
    {post.images.slice(1)?.map((image: any, index: number) => {
      const urlParams = new URLSearchParams(image.url.split('?')[1]);
      const type = urlParams.get('type');
      const isLastItem = index === post.images.length - 2;
      return (
        <>
        <div data-test-id="post-img-div" className={`postImgDivs ${isLastItem ? "lastPostImgDiv": ""}`} style={!image.is_visible && !isOwner ? { display: 'none' } : { display: 'block' }}>
        {!image.is_visible && <span className="hiddenImage" />}
          {type && type.includes("image") ? (
            <img
              className="postImage"
              src={image.url}
              alt="postImage"
              data-test-id="post-image"
            />
          ) :
            (
              <video
                className="postImage"
                controls
                data-test-id="post-video"
                src={image.url}
              />
            )}
         {(!isCollaborationPost && showOptions && isOwner) && <>
          {this.renderVisibilityIcons(image.is_visible, () => this.setVisibleImage(image.id, false), () => this.setVisibleImage(image.id, true))}
          </>}
        </div>
        </>
      )
    })}
  </>)

  postCollabrator = (isCollaborationPost:boolean, post:any, isOwner: boolean) => (<>
    {post.collaborators.map((collaborator:any, index:number) => {
      if (collaborator?.error) return null
      else return (
        <>
          {collaborator.profile &&
            <Grid
              data-test-id="collab-grid-item"
              className="collabCard"
              item
              lg={4}
              md={6}
              sm={6}
              xs={12}
            >
                <div className="collabCardMain">
                <ProfileAvatar src={collaborator.profile.photo} className="collabProfileImg" >
                  {returnTruthyString(collaborator.profile.first_name[0]) + returnTruthyString(collaborator.profile.last_name[0])}
                </ProfileAvatar>
                <div className="collabTlDescMob">
                  <Typography className="collabProfileName">
                    {collaborator.profile.first_name.charAt(0).toUpperCase()+collaborator.profile.first_name.slice(1).toLowerCase()+ " " + returnTruthyString(getSurname(collaborator.profile.last_name, collaborator.id.toString()))}
                  </Typography>
                  <span className="borderBottom mobileHide" />
                  <Typography className="collabProfileDesc">
                    {collaborator.profile.headline}
                  </Typography>
                </div>
                {!isCollaborationPost && <>
                {isOwner &&
                  <img
                    data-test-id="deleteIcon"
                    className="deleteCollabIcon"
                    onClick={() => { this.setState({ deleteCollabPopUp: true, deleteID: collaborator.id }) }

                    }
                    src={removeIcon}
                    style={webStyle.deleteCollabIcon}
                  />
                }
                </>}
              </div>
            </Grid>}
        </>
      )


    }

    )}
  </>)


  renderVisibilityIcons = (isVisible: boolean, onClickVisibilityOffHandler: () => void, onClickVisibilityOnHandler: () => void) => {
    const visibleImages = this.state.postData?.attributes.images.filter(image => image.is_visible === true);
    const hasVisibleImages = visibleImages.length > 1;
    if(isVisible){
      if(hasVisibleImages){
      return (<CustomTooltip title="Visible in gallery" placement="bottom-end">
        <StyledEyeIcon data-test-id="eyeOpen"
        onClick={() => onClickVisibilityOffHandler()}
        >
          <img
          src={visibilityOn}
          alt="eyeOpen"
        />
         
        </StyledEyeIcon>
        </CustomTooltip>
      )
      }
    }else{
      return <CustomTooltip title="Not visible in gallery" placement="bottom-end"><StyledEyeIcon data-test-id="eyeClose"
      onClick={() => onClickVisibilityOnHandler()}
      ><img
        src={visibilityOff}
        alt="eyeClose"
      />
      </StyledEyeIcon>
      </CustomTooltip>
    }
  }

  postImgDiv = (type:any,post:any,isOwner:boolean, showOptions: boolean) => (
    <div className="postImgDiv" style={!isOwner && !post.images[0]?.is_visible ? { display: 'none' } : { display: 'block' }}>
    {type && type.includes("image") ? (
      <>
      {!post.images[0]?.is_visible && <span className="hiddenImage" />}
      <img
        className="postImage"
        src={post.images[0]?.url}
        alt="postImage"
        data-test-id="post-image"
      />
      </>
    ) :
      (
        <>
        {!post.images[0]?.is_visible && <span className="hiddenImage" />}
        <video
          className="postImage"
          controls
          src={post.images[0]?.url}
          playsInline 
          muted
          autoPlay
        >
          Your browser does not support the video tag.
        </video>
        </>
      )}
      {isOwner && showOptions &&
    <>
      {this.renderVisibilityIcons(post.images[0]?.is_visible, () => this.setVisibleImage(post.images[0]?.id, false), () => this.setVisibleImage(post.images[0]?.id, true))}
    </>}
  </div>
  )


  // Customizable Area End
  render() {
    // Customizable Area Start
    const post = this.state.postData.attributes ?? {};
    if(Object.keys(post).length === 0){
      return <></>
    }
    const showOptions = post?.images?.length>1
    const profileImage = returnTruthyString(post?.profile?.data.attributes.photo)
    const avatarName = returnTruthyString(post?.profile?.data.attributes.first_name[0]) + returnTruthyString(post?.profile?.data.attributes.last_name[0])
    const urlParams = new URLSearchParams(post?.images?.[0]?.url?.split('?')?.[1]);
    const type = urlParams.get('type');
    const isOwner = post?.profile?.data.attributes.id.toString() === sessionStorage.getItem("profileId")
    const userProfileId = sessionStorage.getItem("profileId");
    const postProfileId = post?.profile?.data.attributes.id;
    const isCollaborationPost = userProfileId !== postProfileId?.toString();
    const confirmDeletePost = () => {
      return (
        <Dialog
          style={{ padding: "20px" }}
          open={this.state.deleteModal}
          onClose={this.handleDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={webStyle.headerContent}>
            <DialogContentText id="alert-dialog-description" style={{ ...webStyle.textStyles, textAlign: "center" }}>
              Are you sure you want to delete this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "10px 20px" }}>
            <Button variant="outlined" id="close-delete-collab-popup" data-id="cancelButton" style={{ ...webStyle.buttonstyles, textTransform: "none" }} onClick={this.handleDelete}>Cancel</Button>
            <Button style={{ ...webStyle.containedButton, textTransform: "none" }} variant="contained" id="yes-delete-btn" onClick={this.handleDeletePost} >
              Yes, delete
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
    const isLiked = this.state.allLikes.some(
      (likes: any) =>
        likes.attributes.likeable_id === post.profile.data.attributes.id
    );


    const isSavedProfile = this.state.allSavedProfile.some(
      (likes: any) =>
        likes.attributes.favouriteable_id === post.profile.data.attributes.id
    );
    const isSavedPost = this.state.allSavedPost.some(
      (likes: any) =>
        likes.attributes.favouriteable_id === post.id
    );

    return (
      <ThemeProvider theme={theme}>
        <DeleteCollab state={this.state} handleDelete={this.handleDeleteCollab} deleteCollabApi={this.deleteCollabApi} />
        <AddCollabarator state={this.state} closeDialougCollab={this.closeDialougCollab} handleAddCollab={this.handleAddCollab} />
        {this.state.editPostPopUp && (
          <PostCreationPopUp
            handleKeyDownDes={this.handleKeyDownDes}
            handleKeyDown={this.handleKeyDown}
            handleChangeToggle={this.handleChangeToggle}
            updatePost={this.updatePost}
            removeImage={this.removeImage}
            handleImageChange={this.handleImageChange}
            state={this.state}
            handlePreviewOpen={this.handlePreviewOpen}
            handleClosePopUp={this.handleDisplayClose}
            savePopUpData={this.savePopUpData}
            isEditMode={true}
          />
        )}
        {this.state.displayPreview && (
          <Preview
            state={this.state}
            updatePost={this.updatePost}
            handlePreviewClose={this.handlePreviewClose}
            isEdited={true}
            profileImage={post.profile.data.attributes.photo}
            handleSetVisiblePreview={this.handleSetVisiblePreview}     
          />
        )}

        {this.state.deleteModal && (
          <div data-id="deleteModalOverlay" style={webStyle.deleteModalOverlay}>
            {confirmDeletePost()}
          </div>
        )}
        <StyledPostDetails 
          open={this.props.isOpen || false} 
          onClose={() => this.props.onClosePostDetails?.()}
          data-test-id="postDetailsPopup"
          BackdropProps={{className: "postDetailsBackdrop"}}
          PaperProps={{className: "postDetailsPaper"}}
          >
          <DialogContent className="postDetailsContent" ref={this.postDetailsRef}>
          <StyledPostContainer data-test-id="postContainer"   className="boxContainerMob">
            <div className="profileDivMob">
              <div className="profileimgContainer">
                <ProfileAvatar src={profileImage} style={webStyle.profileImgStyle}>
                  {avatarName}
                </ProfileAvatar>
              </div>
              <div className="userNameDiv">
                  <Typography className="describtionInterior">{post.project_title}</Typography>
                  <Typography className="userNameInterior">
                    {post.profile.data.attributes.first_name + " " + getSurname(post.profile.data.attributes.last_name, post.account_id.toString())}
                  </Typography>
              </div>
              <div className="optionsMain">
              <ClickAwayListener data-test-id="clickAwayOption" onClickAway={this.handleOptionClose}>
                <div>
                {!isCollaborationPost && isOwner 
                && <img 
                src={optionIcon} 
                onClick={this.handleOptionOpen} 
                data-test-id="optionIcon" 
                className="optionIcon"/>}
                {this.state.optionModal && (
                  <div
                    data-test-id="optionBoxModal"
                    onClick={this.handleOptionClose}
                    style={webStyle.optionHyperLinkDiv}
                  >
                    {dropdownLinks("Edit", () => this.handleEditOpen(this.state.postData))}
                    {dropdownLinks("Delete", () => this.handleDeletePostPopUp(this.state.postData.id))}
                  </div>
                )}
                </div>
              </ClickAwayListener>
                <img src={blackClose} className="closeIcon"  onClick={() => this.props.onClosePostDetails?.()}
                  data-test-id="ProfileInfoPhoto"/>
              </div>
            </div>
             {this.postImgDiv(type,post,isOwner, showOptions)}
            <div className="postShareButton shareBtnDivMob">
              <div style={{ display: "flex" }}>
                {this.state.isPostLiked ?
                  <FavoriteIcon height={24} onClick={() => this.likePost("unlike")} width={24} data-test-id="likeIcon2">
                  </FavoriteIcon>
                  :
                  <FavoriteBorderIcon height={24} onClick={() => this.likePost("like")} width={24} data-test-id="unLikeIcon2">
                  </FavoriteBorderIcon>
                }
                  <Typography style={{ paddingLeft: "12px", fontFamily: "Silika-Medium" }}>
                    {post.like_counts} Likes
                  </Typography>
              </div>
              <div>
                <ShareIcon data-test-id="sharePostBtn" onClick={() => { this.openPopUpShareModal(this.id, "Profiles", post?.profile?.data.attributes.id) }} height={24} width={24} style={webStyle.imgPaddingLeft}/>
                {!isSavedPost ? <img src={saveImage} height={24} width={24}
                  onClick={() => this.handleAddFavouriteProfile(post.id, "BxBlockPosts::Post")} data-test-id="savePostImg"></img> :
                  <TurnedInIcon onClick={() => this.handleDeleteFavProfile(post.id, "post")} data-test-id="unSavePostImg" />}
              </div>
            </div>
            <div className="descBoxMob">
              <Typography>
                {post.description}
              </Typography>
            </div>
            {this.postDetailMedias(isCollaborationPost, post, showOptions, isOwner)}
            <span className="borderBottom mobileHide" />
            {this.renderProjectTags()}         
                <div className="collabContainer">
                  <Box className="addCollabHeader">
                    <Typography>Collaborators</Typography>
                    {!isCollaborationPost && isOwner &&<img src={addIcon}
                      onClick={() => this.openDialougCollab()}
                      data-test-id="addCollabPopup"
                    />}
                  </Box>
                  <Grid className="collabCards" container spacing={4}>
                   {this.postCollabrator(isCollaborationPost, post, isOwner)}
                  </Grid>
                </div>
                <span className="borderBottom mobileHide" />
            <StyledProfile>
                <div className="profileinfoDiv">
                    <div className="userProfileImgDiv">
                    <ProfileAvatar src={profileImage} className="userProfileImg">
                      {avatarName}
                    </ProfileAvatar>
                    </div>
                  <div className="user-detail-div">
                    <div className="userNameMob">
                    <Typography className="userName">{post.profile.data.attributes.first_name + " " + getSurname(post.profile.data.attributes.last_name, post.account_id.toString())}</Typography>
                        <Typography className="description">
                        {
                          this.renderHeadline()
                        }
                          </Typography>
                    </div>
                    <div className="table-description-wrapper">
                      <div style={webStyle.levelOuterDiv}>
                        <div style={webStyle.levelDiv}>
                          <Typography style={webStyle.level}>Level:</Typography>
                          <Typography style={webStyle.levelPosition}>
                            {post.profile.data.attributes.seniority_level}
                          </Typography>
                        </div>
                        <div style={webStyle.locationDiv}>
                          <Typography style={webStyle.location}>
                            Location:
                          </Typography>
                          <LocationOnIcon style={webStyle.locationIcon} />
                          <Typography style={webStyle.locationName}>
                            {post.profile.data.attributes.city +
                              ", " +
                              post.profile.data.attributes.country}
                          </Typography>
                        </div>
                      </div>
                      <span className="borderBottom" style={{marginTop: "13px"}} />
                      <div style={webStyle.typeOuterDiv}>
                        <div style={webStyle.typeDiv}>
                          <Typography style={webStyle.type}>Type:</Typography>
                          <Typography style={webStyle.workingType}>
                            {post.profile.data.attributes.work}
                          </Typography>
                        </div>
                        <div style={webStyle.professionDiv}>
                          <Typography style={webStyle.profession}>
                            Profession:
                          </Typography>
                          <Typography style={webStyle.professionType}>
                            {post.profile.data.attributes.profession}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className="table-mobile-wrapper">
                      <div
                        style={webStyle.levelOuterDiv}
                        className="locationOuterDiv"
                      >
                        <Typography className="description">
                        {
                          this.renderHeadline()
                        }
                        </Typography>
                        <div style={webStyle.levelDiv} className="levelDivMobile">
                          <Typography style={webStyle.level}>Level:</Typography>
                          <Typography style={webStyle.levelPosition}>
                            {post.profile.data.attributes.seniority_level}
                          </Typography>
                        </div>
                        <div className="typeMobileDiv levelDivMobile">
                          <Typography style={webStyle.type}>Type:</Typography>
                          <Typography style={webStyle.workingType}>
                            {post.profile.data.attributes.work}
                          </Typography>
                        </div>
                        <div className="typeMobileDiv levelDivMobile">
                          <Typography style={webStyle.profession}>
                            Profession:
                          </Typography>
                          <Typography style={webStyle.professionType}>
                            {post.profile.data.attributes.profession}
                          </Typography>
                        </div>
                        <div style={webStyle.locationDiv} className="levelDivMobile">
                          <Typography style={webStyle.location}>
                            Location:
                          </Typography>
                          <LocationOnIcon style={webStyle.locationIcon} />
                          <Typography style={webStyle.locationName}>
                            {post.profile.data.attributes.city +
                              ", " +
                              post.profile.data.attributes.country}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <span className="borderBottom" style={{marginTop: "13px"}} />
                    {this.creatorProfileLike(isCollaborationPost, post, isLiked, isSavedProfile)}
                  </div>
                  <ShareMenuDialog
                    isOpen={this.state.isSendLinkToChatModalOpen}
                    chatMessage={this.state.sendChatMessage}
                    onClickBackButton={this.handleNavigateBack}
                    onShareDataChange={this.handleshareDataChatChange}
                    shareData={this.state.shareData}
                    onClickSendChat={this.handleSend}
                    onChangeChatMessage={this.handlesendChatMessage}
                    profiles={this.state.getAllData?.map((option:{attributes:{account_id:number, first_name: string, last_name: string, photo: string}}) => ({
                      account_id:option?.attributes?.account_id,
                      firstName: option?.attributes?.first_name,
                      lastName: getSurname(option?.attributes?.last_name, option?.attributes?.account_id?.toString()),
                      photo: option?.attributes?.photo,
                    }))}
                    onClose={this.handleCloseChatModel}
                  />
                  <ShareLinkDialog 
                  onClose={this.handleClose}
                  isOpen={this.state.isOpenModelShare}
                  onClickChatButton={this.handleChat}
                  shareLink={`${this.state.linkShare}`}
                  isChatSend={this.state.msgSend}
                  />
                  
                </div>
              {this.carouselCreatorPost()}
            </StyledProfile>
          </StyledPostContainer>
        </DialogContent>
        </StyledPostDetails>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledPostContainer = styled(Container)(({theme}: {theme: Theme}) => ({
  padding: "55px 45px 0 45px",
  borderRadius: "4px",
  maxWidth: "1232px",
  height: "calc(100vh - 57px)",
  [theme.breakpoints.down("xs")]:{
    height: "fit-content !important",
    padding: "0px",
  },
  "& .profileDivMob":{
    display: "flex",
    position: "relative" as "relative",
    gap: "40px",
    [theme.breakpoints.down("xs")]:{
      padding: "24px 24.5px 0px 24.5px",
    },
  },
  "& .profileimgContainer": {
    maxWidth: "100px",
  },
  "& .userNameDiv": {
    justifyContent: "right",
    display: "flex",
    flexFlow: "column",
    margin: "auto 0"
  },
  "& .describtionInterior":{
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#121212",
    marginBottom: "12px"
  },
  "& .userNameInterior":{
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "normal",
    fontStyle: "normal",
    color: "#64748B"
  },
  "& .postImgDiv": {
    maxWidth: "1141px",
    height: "584px",
    marginTop: "42px",
    objectFit:"cover",
    position: "relative",
    [theme.breakpoints.down("sm")]:{
      height: "189px"
    },
  },
  "& .hiddenImage":{
    position: "absolute",
    top: 0,
    left: 0, 
    width: "100%",
    height: "100%",
    backgroundColor: "#0d0c22",
    opacity: "0.6",
    borderRadius: "4px"
  },
  "& .postImage":{
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]:{
      borderRadius: "0px"
    },
  },
  "& .postImgDivs":{
    maxWidth: "1141px",
    position: "relative",
    height: "584px",
    objectFit:"cover",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]:{
      height: "189px"
    },
  },
  "& .lastPostImgDiv":{
    marginBottom: "82px",
    [theme.breakpoints.down("sm")]:{
      marginBottom: "24px",
    },
  },
  "& .postShareButton": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "12px",
    padding: "22px 0"
  },
  "& .descBoxMob": {
    padding: "40px 7px",
    wordWrap: "break-word",
    "& p":{
      fontSize: "14px",
      fontFamily: "Silika-Light",
      lineHeight: "16.88px",
      color: "#000000",
      whiteSpace: 'pre-wrap' as 'pre-wrap'
    }
  },
  "& .borderBottom":{
    borderBottom: "1px solid #D9D9D9",
    height: "1px",
    width: "100%",
    display: "block"
  },
  "& .mobileHide":{
    [theme.breakpoints.down("sm")]:{
    display: "none"
    }
  },
  "& .projectTagsMobile":{
    [theme.breakpoints.down("sm")]:{
      border: "1px solid #dfdfdf",
      borderRadius: "4px",
      margin: "0 24.5px",
      padding: "24px"
    }
  },
  "& .tagHeader":{
    fontFamily: "Silika-Medium",
    fontSize: "20px",
    lineHeight: "14.13px",
    color: "#000000",
    padding: "18px 0 19px 0",
    [theme.breakpoints.down("sm")]:{
      padding: "0px",
      marginBottom: "24px"
    }
  },
  "& .tagsContainer":{
    display: "flex",
    flexFlow: "row",
    marginBottom: "42px",
    [theme.breakpoints.down("sm")]:{
      margin: "0px"
    }
  },
  "& .tagCard":{
    marginRight: "8px",
    width: "134px",
    height: "33px",
    borderRadius: "2px",
    border: "1px solid #DFDFDF",
    "&:last-child":{
      marginRight: "0px",
    }
  },
  "& .tagContent":{
    height: "33px",
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    lineHeight: "14.18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .optionsMain":{
    position: "absolute",
    right: 0,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]:{
      right: "24.5px"
    },
  },
  "& .optionIcon":{
    height: "20px",
    width: "20px",
    flexShrink: 0,
    cursor: "pointer",
    marginRight: "8px"
  },
  "& .closeIcon":{
    height: "24px",
    width: "24px",
    "& path":{
      fill: "#010101"
    }
  },
  "& .collabContainer":{
    width: "100%",
    [theme.breakpoints.down("xs")]:{
      border: "1px solid #dfdfdf",
      borderRadius: "4px",
      margin: "24px 24.5px",
      width: "calc(100% - 97px)",
      padding: "24px"
    }
  },
  "& .addCollabHeader":{
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "27px 0",
    [theme.breakpoints.down("xs")]:{
      padding: "0px",
      marginBottom: "14px"
    },
    "& p":{
     fontFamily: "Silika-Medium" ,
     fontSize: "20px",
     lineHeight: "24.13px"
    },
    "& svg":{
      height: "30px",
      width: "30px"
    }
  },
  "& .collabCards":{
    display:"inline-flex",
    flexWrap: "wrap",
    marginBottom: "41.5px",
    [theme.breakpoints.down("xs")]:{
      width: "100%",
      margin: "0px"
    }
  },
  "& .collabCard":{
    aspectRatio: "363/280",
    [theme.breakpoints.down("xs")]:{
      aspectRatio: "auto",
      padding: "16px 0",
      borderBottom: "1px solid #dfdfdf",
      "&:last-child":{
        borderBottom: "none"
      }
    }
  },
  "& .collabCardMain":{
    borderRadius: "2px",
    border: "1px solid #DFDFDF",
    padding: '25.5px 27px',
    display: "flex",
    flexFlow: "column",
    position: "relative",
    height: "calc(100% - 51px)",
    width: "calc(100% - 54px)",
    [theme.breakpoints.down("xs")]:{
      height: "fit-content",
      margin: "0px",
      width: "100%",
      display: "flex",
      flexFlow: "row",
      border:"none",
      padding: "0px",
      borderBottom: "1px solid #dfdfdf",
      paddingBottom: "12px",
      alignItems: "center",
      "&:last-child":{
        borderBottom: "0px",
        paddingBottom: "0px",
      }
    },
  },
  "& .deleteCollabIcon":{
    position: "absolute",
    top: 0,
    right: 0,
    height: "16px",
    width: "16px",
    padding: "16px",
    [theme.breakpoints.down("xs")]:{
      margin: "auto 0 auto auto",
      position: "relative",
      padding: "16px 6px 16px 0"
    }
  },
  "& .collabProfileImg":{
    height: "100px",
    width: "100px",
    fontSize: "30px",
    margin: "6px auto 31px auto",
    "@media (max-width: 820px)":{
      margin: "6px auto 12px auto",
    },
    "@media (max-width: 770px)":{
      height: "80px",
      width: "80px",
    },
    "@media (max-width: 730px)":{
      height: "70px",
      width: "70px",
    },
    [theme.breakpoints.down("xs")]:{
      height: "48px",
      width: "48px",
      margin: "auto 0",
      fontSize: "18px"
    },
   
  },
  "& .collabTlDescMob":{
    "@media (max-width: 730px)":{
     padding:"0px !important" ,
     width: "fit-content",
     margin:"0 0 0 12px"
    }
  },
  "& .collabProfileName":{
    color: '#0D0C22', 
    fontSize: '20px', 
    fontWeight: 500, 
    lineHeight: '40px', 
    fontFamily: "Silika-Medium" ,
    "@media (max-width: 730px)":{
      fontSize: '14px', 
      lineHeight: "16.89px"
    }
  },
  "& .collabProfileDesc":{
    fontFamily: "Silika-Light", 
    color: '#0D0C22', 
    fontSize: '14px', 
    fontWeight: 300, 
    lineHeight: '19.69px', 
    marginTop: "13px",
    textOverflow: "ellipsis",
    "@media (max-width: 730px)":{
      marginTop: "6px",
      fontSize: "11px",
      lineHeight: "15.47px"
    }
  }
}))

const webStyle = {
  deleteModalOverlay: {
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  headingText: {
    width: "100%", textAlign: "center",
    fontSize: '24px',
    fontWeight: 500, color: "#010101"
  },
  modelHeadingstyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  describtion: {
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    marginTop: "18px",
  },
  levelOuterDiv: {
    display: "flex",
    marginTop: "14px",
    marginLeft: "50px",
  },
  levelDiv: {
    display: "flex",
    width: "50%",
  },
  level: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  levelPosition: {
    fontFamily: "Silika-Medium",
    fontSize: '12px',
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  locationDiv: {
    display: "flex",
  },
  location: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  locationIcon: {
    height: "20px",
    width: "20px",
    flexShrink: 0,
    marginLeft: "7px",
    marginRight: "4px",
  },
  locationName: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center"
  },
  typeOuterDiv: {
    display: "flex",
    marginTop: "14px",
    marginLeft: "50px",
  },
  typeDiv: {
    display: "flex",
    width: "50%",
  },
  type: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  workingType: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  professionDiv: {
    display: "flex",
    width: "50%",
  },
  profession: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  professionType: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  descBox: {
    padding: "30px 100px"
  },
  description: {
    fontSize: "14px",
    fontFamily: "Silika-Light",
    lineHeight: "16.88px",
    color: "#000000",
    paddingTop: "10px",
    whiteSpace: 'pre-wrap' as 'pre-wrap'
  },
  shareButton: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px"
  },
  imgPaddingLeft: {
    paddingRight: "10px",
    cursor: "pointer"
  },
  optionHyperLinkDiv: {
    position: "absolute" as "absolute",
    top: "24px",
    right: "30px",
    zIndex: 99999,
    background: "#FFF",
    borderRadius: "1px",
    padding: "4px",
    border: "1px solid #9B9B9D",
    gap: "2px",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start" as "flex-start",
  },
  discoverHyperLink: {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#0D0C22",
    padding: "9px 20px 9px 16px",
    gap: "8px",
    cursor: 'pointer',
    textAlign: "start" as "start"
  },
  discoverOptionBorder: {
    borderBottom: "0.6px solid #9B9B9D",
    width: "148px",
    opacity: "16%",
    margin: "auto"
  },
  discoverDropDownWidth: {
    width: "148px"
  },
  buttonstyles: {
    border: "1px solid  #010101",
    borderRadius: "2px",
    width: "50%"
  },
  textStyles: {
    fontFamily: "Silika-light",
    fontSize: "16px",
    fontWeight: 300,
    width: "60%",
    alignItems: "center",
    color: "#0D0C22"
  },
  containedButton: {
    border: "1px solid  #010101",
    borderRadius: "2px",
    backgroundColor: "#0D0C22",
    color: "white",
    width: "50%"
  },
  headerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profileImgStyle: {
    width: "68px",
    height: "68px",
    fontSize: "30px",
  },
  deleteCollabIcon: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
    color: "#9B9B9D"
  },
  modelHeadingstyleChatModel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
};


export const StyledEyeIcon= styled("div")(({theme}: {theme: Theme}) => ({
  height: "48px",
  width: "48px",
  background: "#fff",
  right: "24px",
  top: "24px",
  position: 'absolute' as 'absolute',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "2px",
  [theme.breakpoints.down("sm")]:{
    height: "29px",
    width: "29px",
    right: "12px",
    top: "12px",
  },
  "& img":{
  height: "24px",
  width: "26px",
  [theme.breakpoints.down("sm")]:{
    right: "0",
    height: "16px",
    width: "16px",
  }
 }
}))
// Customizable Area End
