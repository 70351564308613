import React from "react";
import { Paper, OutlinedInput, TextField, styled, Theme, InputAdornment, Typography, Select, MenuItem } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { poundIcon } from "../../../customisableuserprofiles/src/assets"
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Country } from 'country-state-city';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    onChangeSearchText: (event: string) => void;
    seniorityLevels: string[];
    selectedWorkTypes: string[];
    skills: string[];
    selectedSkills: string[];
    professions: string[];
    selectedSeniorityLevels: string[];
    workTypes: string[];
    selectedCountries: string[];
    selectedProfessions: string[];
    onMinRateChange: (value: number | undefined) => void;
    onMaxRateChange: (value: number | undefined) => void;
    onChangeFilter: (values: string[], fieldName: string) => void;
    onClickClearAll: (refetch: boolean) => void;
    onClickClearFilter: (values: string[], type: string) => void;
    minRate: number | undefined;
    maxRate: number | undefined;
    onClearRateRange: () => void
}



const StyledSelect = styled(Select)({
    width: "100%",
    "& .MuiSelect-root": {
        display: "flex",
        alignItems: "center"
    },
    "& .MuiInputBase-input": {
        fontFamily: "Silika-Medium",
        width: "100% !important",
        height: "48px !important",
        fontSize: "16px",
    },
});

const MenuItemFont = styled(MenuItem)({
    fontSize: "14px",
    color: "#0D0C22",
    padding: "7px 16px !important",
    fontFamily: "Silika-Medium",
    "&:hover": {
        background: "#dfdfdf"
    },
    "&.Mui-selected": {
        background: "#010101",
        color: "#fff",
        "&:hover": {
            background: "#dfdfdf",
            color: "#010101"
        },
    }
});

const StyledTextField = styled(TextField)(({ theme }: { theme: Theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: "100% !important",
        margin: "0",
    },
    "& .textfield": {
        height: "48px",
        borderRadius: "2px",
        border: "1px solid #DFDFDF",
        [theme.breakpoints.down('md')]: {
            fontSize: "16px",
            "& input": {
                width: "calc(100% - 16px) !important"
            },
        },
    },
    "& input::placeholder": {
        fontFamily: "Silika-Medium"
    }
}));



const StyledPaper = styled("div")(({ theme }: { theme: Theme }) => ({
    boxShadow: "0px 8px 32px 0px #0000000F",
    border: "1px solid #dfdfdf",
    maxHeight: 35 * 8,
    "& .MuiList-root": {
        padding: "5px 4px",
        [theme.breakpoints.down("sm")]: {
            padding: "2px",
        },
        "& li": {
            marginBottom: "2px",
            borderRadius: "2px"
        }
    }
}));

const StyledAdvanceSearch = styled("div")(({ theme }: { theme: Theme }) => ({
    width: "100%",
    "& *": {
        fontFamily: "Silika-Medium",
    },
    "& .filtersTitle": {
        margin: "16px 0"
    },
    "& .filterContainer": {
        flexFlow: "row",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "end",
        marginBottom: "51px",
        "& .startEndornment": {
            marginRight: "11px",
            width: "24px",
            height: "24px"
        },
        "& input::placeholder": {
            color: "#010101 !important",
            opacity: 1
        },
        "@media (max-width: 1248px)": {
            justifyContent: "flex-start",
            "& > div": {
                margin: "0 8px 8px 0"
            }
        },
        "& .MuiInputBase-root": {
            borderRadius: "2px",
            height: "46px"
        },
        "& .seniorityLevel": {
            cursor: "pointer",
            width: "118px",
            border: "1px solid #DFDFDF",
            borderRadius: "2px",
            height: "48px",
            [theme.breakpoints.down("sm")]: {
                width: "140px"
            },

        },
        "& .workType": {
            cursor: "pointer",
            width: "168px",
            border: "1px solid #DFDFDF",
            borderRadius: "2px",
            height: "48px",
            [theme.breakpoints.down("sm")]: {
                width: "140px"
            }
        },
        "& .country": {
            width: "219px",
            cursor: "pointer",
            border: "1px solid #DFDFDF",
            borderRadius: "2px",
            height: "48px",
            [theme.breakpoints.down("sm")]: {
                width: "140px"
            }
        },
        "& .professionType": {
            width: "236px",
            border: "1px solid #DFDFDF",
            borderRadius: "2px",
            height: "46px",
            [theme.breakpoints.down("sm")]: {
                width: "140px",
                borderRadius: "2px",
            }
        },
        "& .skills": {
            width: "214px",
            border: "1px solid #DFDFDF",
            borderRadius: "2px",
            height: "46px",
            [theme.breakpoints.down("sm")]: {
                width: "140px"
            }
        },
        "& .dash": {
            margin: "auto 5.15px"
        },
        "& .rate": {
            width: "121px",
            border: "1px solid #DFDFDF",
            borderRadius: "2px",
            height: "48px",
            [theme.breakpoints.down("sm")]: {
                width: "140px"
            }
        },
    },
    "& .borderLine": {
        height: "1px",
        width: "100%",
        borderBottom: "1px solid #D9D9D9",
        display: "block",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    "& .selectedFilters": {
        display: "flex",
        alignItems: "center",
        flexFlow: "row",
        justifyContent: "space-between",
        marginTop: "11px",
        marginBottom: "63px",

    },
    "& .filters": {
        display: "inline-flex",
        "& .filter": {
            border: "1px solid #dfdfdf",
            borderRadius: "2px",
            alignItems: "center",
            padding: "4px 6px",
            marginRight: "10px",
            display: "flex",
            cursor: "pointer",
            "& svg": {
                width: "16px",
                marginLeft: "4px",
                height: "16px",
            }
        }
    },
    "& .clearFilters": {
        textDecoration: "underline",
        cursor: "pointer",
        height: "20px",
    },
    [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 42px)"
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

export const StyledAutoCompletePaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
    border: "1px solid #dfdfdf",
    boxShadow: "0px 8px 32px 0px #0000000F",
    overflow: "hidden",
    "& .MuiAutocomplete-option[data-focus='true']": {
        backgroundColor: "#dfdfdf !important",
    },
    "& .MuiAutocomplete-option[aria-selected='true']": {
        backgroundColor: "#010101 !important",
        color: "#fff"
    },
    "& .MuiAutocomplete-listbox": {
        maxHeight: 36 * 8,
        padding: "5px 4px",
        overflow: "auto",
        [theme.breakpoints.down("sm")]: {
            padding: "2px",
        },
        "& li": {
            borderRadius: "2px",
            marginBottom: "2px",
        }
    }
}));



export class AdvanceSearch extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    renderFilters = () => {
        const { minRate,
            maxRate,
            selectedCountries,
            selectedWorkTypes,
            selectedSkills,
            selectedSeniorityLevels,
            selectedProfessions } = this.props;

        let isFilterEnabled = false;

        if (selectedSkills.length > 0 ||
            selectedProfessions.length > 0 || 
            selectedWorkTypes.length > 0 || 
            selectedSeniorityLevels.length > 0 || 
            selectedCountries.length > 0) {
            isFilterEnabled = true;
        }


        return isFilterEnabled ? (
            <>
                <span className="borderLine" />
                <div className="selectedFilters">
                    <div className="filters">
                        {selectedSeniorityLevels.map(seniorityLevel => <div onClick={() => this.handleClearFilter("selectedSeniorityLevels", selectedSeniorityLevels, seniorityLevel)} className="filter" data-test-id="seniority"><span>{seniorityLevel}</span><CloseIcon /></div>)}
                        {selectedSkills.map(skill => <div onClick={() => this.handleClearFilter("selectedSkills", selectedSkills, skill)} className="filter" data-test-id="skillFilter"><span>{skill}</span><CloseIcon /></div>)}
                        {selectedProfessions.map(profession => <div onClick={() => this.handleClearFilter("selectedProfessions", selectedProfessions, profession)} className="filter"  data-test-id="skillFilter"><span>{profession}</span><CloseIcon /></div>)}
                        {selectedCountries.map(country => <div onClick={() => this.handleClearFilter("selectedCountries", selectedCountries, country)} className="filter"  data-test-id="skillFilter"><span>{country}</span><CloseIcon /></div>)}
                        {selectedWorkTypes.map(workType => <div onClick={() => this.handleClearFilter("selectedWorkTypes", selectedWorkTypes, workType)} className="filter"  data-test-id="skillFilter"><span>{workType}</span><CloseIcon /></div>)}
                        {this.checkRate() ?<div onClick={() => this.handleClearFilter("rateRange")} className="filter" data-test-id="rateRangeFilter"><span>£{minRate}-{maxRate}</span><CloseIcon/></div>: <></>}
                    </div>
                    <span className="clearFilters" data-test-id="clearFilters" onClick={() => this.props.onClickClearAll(true)}>
                        Clear all filters
                    </span>
                </div>
            </>
        ) : <></>

    }

    checkRate = () => {
        const {minRate, maxRate} = this.props;
        if((minRate !== undefined) && (maxRate !== undefined) && (minRate < maxRate)){
            return true;
        }
        return false;
    }

    conditionRender = (placeholder: string) => {
        return <span>{placeholder}</span>
    }

    handleClearFilter = (type: string, arr?: string[], value?: string) => {
        const types = ["selectedSkills", 
            "selectedSeniorityLevels",
             "selectedWorkTypes", 
            "selectedProfessions", 
             "selectedCountries"];
        if (types.includes(type) && arr && arr.length > 0) {
            this.props.onClickClearFilter([...arr].filter(x => x !== value), type);
        } else {
            this.props.onClearRateRange();
        }
    }

    render() {
        const countries = Country.getAllCountries().map(state => ({ value: state.isoCode, label: state.name }));
        const { minRate, 
            maxRate, 
            selectedCountries, 
            workTypes, 
            selectedWorkTypes, 
            seniorityLevels,
            selectedSeniorityLevels, 
            skills, 
            selectedProfessions, 
            selectedSkills, 
            professions 
        } = this.props;
        return (
            <StyledAdvanceSearch>
                <StyledTextField
                    fullWidth
                    type="text"
                    data-test-id="searchFav"
                    onChange={(event)=>this.props.onChangeSearchText(event.target.value)}
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        className: "textfield"
                    }}
                    placeholder="Name, Company, Position"
                />
                <Typography className="filtersTitle">
                    Filters:
                </Typography>
                <div className="filterContainer">
                    <Autocomplete
                        className="professionType"
                        value={selectedProfessions}
                        multiple
                        data-test-id="professionType"
                        openOnFocus={false}
                        options={professions}
                        PaperComponent={StyledAutoCompletePaper}
                        disableCloseOnSelect
                        style={{border:"1px solid #DFDFDF"}}
                        renderOption={(option) => { 
                            return (
                                <div style={{ fontFamily: 'Silika-Medium' }}>
                                    {option}
                                </div>
                            );
                        }}
                        renderInput={(params) => <TextField {...params} InputProps={{ ...params.InputProps, startAdornment: <SearchIcon className="startEndornment" /> }} variant="outlined" placeholder="Profession" />}
                        onChange={(_, newValue) => {
                            this.props.onChangeFilter(newValue, "selectedProfessions")
                        }}
                    />
                    <StyledSelect
                        value={selectedSeniorityLevels}
                        placeholder="Seniority"
                        multiple
                        className="seniorityLevel"
                        data-test-id='seniorityLevel'
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        renderValue={() => this.conditionRender("Seniority")}
                        name='seniorityLevel'
                        variant="outlined"
                        disableUnderline
                        onChange={(event) => this.props.onChangeFilter(event.target.value as string[], "selectedSeniorityLevels")}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                horizontal: "left",
                                vertical: "bottom",
                            },
                            transformOrigin: {
                                horizontal: "left",
                                vertical: "top",
                            },
                            MenuListProps: {
                                className: "seniorityDropdown"
                            },
                            PaperProps: { component: StyledPaper }
                        }}

                    >
                        {seniorityLevels.map((menuItem) => <MenuItemFont key={menuItem} value={menuItem}>{menuItem}</MenuItemFont>)}
                    </StyledSelect>
                    <StyledSelect
                        data-test-id='workType'
                        displayEmpty
                        renderValue={() => this.conditionRender("Work Type")}
                        className="workType"
                        IconComponent={KeyboardArrowDownIcon}
                        value={selectedWorkTypes}
                        multiple
                        disableUnderline
                        variant="outlined"
                        name='workType'
                        placeholder="Work type"
                        onChange={(event) => this.props.onChangeFilter(event.target.value as string[], "selectedWorkTypes")}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                horizontal: "left",
                                vertical: "bottom",
                            },
                            transformOrigin: {
                                horizontal: "left",
                                vertical: "top",
                            },
                            MenuListProps: {
                                className: "workTypeDropdown"
                            },
                            PaperProps: { component: StyledPaper }
                        }}
                    >
                        {workTypes.map((menuItem) => <MenuItemFont key={menuItem} value={menuItem}>{menuItem}</MenuItemFont>)}
                    </StyledSelect>
                    <Autocomplete
                        className="skills"
                        data-test-id="skills"
                        openOnFocus={false}
                        value={selectedSkills}
                        multiple
                        disableCloseOnSelect
                        options={skills}
                        PaperComponent={StyledAutoCompletePaper}
                        renderInput={(params) => <TextField {...params} InputProps={{ ...params.InputProps, startAdornment: <SearchIcon className="startEndornment" /> }} variant="outlined" placeholder="Skills" />}
                        renderOption={(option) => <div style={{ fontFamily: "Silika-Medium" }}>{option}</div>}
                        onChange={(_, newValue) => this.props.onChangeFilter(newValue as string[], "selectedSkills")}
                    />
                    <StyledSelect
                        className="country"
                        data-test-id='country'
                        renderValue={() => this.conditionRender("Country")}
                        IconComponent={KeyboardArrowDownIcon}
                        value={selectedCountries}
                        disableUnderline
                        multiple
                        variant="outlined"
                        displayEmpty
                        name='country'
                        onChange={(event) => this.props.onChangeFilter(event.target.value as string[], "selectedCountries")}
                        MenuProps={{
                            getContentAnchorEl: null,            
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            MenuListProps: {
                                className: "countryDropdown"
                            },
                            PaperProps: { component: StyledPaper }
                        }}
                    >
                        {countries.map((menuItem) => <MenuItemFont key={menuItem.value} value={menuItem.label}>{menuItem.label}</MenuItemFont>)}
                    </StyledSelect>
                    <div>
                        <OutlinedInput
                            placeholder="Min Rate"
                            data-test-id="minRate"
                            className="rate"
                            value={minRate ?? ""}
                            startAdornment={<InputAdornment position="start">
                                <img src={poundIcon} style={{
                                    height: "14px"
                                }} /></InputAdornment>}
                            onChange={(event) => this.props.onMinRateChange(parseInt(event.target.value))}
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                        <span className="dash">-</span>
                        <OutlinedInput
                            placeholder="Max Rate"
                            data-test-id="maxRate"
                            className="rate"
                            value={maxRate ?? ""}
                            startAdornment={<InputAdornment position="start">
                                <img src={poundIcon} style={{
                                    height: "14px",
                                }} /></InputAdornment>}
                            onChange={(event) => this.props.onMaxRateChange(parseInt(event.target.value))}
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                    </div>
                </div>
                {this.renderFilters()}
            </StyledAdvanceSearch>
        )
    }
}

export default AdvanceSearch;