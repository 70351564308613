import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {userImg, } from "./assets";
import { ChangeEvent } from "react";
import { boolean } from "yup";
interface FileValue {
  name: string;
  file: File;
  type: string;
}

interface TableRowData {
  name: string;
  status: string;
  actions: string;
  actioneeName: string;
  actioneeImg: string;
  deadline: string;
  background: any;
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  isCalendarOpen: boolean, 
  selectedDate: Date | null;
  totalCandidates: string;
  type: string;
  token: string;
  openDialog:boolean,
  errorMsg: string;
  loading: boolean;
  showMessage:boolean;
  selectedFile: File | null;
  previewUrl: string | null;
  files: Array<FileValue>;
  showDetailSection:boolean
  showCloseDetailSection:boolean
  roleAttachmentFiles: Array<FileValue>;
  roleInvoiceFiles: Array<FileValue>;
  isCreate: boolean;
  tableRowData: Array<TableRowData>;
  expandedRowIndex: number | null;
  openTerminateModal: boolean;
  detailsShow: boolean,
  detailsText: string | undefined,
  openProjectModal: boolean,
  attachmentFiles: Array<FileValue>;
  bannerFiles: Array<FileValue>;
  contractFiles: Array<FileValue>;
  projFiles:Array<FileValue>;
  value:any;

  formData: {
    projectName: string;
    description: string;
    notes: string;
  };
  formErrors: {
    projectName?: string;
    description?: string;
  };
  formTouched: {
    projectName: boolean;
    description: boolean;
  };
  isFormValid: boolean;
  openModal:boolean;
  openProjCreated: boolean;
  openApproveModal: boolean,
  openApproveTwoModal: boolean,
  openRoleCreated: boolean;
  roleDeliverable: boolean;
  openCreateAnother: boolean;
  terminateContract: boolean;
  yesTerminateContract: boolean;
  openNewProj: boolean;
  invoice:boolean;
  openCollapsibleTable: { [key: string]: boolean };
  openFindtalent:boolean;
  search:boolean;
  timesheet:boolean,
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isCalendarOpen: false, 
      selectedDate: new Date(),
      type: "",
      openDialog:false,
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      showMessage:false,
      selectedFile: null,
      previewUrl: null,
      files: [],
      contractFiles:[],
      showDetailSection:false,
      showCloseDetailSection:false,
      roleAttachmentFiles: [],
      roleInvoiceFiles: [],
      projFiles:[],
      isCreate: false,    
      expandedRowIndex: null,
      openTerminateModal: false,
      detailsShow: false,
      detailsText: "",
      timesheet:false,
      tableRowData: [
        {
        name: "Week 01",
        status: "Paid",
        actioneeImg: userImg,
        deadline: "10 Feb 2024",
        background: "#D1FAE5",
        actions: "Confirm Payment",
        actioneeName: "Joel G.",
        
      },
      { actioneeName: "Martha S",
        actioneeImg: userImg,
        deadline: "08 Aug 2024",
        name: "Week 02",
        status: "Not started",
        actions: "Start Timesheet",
       
        background: "#DFDFDF"
      },
      {
        name: "Week 03",
        status: "In Progress",
        background: "#FEF3C7",
        actions: "Submit Timesheet",
        actioneeName: "Joan W.",
        actioneeImg: userImg,
        deadline: "25 Jul 2024",
      },
       {
        name: "Week 04",
        status: "Invoiced awaiting payment",
        actions: "Make Payment",
         deadline: "08 Aug 2024",
        background: "#DFDFDF",
        actioneeName: "Martha S",
        actioneeImg: userImg,
      },
      {
        status: "Submitted for Approval",
        actioneeName: "Martha S",
        actioneeImg: userImg,
        deadline: "16 Mar 2024",
        name: "Week 05",

        background: "#DFDFDF",
        actions: "Approve / Reject",
      }
    ],
    openProjectModal:false,
    attachmentFiles: [],
    bannerFiles:[],

    formData: {
      projectName: "",
      description: "",
      notes:""
    },
    formErrors: {},
    formTouched: {
      projectName: false,
      description: false,
    },
    isFormValid: false,
    openModal:false,
    openProjCreated: false,
    openApproveModal: false,
    openApproveTwoModal: false,
      openRoleCreated: false,
      roleDeliverable: false,
      openCreateAnother: false,
      terminateContract: false,
      yesTerminateContract:false,
      openNewProj: false,
      invoice: false,
      openCollapsibleTable: {},
      openFindtalent:false,
value:0,
search:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          if(webResponseJson.data){
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
          }
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleShowState = (subName?: string | undefined) => {
    this.setState({ detailsShow: !this.state.detailsShow, detailsText: subName }, () => {
    });
  };
  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];

    const filesWithTypes = selectedFiles.map((file) => {
      const fileType = file.type ? file.type.split("/")[1].split("+")[0] : "unknown";
      return {
        name: file.name,
        file,
        type: fileType,
      };
    });

    this.setState((prevState) => ({
      files: [...prevState.files, ...filesWithTypes],
    }));
  };
  showDialog = () => {
    this.setState({ openDialog: true });
  };
  handleClose = () => {
    this.setState({ openDialog: false });
  };
  closeFindtalent  = () => {
    this.setState({ openFindtalent: false });
  };
  openFindtalent  = () => {
    this.setState({ openFindtalent: true });
  };
  
handleChange = (event: any, newValue: number) => {
  this.setState({ value: newValue });
};
  navigateToDetailSection=()=>{
    this.setState({showDetailSection:!this.state.showDetailSection , showCloseDetailSection:false, invoice: false, timesheet:false})
  }
  navigateToCloseDetailSection=()=>{
    this.setState({showCloseDetailSection:!this.state.showCloseDetailSection ,showDetailSection:false , invoice: false, timesheet:false })
  }
  navigateToDashboard=()=>{
    this.setState({showDetailSection:!this.state.showDetailSection})
    this.setState({ isCreate: false , invoice: false, timesheet:false});    
  }
  navigateToInvoice=()=>{
    this.setState({ showDetailSection: !this.state.showDetailSection, isCreate: false ,  invoice: true, timesheet:false});
  }
  navigateToTimesheet=()=>{
    this.setState({ showDetailSection: this.state.showDetailSection, isCreate: false ,  invoice: true, timesheet:true});
  }
  openTimesheet=(e:any,rowValue:any)=>{
    e.stopPropagation(); // Stop event from bubbling up to parent TableRow
    if (rowValue.actions === 'Start Timesheet') {
      this.navigateToTimesheet();
    }
  }
  onCreateClick = () => {
    this.setState({ isCreate: !this.state.isCreate });
  };

  handleRoleAttachmentFile = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];
    
    const filesWithTypes = selectedFiles.map((file) => {
      const fileType = file.type ? file.type.split("/")[1].split("+")[0] : "unknown";
      return {
        name: file.name,
        file,
        type: fileType,
      };
    });

    this.setState((prevState) => ({
      roleAttachmentFiles: [...prevState.roleAttachmentFiles, ...filesWithTypes],
    }));
  };

  handleRoleInvoiceFile = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];

    const filesWithTypes = selectedFiles.map((file) => {
      const fileType = file.type ? file.type.split("/")[1].split("+")[0] : "unknown";
      return {
        name: file.name,
        file,
        type: fileType,
      };
    });

    this.setState((prevState) => ({
      roleInvoiceFiles: [...prevState.roleInvoiceFiles, ...filesWithTypes],
    }));
  };

  handleRowClick = (index: number) => {
    this.setState((prevState) => ({
      expandedRowIndex: prevState.expandedRowIndex === index ? null : index,
    }));
  };

  handleTerminateModal = () => {
    this.setState((prevState) => ({
      openTerminateModal: !prevState.openTerminateModal,
    }));
  }
  toggleCalendar = () => {
    this.setState((prevState) => ({
      isCalendarOpen: !prevState.isCalendarOpen,
    }));
  };
  handleDateChange = (date: Date | null) => {
    this.setState({ selectedDate: date ,isCalendarOpen: false, });
  };
  formatDate(date: Date | null) {
    if (!date) return "";
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2); 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  showProjectDialog = () => {
    this.setState({ openProjectModal: true });
  };
  handleProjectClose = () => {
    this.setState({ openProjectModal: false });
  };
  handleBannerChange = (event: ChangeEvent<HTMLInputElement>, type: string) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];

    const filesWithTypes = selectedFiles.map((file) => {
      const fileType = file.type ? file.type.split("/")[1].split("+")[0] : "unknown";
      return {
        name: file.name,
        file,
        type: fileType,
      };
    });

    this.setState((prevState) => {
      const newState = { ...prevState };
      if (type === "banner") {
        newState.bannerFiles = [...prevState.bannerFiles, ...filesWithTypes];
      } else if (type === "attachments") {
        newState.attachmentFiles = [...prevState.attachmentFiles, ...filesWithTypes];
      } else if(type === "projFiles") {
        newState.projFiles = [...prevState.projFiles, ...filesWithTypes];
      } else {
        newState.contractFiles = [...prevState.contractFiles, ...filesWithTypes];
      }

      return newState;
    });
  };


  validateField = (name: string, value: string): string => {
    switch (name) {
      case "projectName":
        return !value ? "This field is required" : "";
      case "description":
        if (!value) return "This field is required";
        if (value.length < 10) return "Description must be at least 10 characters";
        return "";
      default:
        return "";
    }
  };

  validateForm = (): boolean => {
    const { projectName, description, notes } = this.state.formData;
    const errors: Record<string, string> = {};

    errors.projectName = this.validateField("projectName", projectName);
    errors.description = this.validateField("description", description);

    this.setState({ formErrors: errors });

    return !Object.values(errors).some(error => error);
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }), () => {
      // Validate field and update errors
      const error = this.validateField(name, value);
      this.setState(prevState => ({
        formErrors: {
          ...prevState.formErrors,
          [name]: error
        },
        isFormValid: this.validateForm()
      }));
    });
  };

  handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name } = e.target;
    this.setState(prevState => ({
      formTouched: {
        ...prevState.formTouched,
        [name]: true
      }
    }));
  };

  handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Mark all fields as touched
    this.setState({
      formTouched: {
        projectName: true,
        description: true
      }
    },()=>{
      this.showOpenProjCreated()
    });

   
  };

  showRoleDialog = () => {
    this.setState({ openModal: true });
  };
  handleRoleClose = () => {
    this.setState({ openModal: false });
  };

  showOpenProjCreated = () => {
    this.setState({ openProjCreated: true });
  };
  handleProjectCreateClose = () => {
    this.setState({ openProjCreated: false });
  };

  showApproveDialog = () => {
    this.setState({ openApproveModal: true });
  };
  handleApproveClose = () => {
    this.setState({ openApproveModal: false });
  };

  showApproveDialogTwo = () => {
    this.setState({ openApproveTwoModal: true }, () => this.setState({ openApproveModal: false }));
  }

  handleApproveCloseTwo = () => {
    this.setState({ openApproveTwoModal: false }, () => this.setState({ openApproveModal: true }));
  };
  showOpenRoleCreated = () => {
    this.setState({ openRoleCreated: true });
  };
  handleRoleCreateClose = () => {
    this.setState({ openRoleCreated: false });
  };
  showDeliverable = () => {
    this.setState({ roleDeliverable: true });
  };
  closeDeliverable = () => {
    this.setState({ roleDeliverable: false });
  };
  showCreateAnother = () => {
    this.setState({ openCreateAnother: true });
  };
  closeCreateAnother = () => {
    this.setState({ openCreateAnother: false });
  };
  handleTerminate = () => {
    this.setState((prevState) => ({
      terminateContract: !prevState.terminateContract,
    }));
  };
  handleYesTerminate = () => {
    this.setState((prevState) => ({
      yesTerminateContract: !prevState.yesTerminateContract,
    }));
  };

  handleNewProj = () => {
    this.setState((prevState) => ({
      openNewProj: !prevState.openNewProj,
    }));
  };

  toggleCollapsible = (key:any) => {
    this.setState(prevState => ({
      openCollapsibleTable: {
        ...prevState.openCollapsibleTable,
        [key]: !prevState.openCollapsibleTable[key]
      }
    }));
  };
  
  // Customizable Area End
}
