import React, { useState } from "react";
import {
  Box,
  Button as MuiButton,
  createTheme,
  Divider,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import AccordionDetails from '@material-ui/core/AccordionDetails';
const imageCreative = require("./imageCreative.svg");
import { actioneeImg } from "../../blocks/dashboard/src/assets";

function createData(
  Project: string,
  Client: string,
  Deliverable: string,
  Action: string,
  Due: string
) {
  return { Project, Client, Deliverable, Action, Due };
}
function createData1(name: string, subName?: string) {
  return { name, subName };
}

const rows = [
  createData(
    "0001 Truckers",
    "car imagine",
    "01 Car circuits",
    "Submit deliverable",
    "5 Days"
  ),
  createData(
    "0002 Van O5",
    "car imagine",
    "02 Car circuits",
    "Submit Timesheet",
    "2 Days"
  ),
  createData(
    "0003 Van O5",
    "car imagine",
    "03 Car circuits",
    "Confirm Payment",
    "-245 Days"
  ),
];
const rowOpen = [
  {
    id: "001",
    name: "Car circuits",
    status: "Invoiced awaiting payment",
    action: "Make Payment",
    actioneeImg: actioneeImg,
    Actionee: "Joel G.",
    Due: "5 Days",
  },
  {
    id: "002",
    name: "Frontal Lights",
    status: "Submitted for Approval",
    action: "Approve/Reject",
    actioneeImg: actioneeImg,
    Actionee: "Martha S.",
    Due: "2 Days"
  },
  {
    id: "003",
    name: "Car Dashboard",
    status: "Paid",
    action: "Approve/Reject",
    actioneeImg: actioneeImg,
    Actionee: "Joan W.",
    Due: "-245 Days"
  },
];
function createData_(name: string, Mon: string, Tue: string, Wed: string, Thu: string, Fri: string, Sat: string, Sun: string, Hours: string, Rate: string, Amount: string, Status: string): { name: string, Mon: string, Tue: string, Wed: string, Thu: string, Fri: string, Sat: string, Sun: string, Hours: string, Rate: string, Amount: string,Status: string } {
  return { name, Mon, Tue, Wed, Thu, Fri, Sat, Sun, Hours, Rate, Amount, Status };
}
function tableData_(dayDate: any): { dayDate: any } {
  return { dayDate };
}

const rowsData = [
  createData_('Week 1', "2:00", "-",  "2:00", "-", "2:00", "", "", "6:00", "£50", "£300","Submitted"),
  createData_('Week 2', "3:00", "3:00", "3:00", "3:00", "3:00", "-", "-", "15:00", "£50", "£750","Submit Timesheet"),
  createData_('Week 3',  "3:00", "3:00", "3:00", "3:00", "3:00", "-", "-", "15:00", "£50", "£750",""),
];

const dateData = [
  tableData_({day:"Mon", date:"31 Mar"}),
  tableData_({day:"Tue", date:"1 Apr"}),
  tableData_({day:"Wed", date:"2 Apr"}),
  tableData_({day:"Thu", date:"3 Apr"}),
  tableData_({day:"Fri", date:"4 Apr"}),
  tableData_({day:"Sat", date:"5 Apr"}),
  tableData_({day:"Sun", date:"6 Apr"}),
];

const openProject = [
  createData1("0001 Truckers", "(Deliverable based)"),
  createData1("0002 Van 05", "(Hourly based)"),
  createData1("0003 Truckers", "(Deliverable based)"),
  createData1("0004 Van o5", "(Hourly based)"),
  createData1("0005 Truckers", "(Deliverable based)"),
];

const closedProject = [
  createData1("0001 Truckers"),
  createData1("0002 Van 05"),
  createData1("0003 Truckers"),
  createData1("0004 Van O5"),
  createData1("0005 Truckers"),
];


const DashboardTable = () => {
  return (
    <>
      <Timesheets>
        <Box className="Boxstyle">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="TimeSheet">Timesheet</TableCell>
                   {dateData.map((date) => (    
                     <TableCell align="right">
                      <Typography>{date.dayDate.day}</Typography>
                      <Typography>{date.dayDate.date}</Typography>
                     </TableCell>
                    ))
                   }
                  <TableCell className="cellLight" align="right">
                    Hours
                  </TableCell>
                  <TableCell className="cellLight" align="right">
                    Rate
                  </TableCell>
                  <TableCell className="cellDark" align="right">
                    Amount
                  </TableCell>
                  <TableCell className="cellLight" align="right">
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsData.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell className="weekstyle" component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      <span className="cellStyle">{row.Mon}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="cellStyle">{row.Tue}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="cellStyle">{row.Wed}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="cellStyle">{row.Thu}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="cellStyle">{row.Fri}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="cellStyle">{row.Sat}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="cellStyle">{row.Sun}</span>
                    </TableCell>
                    <TableCell className="cellLight" align="right">
                      {row.Hours}
                    </TableCell>
                    <TableCell className="cellLight" align="right">
                      {row.Rate}
                    </TableCell>
                    <TableCell className="cellDark" align="right">
                      {row.Amount}
                    </TableCell>
                    <TableCell className="btnStyle" align="right">
                    <button  className={
                    row.Status === "Submitted"
                      ? "submitStatus"
                      : row.Status === "Submit Timesheet" ? "timeSheetStatus" : "blankStatus"
                  }
                >
                  {row.Status}</button> 
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Timesheets>
    </>
  );
}

const CreativeDashboard = (props: any) => {
  const {handleShowState} = props
 const [openDetails, setOpenDetails] = useState(
    new Array(openProject.length).fill(false)
  ); 
  const [show,setShow] = useState(
    new Array(openProject.length).fill(null).map(() => new Array(rowOpen.length).fill(false))
  )
 const [moreDetails, setMoreDetails] = useState(
    new Array(openProject.length).fill(false)
  ); 

  const handleClick = (index: number) => {
    const newOpenDetails = [...openDetails];
    newOpenDetails[index] = !newOpenDetails[index];
    setOpenDetails(newOpenDetails);
  };

  const showAccordian = (parentIdx: number, index: number) => {
    const newShow = [...show];
    newShow[parentIdx][index] = !newShow[parentIdx][index];
    setShow(newShow);
  };
  
  const handleClickDetails = (index: number, subName: string | undefined) => {
    const newOpenDetails = [...moreDetails];
    newOpenDetails[index] = !newOpenDetails[index];
    setMoreDetails(newOpenDetails);
    handleShowState(subName);
  };

      return (
  <Box>
    <HeadingBox>
    <Typography className="headingStyle">Dashboard</Typography>
    </HeadingBox>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row'
          },
          marginBottom: "40px"
        }}>
        <TopSection sx={{
          flex: 1,
          mb: { xs: 2, md: 0 },
          mr: { xs: 0, md: 5 }
        }}>
          <Box className='boxStyle'>
            <Typography className='heading'>Opportunities</Typography>
            <Typography className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis.</Typography>
            <MuiButton  className="btnStyle" >Explore</MuiButton>
          </Box>
        </TopSection>
        <TopSection sx={{ flex: 1 }}>
          <Box className='boxStyle'>
            <Typography className='heading'>Events</Typography>
            <Typography className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis.</Typography>
            <MuiButton  className="btnStyle" >Create</MuiButton>
          </Box>
        </TopSection>
      </Box>
      <NextActionSection>
        <Box className="boxStyle">
          <Typography className="heading">
            Next actions <span className="spanStyle">3</span>
          </Typography>
          <Divider className="lineStyle"></Divider>
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      fontFamily: "silika-Medium",
                    }}
                  >
                    Project
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      fontFamily: "silika-Medium",
                    }}
                    align="left"
                  >
                    Client
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      fontFamily: "silika-Medium",
                    }}
                    align="left"
                  >
                    Deliverable
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      fontFamily: "silika-Medium",
                    }}
                    align="left"
                  >
                    Action
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      fontFamily: "silika-Medium",
                    }}
                    align="left"
                  >
                    Due
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow>
                    <TableCell align="left">{row.Project} </TableCell>
                    <TableCell
                      align="left"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img src={imageCreative} height={32} width={32} />
                      &nbsp;&nbsp;
                      <span style={{ textDecoration: "underline" }}>
                        {row.Client}
                      </span>
                    </TableCell>
                    <TableCell align="left">{row.Deliverable}</TableCell>
                    <TableCell align="left">
                      <Button>{row.Action}</Button>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: `${parseInt(row.Due) < 0 ? "red" : "black"}`,
                      }}
                    >
                      {row.Due}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </NextActionSection>
      <OpenProjectSection>
        <Box>
          <Box className="projectHead">
            {" "}
            <Typography>Open Projects</Typography>{" "}
          </Box>
          <Box
            style={{
              padding: "41px 50px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="simple table">
                <TableBody>
                  {openProject.map((open, index) => (
                    <TableRow style={{ width: "100%" }} key={open.name}>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#9B9B9B",
                          width: "100%",
                        }}
                      >
                        <Box
                          className={`tableClick ${openDetails[index] && "tableClickTrue"}`}
                        
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#000000",
                            }}
                            onClick={() => handleClick(index)}
                          >
                            <FolderOpenOutlinedIcon />
                            &nbsp;&nbsp;&nbsp;&nbsp;<span>{open.name}</span>
                            &nbsp;
                            <span style={{ color: "grey" }}>
                              {" "}
                              {open.subName}
                            </span>{" "}
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#000000",
                            }} 
                            onClick={props.navigateToDetailSection}
                             >
                            Details <ArrowForwardIosIcon />
                          </Box>
                        </Box>
                        {openDetails[index] && (
                          <CustomBox>
                            <Box className="openProContainer">
                              <img
                                src={imageCreative}
                                alt="image"
                                className="proImage"
                              />
                              <ClickContainer>
                                <Box>
                                  <Box>
                                    <span className="containerHeading">
                                      Automotive Designer
                                    </span>
                                    &nbsp;
                                    <span className="containerSubHeading">
                                      {open.subName}
                                    </span>
                                  </Box>
                                  <Box>Supercars</Box>
                                </Box>
                                <Box className="openTable">
                                  <Box style={{ display: "flex", gap: "45px", width: "100%" }}>
                                    <Box className="openTableRow">
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <Box className="openRowValues">
                                          <span className="headValue">
                                            Dates:
                                          </span>
                                          <span className="fieldValue">
                                            Feb 30 - Feb 20
                                          </span>
                                        </Box>
                                        <Box className="openRowValues">
                                          <span className="headValue">
                                          Rate:
                                          </span>
                                          <span className="fieldValue">
                                          NA
                                          </span>
                                        </Box>
                                        <Box className="openRowValues">
                                          <span className="headValue">
                                            Budget:
                                          </span>
                                          <span className="fieldValue">
                                            £2,000
                                          </span>
                                        </Box>
                                        <Box className="openRowValues">
                                     
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </ClickContainer>
                            </Box>
                            <Box className="boxStyle">
                              <Divider className="lineStyle"></Divider>
                              <TableContainer component={Paper} elevation={0}>
                                <Table aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          fontFamily: "silika-Medium",
                                        }}
                                      >
                                        Deliverable
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          fontFamily: "silika-Medium",
                                        }}
                                        align="left"
                                      >
                                        Status
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          fontFamily: "silika-Medium",
                                        }}
                                        align="left"
                                      >
                                        Action
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          fontFamily: "silika-Medium",
                                        }}
                                        align="left"
                                      >
                                        Actionee
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          fontFamily: "silika-Medium",
                                        }}
                                        align="left"
                                      >
                                        Due
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody className="">
                                    {rowOpen.map((row, idx) => (
                                      <>
                                      <TableRow>
                                        <TableCell align="left">
                                          <span className="tableRowValueId">
                                            {row.id}
                                          </span>
                                          <span className="tableRowValueName">
                                            {row.name}
                                          </span>
                                        </TableCell>
                                        <TableCell align="left">
                                          <span  onClick={() => showAccordian(index, idx)} className={row.status !== "Paid" ? "tableRowStatus" : "tableRowPaidStatus"}>
                                            {row.status}
                                          </span>
                                        </TableCell>
                                        <TableCell align="left">
                                          <Button>{row.action}</Button>
                                        </TableCell>
                                        <TableCell align="left" style={{ display: "flex", alignItems: "center"}}>
                                          <span className="tableRawImage">
                                            <img src={row.actioneeImg} alt="actioneeImg" />
                                          </span>
                                          &nbsp;&nbsp;
                                          <span className="tableRawNames">
                                            {row.Actionee}
                                          </span>
                                        </TableCell>
                                        <TableCell 
                                        align="left"
                                        style={{
                                          color: `${parseInt(row.Due) < 0 ? "red" : "black"}`,
                                        }}
                                        >
                                          <span className="tableRawDates">
                                            {row.Due}
                                          </span>
                                        </TableCell>
                                      </TableRow>
                                        </>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          </CustomBox>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </OpenProjectSection>
      <CloseProjectSection>
        <Box>
          <Box className="projectHead">
            {" "}
            <Typography>Closed Projects</Typography>
          </Box>
          <Box
            style={{
              padding: "41px 50px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="simple table">
                <TableBody>
                  {closedProject.map((open, index) => (
                    <TableRow style={{ width: "100%" }}>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#9B9B9B",
                          width: "100%",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FolderOpenOutlinedIcon />
                            &nbsp;&nbsp;&nbsp;&nbsp;{open.name}{" "}
                          </Box>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                            onClick={()=>handleClickDetails(index, open.subName)}
                          >
                            Details <ArrowForwardIosIcon />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </CloseProjectSection>
      {/* <DashboardTable/> */}
    </Box>
  );
};

const theme = createTheme({
  typography: {
    fontFamily: "Silka",
  },
});
const Timesheets = styled(Box)({
    marginTop: "90px",
    borderRadius: "4px",
    width: "100%",
    height: "256px",
    gap: "8px",
    " & .Boxstyle":{
        border: '1px solid #DFDFDF',
        width: "95%",
        height: "312px",
        gap: "32px",
        color: "red",
        margin: "auto",
      },
      "& .TimeSheet":{
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "19.36px",
        alignItems: "center",
        letterSpacingz: "-0.15px",
        width: "220px",
      },
      "& .weekstyle": {
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "19.36px",
        font: "silka",
      },
      "& .cellStyle": {
        borderBottom: "1px solid grey",
      },
      "& .cellDark": {
        backgroundColor: "grey",
        width: "62px",
      },
      "& .cellLight": {
        backgroundColor: "lightgrey",
        width: "62px",
      },
      "& .btnStyle": {
        backgroundColor: "lightgrey",
        width: "174px",
      },
      "& .submitStatus": {
        width: '145px',
        height: '32px',
        padding: '2px 8px',
        gap: '8px',
        borderRadius: '2px 0px 0px 0px',
        backgroundColor:  'E2E8F0',
        color: 'black',
      },
      "& .timeSheetStatus": {
        width: '145px',
        height: '32px',
        padding: '6px 10px',
        gap: '6px',
        borderRadius: '2px 0px 0px 0px',
        backgroundColor: 'black', 
        color: 'white',
      },
      "& .blankStatus":{
        display: "none",
      },
})
const TopSection = styled(Box)({
  "& .boxStyle": {
      padding: "41px",
      background: "#FFFFFF",
      border: "1px solid #DFDFDF",
      borderRadius: "4px 0px 0px 0px",
      boxShadow: "0px 10px 25px 0px #00000017",
  },
  "& .heading": {
      fontFamily: "Silika-Medium !important",
      fontSize: "20px !important",
      fontWeight: "500 important",
      lineHeight: "24px",
      color: "#000000",
  },
  "& .content": {
    lineHeight: "24px",
    paddingBottom: "45px",
    paddingTop: "30px",
    color: "#000000",
    fontWeight: 200,
    fontSize: "16px",
    fontFamily: "Silika-ExtraLight",
    marginTop: "10px"
 },
  "& .btnStyle":{
    width: "104px",
    height: "48px",
    border: "1px solid", 
    backgroundColor: 'black',
    color: "white",
    radius: "2px",
    padding: "16px",
    gap: "8px",
  },
  "& .pb-0":{
    paddingBottom:0
  },
  "& .lineStyle": {
      margin: "10px 0px",
  },
  "& .spanStyle": {
      background: "#FF5959",
      padding: "2px 8px",
      borderRadius: "50%",
      width: "24px",
      color: "#fff",
      fontSize: "16px"
  },
});

const HeadingBox = styled(Box)({
  "& .headingStyle":{
    marginBottom: 95,
    fontFamily: "silika-Medium",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    fontWeight: 500,
    fontSize: "32px",
    [theme.breakpoints.down('sm')] :{
      marginBottom : 24,
      fontSize: "20px",
  }},
});

const NextActionSection = styled(Box)({
  "& .boxStyle": {
    padding: "41px",
    background: "#FFFFFF",
    border: "1px solid #DFDFDF",
    borderRadius: "4px 0px 0px 0px",
    boxShadow: "0px 10px 25px 0px #00000017",
  },
  "& .heading": {
    fontFamily: "silika-Medium",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#000000",
    paddingBottom: "30px",
  },
  "& .lineStyle": {
    margin: "10px 0px",
  },
  "& .spanStyle": {
    background: "#FF5959",
    padding: "2px 8px",
    borderRadius: "50%",
    width: "24px",
    color: "#fff",
    fontSize: "16px",
  },
});
const CloseProjectSection = styled(Box)({
  marginTop: "90px",
  border: "1px solid #DFDFDF",
  borderRadius: "4px",
  "& .projectHead": {
    paddingLeft: "41px",
    paddingRight: "41px",
    height: "61px",
    borderRadius: "4px 4px 0px 0px",
    background: "#DFDFDF",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "silika-Medium",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "19.2px",
    color: "#000000",
  },
});
const OpenProjectSection = styled(Box)({
  marginTop: "90px",
  border: "1px solid #DFDFDF",
  borderRadius: "4px",
  "& .projectHead": {
    paddingLeft: "41px",
    paddingRight: "41px",
    height: "61px",
    borderRadius: "4px 4px 0px 0px",
    background: "#DFDFDF",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "silika-Medium",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "19.2px",
    color: "#000000",
  },
  "& .openProContainer": {
    display: "flex",
    flexDirection: "row",
    gap: "36px",
  },
  "& .proImage": {
    width: "133px",
    height: "134px",
    borderRadius: "4px",
    borderWidth: "1px",
  },
  "& .tableClick": {
    display: "flex",
    justifyContent: "space-between",
    padding:"23px 40px"
  },
  "& .tableClickTrue": {
    backgroundColor: "lightgrey"
  },
  "& .statusAccorian": {
    display: "flex",
    flexDirection: "column",
  }
});

const CustomBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "52px",
  padding: "24px 44px 48px 44px",
  borderRadius: "4px",
  "& .heading": {
    fontFamily: "silika-Medium",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#000000",
    paddingBottom: "30px",
  },
  "& .lineStyle": {
    margin: "10px 0px",
  },
  "& .spanStyle": {
    background: "#FF5959",
    padding: "2px 8px",
    borderRadius: "50%",
    width: "24px",
    color: "#fff",
    fontSize: "16px",
  },
  "& .tableRowValueId": {
    padding: "12px",
    fontFamily: "silka",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "19.69px",
    color: "black",
  },
  "& .tableRowValueName": {
    padding: "12px",
    fontFamily: "silka",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "19.36px",
    color: "black",
  },
  "& .tableRowStatus": {
    display:"block",
    textAlign:"center",
    width: "203px",
    height: "22px",
    fontFamily:"silka",
    fontWeight:500,
    borderRadius: "2px",
    backgroundColor: "lightgrey",
    color:"black",
    padding: "2px 19.5px",
    fontSize: "12px",
    lineHeight: "18px",
  },
  "& .tableRowPaidStatus": {
    display:"block",
    textAlign:"center",
    width: "203px",
    height: "22px",
    fontFamily:"silka",
    fontWeight:500,
    borderRadius: "2px",
    backgroundColor: "#FEF3C7",
    color:"#d97706",
    padding: "2px 19.5px",
    fontSize: "12px",
    lineHeight: "18px",
  },
  // "&. tableImgName": {
  //   display: "flex",
  //   gap: "12px",
  //   justifyContent: "center",
  //   alignItems: "center",
    "&. tableRawImage": {
      width: "32px",
      height: "32px",
      borderRadius: "16px"
    },
    "&. tableRawNames": {
      fontFamily: 'Silka',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '18px',
      marginBottom: '8px'
    },
  // },
  "& .tableRawDates": {
    fontFamily: 'Silka',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '19.69px',
  },
});

const ClickContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "26px",
  width: "-moz-available",
  "& .containerHeading": {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "26px",
    lineHeight: "31.37px",
    letterSpacing: "-2.2",
    color: "black",
  },
  "& .containerSubHeading": {
    fontFamily: "silka",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "24px",
    color: "black",
  },
  "& .openTable": {
    display: "flex",
    gap: "92px",
    borderStyle: "solid",
    borderWidth: "1px 0px 1px 0px",
    borderColor: "lightgrey",
  },
  "& .openTableRow": {
    display: "flex",
    gap: "25px",
    padding: "10px 0px 10px 0px",
    width: "100%",
  },
  "& .openRowValues": {
    display: "flex",
    gap: "13px",
  },
  "& .headValue": {
    fontFamily: "silka",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "19.36px",
  },
  "& .fieldValue": {
    fontFamily: "silka",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "black",
  },
});

const Button = styled(MuiButton)({
  width: "175px",
  height: "32px",
  padding: "16px",
  borderRadius: "2px",
  backgroundColor: "#0D0C22",
  color: "#FFFFFF",
  fontFamily: "silika-Medium",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#0D0C22",
    boxShadow: "none",
  },
});

export default CreativeDashboard;
