import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import _ from "lodash";
import { setStorageData } from "../../../../packages/framework/src/Utilities";
import { isSlowInternet } from "../../../../packages/components/src/measureInternet.web";
interface ImageAttributes {
  id: number;
  name: string;
  image: string;
}
interface IListGallery {
  data: MediaImage[];
  errors?: object;
}

interface MediaImage {
  id: number;
  filename: string;
  url: string;
  type: string;
  is_visible?:boolean
}

interface MediaPostAttributes {
  id: number;
  confidential: boolean;
  account_id: number;
  images: MediaImage[];
  collaborators?: number[];
  profile_id ?: number;
}

interface MediaPost {
  id: string;
  type: string;
  attributes: MediaPostAttributes;
}

interface Pagination{
  total_pages: number;
  current_page: number;
  per_page: number;
  total_count: number;
  is_first_page: boolean;
  is_last_page: boolean; 
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  lastPage: boolean;
  images: MediaPost[];
  searchImage: MediaPost[];
  searchMassege:string;
  isLoadingMore: boolean;
  isLogedIn : boolean;
  searchValue:string;
  needLoadMoreBtn: boolean;
  pagination: Pagination;
  perPage: number;
  page: number;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class LandingPage6Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMediaGalleryApiID: string = "";
  getMediaGallerySearchApiID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      lastPage: false,
      images: [],
      searchImage: [],
      searchMassege:"",
      isLoadingMore: false,
      isLogedIn : false,
      searchValue:"",
      needLoadMoreBtn: false,
      perPage: 20,
      page: 1,
      pagination:{
        total_pages: 0,
        current_page: 0,
        per_page: 0,
        total_count: 0,
        is_first_page: false,
        is_last_page: false
    }
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getMediaGalleryApiID) {
          this.handleListGalleryResponse(responseJson);
        }
        if(apiRequestCallId==this.getMediaGallerySearchApiID){
          this.handleListGallerySearchResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Star
  async componentDidMount() {
    this.getMediaGallery(this.state.page, this.state.perPage);
    window.addEventListener("scroll", this.handleScroll);
    if(window.localStorage.getItem("token")){
      this.setState({isLogedIn : true})
    }
    window.scroll({
      top: 0,
      left: 0
  })
  }
  componentWillUnmount(): Promise<void> {
    window.removeEventListener("scroll", this.handleScroll);
    return Promise.resolve();
  }
  handleSearch = () => {
    return(
      this.state.searchImage.length <= 0 && this.state.searchValue
      ? "(0)"
      : (this.state.searchImage.length > 0 && this.state.searchValue) 
      ? `(${this.state.searchImage.length})`
      : ("") 
    )
  }

  handleLoadMore = () => {
    this.props.navigation.navigate("AccountLogin")
  };
  
  handleImageNavigation = async (id : string, confidential:boolean, profileId?: string) =>{
    if(this.state.isLogedIn && !confidential){
      this.props.navigation.navigate("Profiles", { id: profileId, postId: id });
    }else if(!confidential){
      await setStorageData("postId", id)
      this.props.navigation.navigate("AccountLogin");
    }
  }

  handleLoadMoreClick = () => {
    if(!this.state.pagination.is_last_page){
      this.setState({isLoadingMore: true})
      this.getMediaGallery(this.state.pagination.current_page + 1, this.state.perPage);
    }
  };

  handleScroll = async () => {
    if(this.state.searchValue.length === 0 && this.state.isLogedIn){
    const scrollTopValue = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeightValue = window.innerHeight;
    const scrollHeightValue = document.documentElement.scrollHeight;
    const touchedBottom = windowHeightValue + scrollTopValue >= scrollHeightValue;
    if (touchedBottom) {
      if(await isSlowInternet()){
        this.setState({needLoadMoreBtn:true});
      }
      else{
        this.handleLoadMoreClick();
      }
    }
    }
  };

  getTokenURL = () => {
    const token = localStorage.getItem("token");
    let url = !!token ? configJSON.getMediaGalleryLoginApiEntPoint : configJSON.getMediaGalleryApiEndPoint;
    return {url, token};
  }

  getMediaGallery = async (page:number, perPage:number) => {
    const object = this.getTokenURL();
    const header = {
      token: object.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${object.url}?page=${page}&per_page=${perPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getMediaGalleryApiID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleListGalleryResponse = async (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState((prevState) => ({
        images: [...prevState.images, ...responseJson.posts.data],
        isLoadingMore : false,
        pagination:{
          total_pages: responseJson.meta.pagination.total_pages,
          current_page: responseJson.meta.pagination.current_page,
          per_page: responseJson.meta.pagination.per_page,
          total_count: responseJson.meta.pagination.total_count,
          is_first_page: responseJson.meta.pagination.is_first_page,
          is_last_page: responseJson.meta.pagination.is_last_page
          }
      }));
    }
  };

  handleListGallerySearchResponse = async (responseJson: any) => {
    if(responseJson.message!==undefined ){
      this.setState({searchMassege:responseJson.message,searchImage:[]})
    }
    else{
      this.setState({searchMassege:""})
      if (responseJson && !responseJson.errors) {
        this.setState((prevState) => ({
          searchImage: responseJson.posts.data,
          isLoadingMore : false
        }));
      }
    }
    
  };


  handleSearchDebounce = _.debounce(() => {
    const object = this.getTokenURL();
    const {searchValue} = this.state;
    const header = {
      token: object.token
    };
    if(searchValue===""){
      this.getMediaGallery(this.state.pagination.current_page,this.state.perPage);
      this.setState({searchImage:[] , searchMassege:""})
    }
    else{
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
         `${object.url}?query=${searchValue}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      this.getMediaGallerySearchApiID = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }, 1800)

  searchHandle = (text: string) => {
    this.setState({ searchValue: text }, this.handleSearchDebounce);
    this.handleSearch()
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
