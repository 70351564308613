import React from "react";
import { Grid, Typography } from "@mui/material";
import HtmlTypography from "../../../../components/src/HtmlTypography";

interface TitleContainerProps {
  title: string;
  description: string;
  backgroundColor?:string;
  className?: string;
}

const TitleContainer: React.FC<TitleContainerProps> = ({ className, title, description, backgroundColor }) => {
  return (
    <Grid container className={`${className} findTalentTitleContainer`} style={{ backgroundColor: backgroundColor}}>
      <Grid item xl={12}>
        <HtmlTypography className="findTalentTitle" htmlContent={title } />
        <HtmlTypography className="findPara paragraphTitle" htmlContent={description} />
      </Grid>
    </Grid>
  );
};

export default TitleContainer;
