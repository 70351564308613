import React from "react";
import {
  Box,
  Button,
  Container,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Table,
  IconButton,
} from "@material-ui/core";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const HeaderCell = styled(TableCell)({
  padding: "16px 8px",
  color: "#0101g01",
  fontSize: "14px",
  fontWeight: 400,
  border: "none",
  borderBottom: "1px solid #E5E5E5",
  textAlign: "left",
  "&.date": {
    color: "#9CA3AF",
    fontSize: "12px",
    display: "block",
    marginTop: "4px"
  }
});

const DataCell = styled(TableCell)({
  padding: "16px 8px",
  fontSize: "14px",
  border: "none",

  color: "#111827",
});

const ActionButton = styled(Button)({
  textTransform: "none",
  padding: "8px 16px",
  height: "36px",
  fontSize: "14px",
  fontWeight: 500,
  borderRadius: "4px",
  "&.approve": {
    backgroundColor: "#0D0C22",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#0D0C22",
    }
  },
  "&.reject": {
    border: "1px solid #D1D5DB",
    color: "#374151",
    marginRight: "8px"
  }
});

const TimesheetView = () => {
  return (
    <Container maxWidth="xl">
      <Box sx={{ padding: "24px" }}>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "32px"
          }}
        >

          <Typography variant="body1"> <img height={22} width={22} src={download} /></Typography>
        </Box> */}

        {/* Timesheet Table */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow >
                <HeaderCell style={{border:"none", padding:"24px 0px"}} colSpan={9}></HeaderCell>
                <HeaderCell style={{ backgroundColor: "#F8F8F8", padding:"16px 0px" }}></HeaderCell>
                <HeaderCell style={{ backgroundColor: "#F8F8F8" , padding:"16px 0px"}}></HeaderCell>
                <HeaderCell style={{ backgroundColor: "#CDCDCE" ,  padding:"16px 0px", width:'80px'}}></HeaderCell>
              </TableRow>
              <TableRow>
                <HeaderCell> <Box sx={{ display: "flex", alignItems: "center", gridRowGap: 1 }}>
                  <IconButton size="small">
                    <ArrowBackIosIcon fontSize="small" />
                  </IconButton>
                  <Typography variant="body1">Current Month</Typography>
                  <IconButton size="small">
                    <ArrowForwardIosIcon fontSize="small" />
                  </IconButton>
                </Box></HeaderCell>
                <HeaderCell>
                  <div className="date">Timesheet</div>
                </HeaderCell>
                <HeaderCell>
                  Mon
                  <div className="date">31 Mar</div>
                </HeaderCell>
                <HeaderCell>
                  Tue
                  <div className="date">1 Apr</div>
                </HeaderCell>
                <HeaderCell>
                  Wed
                  <div className="date">2 Apr</div>
                </HeaderCell>
                <HeaderCell>
                  Thu
                  <div className="date">3 Apr</div>
                </HeaderCell>
                <HeaderCell>
                  Fri
                  <div className="date">4 Apr</div>
                </HeaderCell>
                <HeaderCell>
                  Sat
                  <div className="date">5 Apr</div>
                </HeaderCell>
                <HeaderCell>
                  Sun
                  <div className="date">6 Apr</div>
                </HeaderCell>
                <HeaderCell style={{ backgroundColor: "#F8F8F8", borderTop:'none' }}>Hours</HeaderCell>
                <HeaderCell style={{ backgroundColor: "#F8F8F8",  borderTop:'none'}}>Rate</HeaderCell>
                <HeaderCell style={{ backgroundColor: "#CDCDCE" ,  borderTop:'none', width:'80px'}}>Amount</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Week 1 */}
              <TableRow>
                <DataCell>Week 1</DataCell>
                <DataCell></DataCell>
                <DataCell>2:30</DataCell>
                <DataCell>1:00</DataCell>
                <DataCell>-</DataCell>
                <DataCell>3:00</DataCell>
                <DataCell>2:00</DataCell>
                <DataCell>-</DataCell>
                <DataCell>-</DataCell>
                <DataCell style={{ backgroundColor: "#F8F8F8" }}>8:30</DataCell>
                <DataCell style={{ backgroundColor: "#F8F8F8" }}>£35</DataCell>
                <DataCell style={{ backgroundColor: "#CDCDCE" }}>£297.5</DataCell>
              </TableRow>
              <TableRow style={{ borderBottom: "1px solid #E5E5E5", paddingRight:0 }}>
                <TableCell colSpan={12} style={{ border: "none", padding: "16px 0px 16px 8px"  }}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <ActionButton className="reject" variant="outlined">
                      Reject Hours
                    </ActionButton>
                    <ActionButton className="approve" variant="contained">
                      Approve Hours
                    </ActionButton>
                  </Box>
                </TableCell>
              </TableRow>

              {/* Week 2 */}
              <TableRow>
                <DataCell>Week 2</DataCell>
                <DataCell></DataCell>
                <DataCell>-</DataCell>
                <DataCell>2:00</DataCell>
                <DataCell>-</DataCell>
                <DataCell>6:00</DataCell>
                <DataCell>4:00</DataCell>
                <DataCell>-</DataCell>
                <DataCell>-</DataCell>
                <DataCell style={{ backgroundColor: "#F8F8F8" }}>12:00</DataCell>
                <DataCell style={{ backgroundColor: "#F8F8F8" }}>£35</DataCell>
                <DataCell style={{ backgroundColor: "#CDCDCE" }}>£420</DataCell>
              </TableRow>
              <TableRow style={{ borderBottom: "1px solid #E5E5E5", }}>
                <TableCell style={{ border: "none", padding: "16px 8px" }}
                  colSpan={9} ></TableCell>
                <TableCell
                  colSpan={3}

                >
                  <span style={{
                    backgroundColor: "#E8F5E9",
                    // padding: "8px 16px",
                    textAlign: 'center',
                    color: "#2E7D32",
                    fontSize: "12px",
                    border: "none", padding: "2px 8px",
                    margin: "0px 0px 20px 0px",
                    display: 'block',
                    width: '100%'
                  }}>
                    Approved by JD
                  </span>
                </TableCell>
              </TableRow>

              {/* Week 3 */}
              <TableRow>
                <DataCell>Week 3</DataCell>
                <DataCell></DataCell>
                <DataCell>2:00</DataCell>
                <DataCell>-</DataCell>
                <DataCell>2:00</DataCell>
                <DataCell>-</DataCell>
                <DataCell>2:00</DataCell>
                <DataCell>-</DataCell>
                <DataCell>-</DataCell>
                <DataCell style={{ backgroundColor: "#F8F8F8" }}>6:00</DataCell>
                <DataCell style={{ backgroundColor: "#F8F8F8" }}>£50</DataCell>
                <DataCell style={{ backgroundColor: "#CDCDCE" }}>£300</DataCell>
              </TableRow>
              <TableRow style={{ borderBottom: "1px solid #E5E5E5", paddingRight:0}}>
                <TableCell colSpan={12} style={{ border: "none", padding: "16px 0px 16px 8px" }}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <ActionButton className="reject" variant="outlined">
                      Reject Hours
                    </ActionButton>
                    <ActionButton className="approve" variant="contained">
                      Approve Hours
                    </ActionButton>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default TimesheetView;